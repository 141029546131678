import * as queries from '../../graphql/queries';

import * as subscriptions from '../../graphql/subscriptions';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listNotifications}`,
    {
        options: (data) => {

            return ({
                variables: {
                },
                fetchPolicy: 'cache-and-network',
            })
        },
        props: ({ data: { listNotifications = [], loading, subscribeToMore, refetch } }) => {

            listNotifications && listNotifications.map((item) => {

                if (typeof (item.data) === 'string') {
                    item.data = JSON.parse(item.data);
                }
            });

            return {
                notifications: listNotifications,
                subscribeToNotifications: (username) => {

                    const data = {
                        document: gql`${subscriptions.onCreateNotification}`,
                        updateQuery: (prev, { subscriptionData }) => {

                          const listNotifications = prev.listNotifications || [];
                
                          console.log({ prev: prev, subscriptionData: subscriptionData })
                
                          const item = subscriptionData.data.onCreateNotification;
                
                          if (typeof (item.data) === 'string') {
                            item.data = JSON.parse(item.data);
                          }
                
                          return {
                            listNotifications: [
                              item,
                              ...listNotifications
                            ]
                          }
                        },
                        variables: {
                          username: username
                        }
                    }

                    subscribeToMore(data);
                }
            }
        }
    }
)