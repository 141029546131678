import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import MaskedTextField from '../../components/form/masked-text-field';
import SelectField from '../../components/form/select-field';
import SelectSearchField from '../../components/form/select-search-field';
import RadioField from '../../components/form/radio-field';

import domain from '../../domain';

import { getCountryCodes, getSpeciality } from '../../utils';

import { CloseButtonIcon } from '../../components/ui/icons';

import * as phoneNumberCodeUi from '../../components/ui/phone-number-code';

class CreatePractitionerPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                id: '',
                name: {
                    name: 'name',
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
                email: {
                    name: 'email',
                    label: I18n.get('Email'),
                    onChange: this.onChangeForm.bind(this, 'email'),
                },
                dob: {
                    name: 'dob',
                    label: I18n.get('Date of birth'),
                    onChange: this.onChangeForm.bind(this, 'dob'),
                    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
                    format: "####-##-##",
                    placeholder: 'YYYY-MM-DD'
                },
                phoneNumberCode: {
                    name: 'phoneNumberCode',
                    placeholder: I18n.get('Country code'),
                    onChange: this.onChangeFormValue.bind(this, 'phoneNumberCode'),
                    components: {
                        ...phoneNumberCodeUi
                    }
                },
                phoneNumber: {
                    name: 'phoneNumber',
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: '',
                    format: '###############',
                },
                gender: {
                    name: 'gender',
                    label: I18n.get('Gender'),
                    onChange: this.onChangeForm.bind(this, 'gender'),
                },
                license: {
                    name: 'license',
                    label: I18n.get('License'),
                    onChange: this.onChangeForm.bind(this, 'license'),
                },
                isVIP: {
                    name: 'isVIP',
                    label: I18n.get('VIP'),
                    onChange: this.onChangeForm.bind(this, 'isVIP'),
                    values: [{ id: true, label: I18n.get('Yes') }, { id: false, label: I18n.get('No') }]
                },
                specialities: {
                    name: 'specialities',
                    label: I18n.get('Specialities'),
                    onChange: this.onChangeFormArrayToggle.bind(this, 'specialities'),
                },
                includeTPAs: {
                    name: 'includeTPAs',
                    onChange: this.onChangeFormArrayToggle.bind(this, 'includeTPAs'),
                    className: 'field mb-1'
                },
                excludeTPAs: {
                    name: 'excludeTPAs',
                    onChange: this.onChangeFormArrayToggle.bind(this, 'excludeTPAs'),
                    className: 'field mb-1'
                },
                groups: {},
            },
            form: {
                specialities: [],
                includeTPAs: [],
                excludeTPAs: [],
                groups: []
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onSubmitForm(e) {
        e.preventDefault();

        const provider = this.props.provider || {};

        const data = {
            practitionerProviderId: provider.id,
            ...this.state.form
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.create_practitioner(data)
            .then(({ practitioner }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/practitioners`);

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been created.`.replace('@practitioner', practitioner.name)) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        const provider = this.props.provider || {};

        const tpas = this.props.tpas && this.props.tpas.items && this.props.tpas.items.map((tpa) => {
            return ({ id: tpa.id, label: tpa.name });
        });

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Create Practitioner')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/practitioners`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('Practitioner Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                            <MaskedTextField {...this.state.fields.dob} value={this.state.form.dob} error={this.state.errors.dob} />

                            <div className="field mb-4">
                                <label className="label">{I18n.get('Phone')}</label>

                                <div className="flex flex-grow flex-row">

                                    <SelectSearchField {...this.state.fields.phoneNumberCode} value={this.state.form.phoneNumberCode} error={this.state.errors.phoneNumberCode} values={getCountryCodes()} className="flex-1 mb-0 mr-8" />

                                    <MaskedTextField {...this.state.fields.phoneNumber} value={this.state.form.phoneNumber} error={this.state.errors.phoneNumber} className="flex-1 mb-0" />

                                </div>

                            </div>

                            <TextField {...this.state.fields.license} value={this.state.form.license} error={this.state.errors.license} />

                            <RadioField {...this.state.fields.gender} value={this.state.form.gender} error={this.state.errors.gender} values={domain.genders} />

                            <SelectSearchField {...this.state.fields.specialities} values={domain.specialities} error={this.state.errors.specialities} />

                            <RadioField {...this.state.fields.isVIP} value={this.state.form.isVIP} error={this.state.errors.isVIP} />

                            <div className="field">
                                <div className="label"></div>
                                <div className="control">
                                    {this.state.form.specialities && this.state.form.specialities.map((specialityId, index) => {

                                        return (
                                            <span key={index} className="select-search-selected">
                                                {getSpeciality(specialityId).label}
                                                <button onClick={(e) => this.onChangeFormArrayToggle('specialities', specialityId, e)} className="p-1">
                                                    <CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" />
                                                </button>
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>

                            <p className="subtitle mt-12 mb-8">{I18n.get('Group')}</p>

                            {provider.groups && provider.groups.items.map((item, index) => {

                                const checked = this.state.form.groups && this.state.form.groups.indexOf(item.id) > -1;
                                const className = 'btn-toggle m-2 hover:bg-smart-blue hover:text-white' + (checked ? ' on' : '');

                                return (
                                    <button type="button" key={index} onClick={(e) => this.onChangeFormArrayToggle('groups', item.id)} className={className} style={{ margin: '0.25rem', minWidth: '150px' }}>
                                        {item.name}
                                    </button>
                                );
                            })}


                            <p className="subtitle mt-12 mb-8">{I18n.get('TPA')}</p>

                            <div className="flex">

                                <div className="flex-1 mr-4">

                                    <p className="mb-4">{I18n.get('Include the following TPAs')}</p>

                                    <SelectSearchField {...this.state.fields.includeTPAs} error={this.state.errors.includeTPAs} values={tpas} />

                                    {this.state.form.includeTPAs && this.state.form.includeTPAs.map((tpaId, index) => {

                                        const tpa = (tpas && tpas.find((item) => item.id == tpaId)) || {};

                                        return (
                                            <span key={index} className="select-search-selected">
                                                {tpa.label}
                                                <button onClick={(e) => this.onChangeFormArrayToggle('includeTPAs', tpaId, e)} className="p-1">
                                                    <CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" />
                                                </button>
                                            </span>
                                        );
                                    })}

                                </div>

                                <div className="flex-1 ml-4">

                                    <p className="mb-4">{I18n.get('Exclude the following TPAs')}</p>

                                    <SelectSearchField {...this.state.fields.excludeTPAs} error={this.state.errors.excludeTPAs} values={tpas} />

                                    {this.state.form.excludeTPAs && this.state.form.excludeTPAs.map((tpaId, index) => {

                                        const tpa = tpas.find((item) => item.id == tpaId);

                                        return (
                                            <span key={index} className="select-search-selected">
                                                {tpa.label}
                                                <button onClick={(e) => this.onChangeFormArrayToggle('excludeTPAs', tpaId, e)} className="p-1">
                                                    <CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" />
                                                </button>
                                            </span>
                                        );
                                    })}

                                </div>

                            </div>

                            <br style={{ clear: 'both' }} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Create')} />

                        </div>

                    </div>

                </form>

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(CORE_API.list_TPAsByProvider(CreatePractitionerPage));

