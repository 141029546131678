import React from 'react';

const NumberField = ({ label, onChange, name, placeholder = '', value, style = {}, maxLength, isOptional, error, min, max, className = 'field mb-4' }) => {

    function handleChange(e) {

        e.persist();

        const value = e.target.value;

        if (min && value < min) {
            return;
        }

        if (max && value > max) {
            return;
        }

        onChange(e)
    }

    const errorClassName = 'border-red-500';

    const inputClassName = 'flex-grow appearance-none border rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:shadow-outline ';


    return (<div className={className}>
        {label && <label className="label">
            {label}
        </label>}
        <div className="control">
            <input
                name={name}
                onChange={handleChange}
                className={inputClassName + (error ? errorClassName : '')}
                type="number"
                placeholder={placeholder}
                value={value || ''}
                style={style}
                maxLength={maxLength}
            />
        </div>
        {error && <p className="help is-danger">
            {error}
        </p>}
    </div>);
}

export default NumberField;