
export const listPractitionersByProvider = /* GraphQL */ `
  query ListPractitionersByProvider(
    $providerId: String!
    $filter: ModelPractitionerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractitionersByProvider(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
        groups {
          id
          name
          score
          createdAt
          updatedAt
        }        
      }
      nextToken
    }
  }
`