import React from 'react';

const RadioField = ({ label, onChange, fieldName, placeholder = '', value, values, name, style = {}, isOptional, error, onBlur, readOnly = false, helpText }) => {

    const id = new Date().getTime();

    const errorClassName = 'border-red-500';

    const inputClassName = 'flex-grow appearance-none border rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:shadow-outline ';

    return (
        <div className="mb-3">
            <div className="flex">
                {label && <label className="flex-none w-1/3 tracking-wide mb-1 text-gray-label">
                    {label}
                </label>}
                <div className="flex-grow">
                    {values.map((item, index) => {

                        const checked = new String(item.id).valueOf() == new String(value).valueOf()

                        return (<label key={index} className="radio">
                            <input type="radio" name={name} onChange={onChange} value={item.id} checked={checked} /> &nbsp;
                                <span className="mr-2 text-gray-500 uppercase">{item.label}</span>
                        </label>);
                    })}
                    {error && <p className="mt-2 text-red-500 text-xs italic">{error}</p>}
                </div>
            </div>

        </div>
    );
}

export default RadioField;