import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';
import Task from '../../task';

import PractitionerContainer from '../../containers/practitioner-container';
import PractitionerLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DetailsItem from '../../components/ui/details-item';

import DateTimeSpan from '../../components/datetime-span';

import Modal from '../../components/modal';

import { ActionButton } from '../../components/ui/buttons';
import { DetailsListIcon, CloseButtonIcon, AlertIcon } from '../../components/ui/icons';
import TaskMessage from '../../components/task-message';

import domain from '../../domain';

import { getSpeciality } from '../../utils';

class PractitionerDetails extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            view: null
        }
    }

    componentDidMount() {
    }

    selectView(name, e) {

        if (e) e.preventDefault();

        this.setState({ view: name, task: {} });
    }

    onDisablePractitionerSubmitForm(e) {

        e.preventDefault();

        const practitioner = this.props.practitioner || {};

        const data = {
            id: practitioner.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.disable_practitioner(data)
            .then(({ result }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been disabled.`.replace('@practitioner', practitioner.name)) });

            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    onEnablePractitionerSubmitForm(e) {

        e.preventDefault();

        const practitioner = this.props.practitioner || {};

        const data = {
            id: practitioner.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.enable_practitioner(data)
            .then(({ result }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been enabled.`.replace('@practitioner', practitioner.name)) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    onDeletePractitionerSubmitForm(e) {

        e.preventDefault();

        const provider = this.props.provider || {};
        const practitioner = this.props.practitioner || {};

        const data = {
            id: practitioner.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.delete_practitioner(data)
            .then(({ result }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been deleted.`.replace('@practitioner', practitioner.name)) });

                this.props.history.push(`/${provider.publicURL}/practitioners`);
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        function getSpeciality(id) {
            return domain.specialityMap[id] || { label: '-' };
        }

        const provider = this.props.provider || {};
        const practitioner = this.props.practitioner || {};
        const user = practitioner.user || {};

        return (
            <PractitionerLayout {...this.props}>

                <div className="page-header">

                    <div className="title">
                        <DetailsListIcon className="icon md mr-3 opacity-50" />
                        {I18n.get('Practitioner Details')}
                    </div>

                    <div className="bar">

                        <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/edit`} className="btn-primary mr-4">
                            {I18n.get('Edit')}
                        </NavLink>

                        {this.props.auth.is_master_or_provider && <>

                            {user.enabled && <button onClick={this.selectView.bind(this, 'disable-form')} className="btn-secondary mr-4">{I18n.get('Disable Practitioner')}</button>}

                            {!user.enabled && <button onClick={this.selectView.bind(this, 'enable-form')} className="btn-secondary mr-4">{I18n.get('Enable Practitioner')}</button>}

                            <button onClick={this.selectView.bind(this, 'delete-form')} disabled={user.enabled} className="btn-tertiary mr-4">{I18n.get('Delete Practitioner')}</button>

                        </>}


                    </div>

                </div>

                <ToastContainer toasts={this.props.core.toasts} />

                <div className="page-body">

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('Practitioner Details')}</p>

                            <DetailsItem label={I18n.get('Name')} value={practitioner.name} />
                            <DetailsItem label={I18n.get('Email')} value={practitioner.email} />
                            <DetailsItem label={I18n.get('Status')} value={user.enabled ? I18n.get('ENABLED') : I18n.get('DISABLED')} />
                            <DetailsItem label={I18n.get('Phone')} value={practitioner.phoneNumber} />
                            <DetailsItem label={I18n.get('License')} value={practitioner.license} />
                            <DetailsItem label={I18n.get('DOB')} value={practitioner.dob} />
                            <DetailsItem label={I18n.get('Gender')} value={practitioner.gender} />
                            <DetailsItem label={I18n.get('Speciality')} value={practitioner.specialities && practitioner.specialities.map((item) => getSpeciality(item).label).join(', ')} />
                            <DetailsItem label={I18n.get('Created')} value={<DateTimeSpan value={practitioner.createdAt} />} />

                            {this.props.auth.is_master_or_provider && <>

                                <p className="subtitle mt-12 mb-8">{I18n.get('Practitioner Group')}</p>

                                <div>
                                    {practitioner.groups && practitioner.groups.map((group, index) => {

                                        return (
                                            <span key={index} className="tag">
                                                {group.name}
                                            </span>
                                        );
                                    })}

                                    {practitioner.groups && practitioner.groups.length == 0 && <p className="mb-4 text-sm">
                                        {I18n.get('No group selected, practitioner is elegible only to TPAs on the inclusion list below.')}.
                                </p>}
                                </div>

                                <p className="subtitle mt-12 mb-8">{I18n.get('TPA')}</p>

                                <div className="flex">

                                    <div className="flex-1 mr-4">

                                        <p className="mb-4">{I18n.get('Include the following TPAs')}</p>

                                        {practitioner.includeTPAs && practitioner.includeTPAs.map((tpa, index) => {

                                            return (
                                                <span key={index} className="tag">
                                                    {tpa.name}
                                                </span>
                                            );
                                        })}

                                        {practitioner.includeTPAs && practitioner.includeTPAs.length == 0 && <p className="text-sm text-gray-400">
                                            {I18n.get('Empty')}.
                                    </p>}

                                    </div>

                                    <div className="flex-1 ml-4">

                                        <p className="mb-4">{I18n.get('Exclude the following TPAs')}</p>

                                        {practitioner.excludeTPAs && practitioner.excludeTPAs.map((tpa, index) => {

                                            return (
                                                <span key={index} className="tag">
                                                    {tpa.name}
                                                </span>
                                            );
                                        })}

                                        {practitioner.excludeTPAs && practitioner.excludeTPAs.length == 0 && <p className="text-sm text-gray-400">
                                            {I18n.get('Empty')}.
                                    </p>}

                                    </div>

                                </div>

                            </>}

                        </div>

                    </div>

                </div>

                <Modal isOpen={this.state.view == 'disable-form'}>

                    <div>
                        <div className="bg-white flex justify-end items-center border-b">
                            <div className="flex-1 p-4">
                                <span className="font-semibold mr-2">{I18n.get('Disable Practitioner Confirmation')}</span>
                            </div>
                            <div className="flex-1 text-right pr-4">
                                <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                    <CloseButtonIcon className="icon w-3 h-3" />
                                </a>
                            </div>
                        </div>
                        <div className="p-12">

                            <form onSubmit={this.onDisablePractitionerSubmitForm.bind(this)}>

                                <p className="mb-6">
                                    <strong>{practitioner.name} ({practitioner.email})</strong>
                                </p>

                                <p className="mb-12 text-gray-600">
                                    {I18n.get('User will no longer be able to log in to the system.')}
                                </p>

                                <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                                <ActionButton task={this.state.task} label={I18n.get('Disable Practitioner')} />


                            </form>

                        </div>
                    </div>

                </Modal>

                <Modal isOpen={this.state.view == 'enable-form'}>

                    <div>
                        <div className="bg-white flex justify-end items-center border-b">
                            <div className="flex-1 p-4">
                                <span className="font-semibold mr-2">{I18n.get('Enable Practitioner Confirmation')}</span>
                            </div>
                            <div className="flex-1 text-right pr-4">
                                <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                    <CloseButtonIcon className="icon w-3 h-3" />
                                </a>
                            </div>
                        </div>
                        <div className="p-12">

                            <form onSubmit={this.onEnablePractitionerSubmitForm.bind(this)}>

                                <p className="mb-6">
                                    <strong>{practitioner.name} ({practitioner.email})</strong>
                                </p>

                                <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                                <ActionButton task={this.state.task} label={I18n.get('Enable Practitioner')} />


                            </form>

                        </div>
                    </div>

                </Modal>

                <Modal isOpen={this.state.view == 'delete-form'}>

                    <div>
                        <div className="bg-white flex justify-end items-center border-b">
                            <div className="flex-1 p-4">
                                <span className="font-semibold mr-2">{I18n.get('Delete Practitioner Confirmation')}</span>
                            </div>
                            <div className="flex-1 text-right pr-4">
                                <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                    <CloseButtonIcon className="icon w-3 h-3" />
                                </a>
                            </div>
                        </div>
                        <div className="p-2 m-4 mb-0 text-gray-600 text-red-600 text-sm border border-red-500">
                            <AlertIcon /> This operation cannot be undone.
                     </div>
                        <div className="p-12">

                            <form onSubmit={this.onDeletePractitionerSubmitForm.bind(this)}>

                                <p className="mb-8">
                                    <strong>{practitioner.name} ({practitioner.email})</strong>
                                </p>

                                <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                                <ActionButton task={this.state.task} label={I18n.get('Delete Practitioner')} />


                            </form>

                        </div>
                    </div>

                </Modal>

            </PractitionerLayout>
        );
    }

}

export default PractitionerContainer(PractitionerDetails);