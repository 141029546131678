
import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listMedicalProviders}`,
    {
        options: (data) => ({
            variables: {
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { listMedicalProviders = {}, loading } }) => {

            return {
                providers: listMedicalProviders,
                loading: loading
            }
        }
    }
)
