
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as queries from '../../graphql/queries';

export default graphql(
    gql`${queries.getNotification}`,
    {
        options: (data) => {

            return {
                variables: {
                    //id: appointmentId
                },
                fetchPolicy: 'cache-and-network'
            }
        },
        props: ({ data: { getNotification = {}, loading } }) => {

            const notification = getNotification;

            return {
                notification: notification,
            }
        }
    }
)

