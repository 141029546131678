import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    PASSWORD_SET,
    FORGOT_PASSWORD,
    PASSWORD_RESET,
    LOGOUT,
    EXPIRED
} from '../actions/auth/actions';

const initialState = {
    init: false,
    task: {}
};

export default function authReducer(state = initialState, action) {

    console.dir(action);

    if (action.type == EXPIRED) return initialState;

    if (action.type.indexOf('AUTH_') == -1) return state;

    return Object.assign({}, state, action.payload);
}
