import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import MasterContainer from '../../containers/master-container';

import MasterLayout from '../shared/layout';

import ToastContainer from '../shared/toast-container';

import AuditLogList from '../shared/_audit-log-list';

import SearchField from '../../components/form/search-field';

import { AuditLogIcon } from '../../components/ui/icons';

const AuditLogListWithData = CORE_API.list_auditLogs(AuditLogList);

class AuditLogsPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (<MasterLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <AuditLogIcon className="mr-3 opacity-50" />
                    {I18n.get('Audit Logs')}
                </div>

                <div className="flex-grow flex items-center justify-end">
                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <AuditLogListWithData {...this.props} ts="2020" />

            </div>

        </MasterLayout>);
    }

}

export default MasterContainer(CORE_API.list_providers(AuditLogsPage));

