import React from 'react';
import { I18n } from 'aws-amplify';


class Dropdown extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false
        }

        this.nodeRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {

        if (!this.nodeRef || !this.nodeRef.current) {
            return;
        }

        if (this.nodeRef.current.contains(e.target)) {

        }
        else {
            this.setState({ isOpen: false });
        }
    }

    toggleOpen(e) {
        this.setState(prevState => {
            return {
                isOpen: !prevState.isOpen
            }
        })
    }

    render() {

        const { label, children, className = '', style = {}, icon } = this.props;

        const outerClassName = 'relative ' + className;

        return (
            <div ref={this.nodeRef} className={outerClassName} style={style}>
                <div className="h-full  flex items-center justify-center cursor-pointer" style={{ width: '65px' }} onClick={this.toggleOpen.bind(this)}>
                    {label}
                    {icon}
                </div>

                {this.state.isOpen && <div className="content bg-transparent absolute z-10" style={{ top: '50px' }}>
                    <div className="h-8">

                    </div>
                    <div className="items-center shadow bg-white rounded shadow-xl overflow-hidden">
                        {children}
                    </div>
                </div>}
            </div>
        )

    }
}

export default Dropdown;