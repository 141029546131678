import React from 'react';
import { compose } from 'react-apollo';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { components } from 'react-select';

import Task from '../../task';

import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import DetailsItem from '../../components/ui/details-item';

import DateTimeSpan from '../../components/datetime-span';

import { DetailsListIcon } from '../../components/ui/icons';

import SelectSearchField from '../../components/form/select-search-field';

import Modal from '../../components/modal';

import { getSpeciality } from '../../utils';

import { CloseButtonIcon } from '../../components/ui/icons';


const SingleValue = ({ children, data, ...props }) => {

    const item = data.data;

    return (<components.SingleValue {...props}>{item.name}</components.SingleValue>);

};

const Option = ({ innerProps, data }) => {

    const item = data.data;

    innerProps = item.disabled ? {} : innerProps;

    const className = 'p-1 cursor-pointer hover:bg-react-select-blue ' + (item.disabled ? 'opacity-25' : '')

    return (<div  {...innerProps} className={className}>
        <p className="text-sm">{item.name}</p>
        <p className="text-xs text-gray-500">{item.specialities && item.specialities.map((item) => getSpeciality(item).label).join(', ')}</p>
    </div>);
}

const PractitionerCard = ({ item, select }) => (<div className="flex-1 flex-grow-0 bg-white m-1 p-4 shadow rounded-lg" style={{ flexBasis: '14rem' }}>
    <button onClick={e => select(item, e)} className="float-right"><CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" /></button>
    <p className="truncate">
        {item.name}
    </p>
    <p className="text-sm text-gray-500">
        {item.specialities && item.specialities.map((item) => getSpeciality(item).label).join(', ')}
    </p>
</div>);

class DetailsPage extends React.Component {

    constructor(props) {
        super();

        this.state = {
            fields: {
                addPractitioner: {
                    name: 'addPractitioner',
                    onChange: this.onChangeFormValue.bind(this, 'addPractitioner'),
                    className: 'field mb-1',
                    components: { Option: Option, SingleValue: SingleValue },
                },
            },
            form: {
                addPractitioner: []
            },
            errors: {
            },
            task: {},
            practitioner: {}
        }
    }

    componentDidMount() {
    }


    onChangeFormValue(prop, value) {

        console.log({ prop: prop, value: value });

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onAddMember(e) {

        e.preventDefault();

        const practitioner = this.props.practitioners && this.props.practitioners.items && this.props.practitioners.items.find((item) => item.id == this.state.form.addPractitioner);

        const groups = new Set([...practitioner.groups.map((item) => item.id), this.props.match.params.practitionerGroupId]);

        const data = {
            id: practitioner.id,
            groups: [
                ...groups
            ]
        };

        var add_task = new Task();

        this.setState({ task: add_task });

        this.props.update_practitioner(data)
            .then(({ practitioner }) => {

                this.setState(prevState => ({
                    form: { ...prevState.form, addPractitioner: undefined },
                    errors: { ...prevState.errors, addPractitioner: undefined },
                    task: {}
                })
                );

                this.props.practitioner_group_refetch();

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been added to this group.`.replace('@practitioner', practitioner.name)) });

            })
            .catch((err) => {

                const { task, errors } = add_task.complete_as_failure(err);

                this.setState({ task: task, errors: errors });
            });

    }

    onRemoveMember(e) {

        e.preventDefault();

        const practitioner = this.state.practitioner;

        const groups = practitioner.groups.map((item) => item.id).filter((id) => id != this.props.match.params.practitionerGroupId);

        const data = {
            id: practitioner.id,
            groups: [
                ...groups
            ]
        };

        var remove_task = new Task();

        this.setState({ task: remove_task });

        this.props.update_practitioner(data)
            .then(({ practitioner }) => {

                this.setState({ practitioner: {}, task: {} });

                this.props.practitioner_group_refetch();

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been remove from this group.`.replace('@practitioner', practitioner.name)) });

            })
            .catch((err) => {

                const { task, errors } = remove_task.complete_as_failure(err);

                this.setState({ task: task, errors: errors });
            });

    }

    onSelect(item, e) {

        e.preventDefault();

        this.setState({ practitioner: item });
    }

    render() {

        const provider = this.props.provider || {};
        const practitioner_group = this.props.practitioner_group || {};

        const practitioners = this.props.practitioners && this.props.practitioners.items && this.props.practitioners.items.map((item) => {

            const specialities = item.specialities && item.specialities.map((item) => getSpeciality(item).label).join(', ');

            const label = `${item.name} (${specialities})`;

            const disabled = !!(item.groups.find((p) => p.id == this.props.match.params.practitionerGroupId));

            return ({ id: item.id, label: label, data: item, disabled: disabled });
        }).filter((item) => item.disabled === false);

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <DetailsListIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Practitioner Group Details')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/groups/${practitioner_group.id}/edit`} className="btn-primary">
                        {I18n.get('Edit')}
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="card">

                    <div className="body">

                        <p className="subtitle mb-8">{I18n.get('Group Details')}</p>

                        <DetailsItem label={I18n.get('Name')} value={practitioner_group.name} />
                        <DetailsItem label={I18n.get('Created')} value={<DateTimeSpan value={practitioner_group.createdAt} />} />
                        <DetailsItem label={I18n.get('Updated')} value={<DateTimeSpan value={practitioner_group.updatedAt} />} />

                    </div>

                </div>

                <div className="flex mt-12 mb-8">
                    <div className="flex-1">

                        <p className="subtitle">{I18n.get('Members')}</p>

                    </div>

                    <div className="flex-1 ml-20">



                    </div>

                </div>

                <form onSubmit={this.onAddMember.bind(this)} className="my-4" style={{ width: '50%' }}>

                    <div className="flex">

                        <div className="flex-grow">

                            <SelectSearchField {...this.state.fields.addPractitioner} value={this.state.form.addPractitioner} error={this.state.errors.addPractitioner} values={practitioners} />

                        </div>

                        <div className="flex-none ml-8">

                            <ActionButton task={this.state.task} label={I18n.get('Add Practitioner')} className="btn-secondary" />

                        </div>

                    </div>

                    <TaskMessage {...this.state.task}></TaskMessage>

                </form>

                <div className="flex flex-wrap">
                    {practitioner_group.practitioners && practitioner_group.practitioners.map((item) => {
                        return (<PractitionerCard item={item} select={this.onSelect.bind(this)} />);
                    })}
                </div>

            </div>

            <Modal isOpen={this.state.practitioner.id}>

                <div>
                    <div className="bg-white flex justify-end items-center border-b">
                        <div className="flex-1 p-4">
                            <span className="font-semibold mr-2">{I18n.get('Remove Practitioner from Group')}</span>
                        </div>
                        <div className="flex-1 text-right pr-4">
                            <a href="#" onClick={this.onSelect.bind(this, {})} className="card-header-icon" aria-label={I18n.get('more options')}>
                                <CloseButtonIcon className="icon w-3 h-3" />
                            </a>
                        </div>
                    </div>
                    <div className="p-12">

                        <form onSubmit={this.onRemoveMember.bind(this)}>

                            <p className="mb-6">
                                <strong>{this.state.practitioner.name} ({this.state.practitioner.email})</strong>
                            </p>

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Remove')} />


                        </form>

                    </div>
                </div>

            </Modal>

        </ProviderLayout >);
    }

}
export default compose(
    CORE_API.get_practitioner_group,
    CORE_API.update_practitioner,
)(ProviderContainer(CORE_API.list_PractitionersByProvider(DetailsPage)));

