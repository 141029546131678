import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import PractitionerList from './_practitioner-list';

import SearchField from '../../components/form/search-field';

import { PractitionersIcon } from '../../components/ui/icons';

const PractitionerListWithData = CORE_API.list_PractitionersByProvider(PractitionerList);

class PractitionersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            search: ''
        };
    }

    componentDidMount() {

    }

    onChangeSearch(value) {

        this.setState({ search: value });
    }

    getFilter(parameters) {

        const filter = {};

        if (parameters.search) {
            filter.q = { contains: parameters.search.toLowerCase() }
        }

        return filter;
    }

    render() {

        const provider = this.props.provider;

        const filter = this.getFilter(this.state);

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <PractitionersIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Practitioners')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/practitioners/new`} className="btn-primary sm:mr-8">
                        {I18n.get('Create Practitioner')}
                    </NavLink>

                </div>

                <SearchField onChangeText={this.onChangeSearch.bind(this)} value={this.state.search} />

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {provider.id && <PractitionerListWithData providerId={provider.id} {...this.props} filter={filter} />}

            </div>

        </ProviderLayout>);
    }

}

export default ProviderContainer(PractitionersPage);

