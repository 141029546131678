import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { DashboardHomeIcon, CalendarRegularIcon, DetailsListIcon, LocationIcon, ReportIcon } from '../../components/ui/icons';

const ASide = ({ practitioner, auth, menuIsOpen = false }) => {

    if (!practitioner || !practitioner.id) return null;

    const provider = practitioner.provider || {};

    const NavLinkOrSpan = ({ auth }) => {
        if (auth.is_master || auth.is_provider) {
            return (
                <NavLink to={`/${provider.publicURL}/dashboard`} className="item">
                    {provider.name}
                </NavLink>
            );
        }
        else {
            return (
                <span>
                    {provider.name}
                </span>
            );
        }
    }

    const labelCssClass = menuIsOpen ? 'inline-block label ' : 'hidden lg:inline-block label ';

    return (
        
        <aside className="mt-12 sm:mt-16 text-gray-300">

            <ul className="list-reset flex flex-col">

                {/* <li className="flex-1" style={{ backgroundColor: '#3D4961' }}>
                    <div className="h-20 px-5 py-6">
                        <span className="text-gray-300 text-2xl">
                            <NavLinkOrSpan auth={auth} />
                        </span>
                    </div>
                </li>
 */}
                <li className="flex-1"  style={{ backgroundColor: '#3D4961' }}>
                    <div className="h-20 px-5 py-6">
                        <span className={labelCssClass + ' text-gray-300 text-xl'}>
                            {practitioner.name}
                        </span>
                    </div>
                </li>

                <li className="mt-8">
                    <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/dashboard`}  className="menu-item dashboard hover:text-smart-blue">
                        <DashboardHomeIcon />
                        <span className={labelCssClass}>{I18n.get('Dashboard')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/calendar`} className="menu-item calendar hover:text-smart-blue">
                        <CalendarRegularIcon />
                        <span className={labelCssClass}>{I18n.get('Calendar')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/details`} className="menu-item details hover:text-smart-blue">
                        <DetailsListIcon />
                        <span className={labelCssClass}>{I18n.get('Details')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/location`} className="menu-item location hover:text-smart-blue">
                        <LocationIcon />
                        <span className={labelCssClass}>{I18n.get('Location & Availability')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/report`} className="menu-item hover:text-smart-blue">
                        <ReportIcon />
                        <span className={labelCssClass}>{I18n.get('Report')}</span>
                    </NavLink>
                </li>                

            </ul>

        </aside>
    );

}

export default ASide;