import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import localizationService from '../../localization';

import Task from '../../task';

import TPAContainer from '../../containers/tpa-container';
import TPALayout from '../shared/layout';

import ToastContainer from '../shared/toast-container';


import { ActionButton } from '../../components/ui/buttons';
import { CloseButtonIcon } from '../../components/ui/icons';

import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';

import domain from '../../domain';


class EditUserForm extends React.Component {

    constructor(props) {

        super(props);

        const user = props.user || {};

        this.state = {
            fields: {
                id: '',
                name: {
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                    maxLength: 200
                },
                locale: {
                    label: I18n.get('Language'),
                    onChange: this.onChangeForm.bind(this, 'locale'),
                }
            },
            form: {
            },
            errors: {
            },
            task: {
            }
        };

        Object.keys(this.state.fields).forEach((item) => {
            this.state.form[item] = user[item];
        });
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log({ update: this.props.user });

        if (JSON.stringify(prevProps.user) != JSON.stringify(this.props.user)) {

            const user = this.props.user || {};

            var nextState = Object.assign({}, prevState);

            Object.keys(this.state.fields).forEach((item) => {
                nextState.form[item] = user[item];
            });

            this.setState(nextState);
        }
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.user) != JSON.stringify(this.props.user)) {

            const user = this.props.user || {};

            var nextState = Object.assign({}, prevState);

            Object.keys(this.state.fields).forEach((item) => {
                nextState.form[item] = user[item];
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        const provider = this.props.provider || {};
        const tpa = this.props.tpa || {};

        const data = {
            ...this.state.form
        }

        var task = new Task();

        this.setState({ task: task });

        this.props.update_user(data)
            .then(({ user }) => {

                localizationService.setLocale(user.locale);

                this.setState({ task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`TPA User '@name' has been updated.`).replace('@name', user.name) });

                this.props.history.push(`/${provider.publicURL}/t/${tpa.id}/users`);
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        // if (this.props.loading) return (<div>Loading...</div>);

        const provider = this.props.provider || {};
        const tpa = this.props.tpa || {};
        const user = this.props.user || {};

        return (<TPALayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Edit TPA User')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/users/${user.id}`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('User Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <SelectField {...this.state.fields.locale} value={this.state.form.locale} error={this.state.errors.locale} values={domain.locales} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />


                        </div>
                    </div>

                </form>

            </div>

        </TPALayout >);
    }

}

export default TPAContainer(CORE_API.get_user(EditUserForm));

