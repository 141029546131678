import 'babel-polyfill';

import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';

import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { store } from './store';

import Amplify, { Auth } from 'aws-amplify';
import config from './aws-exports';
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

import GoogleMapsLoader from './components/google-maps-loader';


if (window && window.location && window.location.hostname && window.location.hostname != "localhost") {
  Sentry.init({
    dsn: "https://3fe59bcbdd574cfcba7c0fe05da7b14b@sentry.io/1414502"
  });
}

Amplify.configure({
  Auth: {
    identityPoolId: config.aws_cognito_identity_pool_id,
    region: config.aws_cognito_region,
    userPoolId: config.aws_user_pools_id,
    userPoolWebClientId: config.aws_user_pools_web_client_id,
    mandatorySignIn: true,
    storage: window.sessionStorage
  }
});

const client = new AWSAppSyncClient({
  url: config.aws_appsync_graphqlEndpoint,
  region: config.aws_appsync_region,
  auth: {
    type: config.aws_appsync_authenticationType,
    jwtToken: async () => {

      resetTimer();

      return (await Auth.currentSession()).getIdToken().getJwtToken();
    }
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  disableOffline: true
});

// client.resetStore();

// client.subscribe({ query: gql`${subscriptions.onUpdateMedicalProvider}`}).subscribe({
//   next: (eventData) => console.log(eventData)
// });

// client.subscribe({ query: gql`${subscriptions.onCreateAppointment}`}).subscribe({
//   next: (eventData) => console.log(eventData)
// });

// client.subscribe({ query: gql`${subscriptions.onBroadcastAppointment}` }).subscribe({
//   next: (eventData) => {
//     console.log(eventData);
//   }
// });



window.appSyncClient = client;

ReactDOM.render(<ApolloProvider client={client}>
  <Provider store={store}>
    <GoogleMapsLoader>
      <App />
    </GoogleMapsLoader>
  </Provider>
</ApolloProvider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

var t;

// window.onmousemove = resetTimer; // catches mouse movements
// window.onmousedown = resetTimer; // catches mouse movements
// window.onclick = resetTimer;     // catches mouse clicks
// window.onscroll = resetTimer;    // catches scrolling
// window.onkeypress = resetTimer;  //catches keyboard actions

function logout() {
  window.sessionStorage.clear();
  window.location.reload();
}

function resetTimer() {
  console.log('resetTimer');
  clearTimeout(t);
  t = setTimeout(logout, 60 * 60 * 1000);
}