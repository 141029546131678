import React from 'react';
import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';

import TimeAgo from '../../components/time-ago';
import { I18n } from 'aws-amplify';

const ProviderItem = ({ item }) => {

    const status = domain.medicalProvider.statusMap[item.status] || {};

    return (<div className="bg-white border-b border-grey-100 hover:bg-gray-100">
        <div className="px-4 py-4">

            <div className="flex h-16 items-center justify-between">

                <div className="flex-1">
                    <div className="flex items-center">
                        <div className="flex-none w-16">
                            {item.logo && <img src={item.logo} className="w-16 h-16 object-cover" />}
                        </div>
                        <div className="flex-grow ml-4">
                            <p className="text-xl">{item.name}</p>
                        </div>
                    </div>
                </div>

                {/* <div className="flex-1 hidden sm:block">
                    <p className="text-lg">{item.contactName}</p>
                    <p className="text-sm text-gray-600">{item.contactEmail}</p>
                </div> */}


                <div className="flex-1 hidden sm:block">
                    <p className="text-base text-gray-600">{I18n.get(status.label)}</p>
                    <p className="text-sm text-gray-600"></p>
                </div>


                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.createdAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.createdAt} /></p>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.updatedAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.updatedAt} /></p>
                </div>

            </div>
        </div>
    </div >);
};

export default ProviderItem;