
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { getAppointment } from '../../graphql/queries/getAppointment';

import { API, graphqlOperation } from 'aws-amplify';

export default graphql(
    gql`${getAppointment}`,
    {
        options: ({ appointmentId }) => ({
            variables: {
                id: appointmentId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getAppointment = {}, loading, refetch } }) => {

            const appointment = getAppointment || {};

            if (typeof (appointment['symptoms']) === 'string') {
                appointment['symptoms'] = JSON.parse(appointment['symptoms']);
            }

            return {
                appointment: appointment,
                loading: loading,
                refetch: refetch
            }
        }
    }
);