import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.createPractitionerGroup}`,
    {
        options: {
        },
        props: (props) => ({
            create_practitioner_group: (input) => {

                return props.mutate({
                    variables: { input: input }
                })
                    .then(({ data }) => {
                        return {
                            practitioner_group: data.createPractitionerGroup,
                        }
                    })
            }
        })
    })
