import React from 'react';

const PasswordField = ({ label, onChange, name, placeholder = '', value, style = {}, maxLength, isOptional, error, onBlur, readOnly = false, className = "field mb-4" }) => {

    const errorClassName = 'border-red-500';

    const inputClassName = 'flex-grow appearance-none border rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:shadow-outline ';

    return (
        <div className={className}>
            {label && <label className="label">
                {label}
            </label>}
            <div className="control">
                <input
                    type="password"
                    className={inputClassName + (error ? errorClassName : '')}
                    onChange={onChange}
                    name={name}
                    placeholder={placeholder}
                    value={value || ''}
                    style={style}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    readOnly={readOnly}
                    autoComplete="off"
                />
                {error && <p className="mt-2 text-red-500 text-xs italic">{error}</p>}
            </div>
        </div>
    );
}

export default PasswordField;