import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
};

const DashboardPage = (props) => {

    if (props.auth.is_master) {
        return <Redirect to={`/providers`} />
    }

    if (props.auth.is_provider) {
        return <Redirect to={`/${props.auth.providerId}/dashboard`} />
    }

    if (props.auth.is_tpa) {
        return <Redirect to={`/${props.auth.providerId}/t/${props.auth.tpaId}/dashboard`} />
    }

    if (props.auth.is_practitioner) {
    }


    return (<Redirect to='/login' />);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

