import { store } from '../../store';

import list_auditLogs from './list_auditLogs';
import list_auditLogsByProvider from './list_auditLogsByProvider';

import list_users from './list_users';
import create_user from './create_user';
import get_user from './get_user';
import update_user from './update_user';
import disable_user from './disable_user';
import enable_user from './enable_user';
import delete_user from './delete_user';

import list_providers from './list_providers';
import get_provider from './get_provider';
import create_provider from './create_provider';
import update_provider from './update_provider';
import get_coverage from './get_coverage';

import list_TPAsByProvider from './list_TPAsByProvider';
import get_tpa from './get_tpa';
import create_tpa from './create_tpa';
import update_tpa from './update_tpa';


import list_Practitioners from './list_practitioners';
import list_PractitionersByProvider from './list_practitionersByProvider';
import get_practitioner from './get_practitioner';
import create_practitioner from './create_practitioner';
import update_practitioner from './update_practitioner';
import disable_practitioner from './disable_practitioner';
import enable_practitioner from './enable_practitioner';
import delete_practitioner from './delete_practitioner';

import get_appointment from './get_appointment';
import list_appointments from './list_appointments';
import list_appointmentsByTPA from './list_appointmentsByTPA';
import listAppointmentsByPractitioner from './list_appointmentsByPractitioner';

import create_appointment from './create_appointment';
import update_appointment from './update_appointment';
import cancel_appointment from './cancel_appointment';
import prepare_appointment from './prepare_appointment';
import request_appointment from './request_appointment';
import update_appointment_request from './update_appointment_request';
import start_appointment from './start_appointment';
import complete_appointment from './complete_appointment';


import create_practitioner_group from './create_practitioner_group';
import update_practitioner_group from './update_practitioner_group';
import get_practitioner_group from './get_practitioner_group';

import list_countries from './list_countries';

import list_notifications from './list_notifications';
import clearAll_notifications from './clearAll_notifications';

import get_notification from './get_notification';
import update_notification from './update_notification';
import select_notification from './select_notification';

import get_appointment_request from './get_appointmentRequest';

import reset from './reset';

import create_toast from './create_toast';
import delete_toast from './delete_toast';

import get_emr from './get_emr';


export const CORE_UPDATE = 'CORE_UPDATE';
export const CORE_NOTIFICATION_NEW = 'CORE_NOTIFICATION_NEW';
export const CORE_CREATE_TOAST = 'CORE_CREATE_TOAST';
export const CORE_DELETE_TOAST = 'CORE_DELETE_TOAST';

export const CORE_API = {
    // Audit Logs
    list_auditLogs: list_auditLogs,
    list_auditLogsByProvider: list_auditLogsByProvider,
    // General
    list_countries: list_countries,
    // Users
    list_users: list_users,
    get_user: get_user,
    create_user: create_user,
    update_user: update_user,
    disable_user: disable_user,
    enable_user: enable_user,
    delete_user: delete_user,
    // Providers
    list_providers:list_providers,
    get_provider: get_provider,
    create_provider: create_provider,
    update_provider: update_provider,
    get_coverage: get_coverage,
    // Tpas
    list_TPAsByProvider: list_TPAsByProvider,
    create_tpa:  create_tpa,
    get_tpa: get_tpa,
    update_tpa: update_tpa,
    // Practitioner
    list_Practitioners: list_Practitioners,
    list_PractitionersByProvider: list_PractitionersByProvider,
    create_practitioner: create_practitioner,
    get_practitioner: get_practitioner,
    update_practitioner: update_practitioner,
    delete_practitioner: delete_practitioner,
    create_practitioner_group:  create_practitioner_group,
    update_practitioner_group: update_practitioner_group,
    get_practitioner_group:  get_practitioner_group,
    disable_practitioner: disable_practitioner,
    enable_practitioner: enable_practitioner,
    // Notification
    list_notifications: list_notifications,
    get_notification:  get_notification,
    update_notification:  update_notification,
    clearAll_notifications: clearAll_notifications,
    // Apppointment
    get_appointment: get_appointment,
    list_appointments: list_appointments,
    list_appointmentsByTPA: list_appointmentsByTPA,
    listAppointmentsByPractitioner: listAppointmentsByPractitioner,
    update_appointment_request: update_appointment_request,
    cancel_appointment:  cancel_appointment,
    prepare_appointment: prepare_appointment,
    request_appointment:  request_appointment,
    start_appointment: start_appointment,
    complete_appointment: complete_appointment,
    get_appointmentRequest: get_appointment_request,
    get_emr: get_emr,
    // Redux
    select_notification:  (data) => store.dispatch(select_notification(data)),
    create_toast:  (data) => store.dispatch(create_toast(data)),
    delete_toast:  (data) => store.dispatch(delete_toast(data)),
    reset:  () => store.dispatch(reset()),
}