import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import ReportItem from '../shared/_report-item';

import Task from '../../task';

import { CloseButtonIcon } from '../../components/ui/icons';

import Modal from '../../components/modal';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';
import DateRangeField from '../../components/form/date-range-field';
import MaskedTextField from '../../components/form/masked-text-field';


class ReportList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
            fields: {
                appointmentTpaId: {
                    onChange: this.onChangeForm.bind(this, 'appointmentTpaId'),
                    className: 'field'
                },                
                alternateId: {
                    onChange: this.onChangeForm.bind(this, 'alternateId'),
                    className: 'field'
                },
                status: {
                    onChange: this.onChangeForm.bind(this, 'status'),
                    className: 'field'
                },
                name: {
                    onChange: this.onChangeForm.bind(this, 'name'),
                    className: 'field'
                },
                address1: {
                    onChange: this.onChangeForm.bind(this, 'address1'),
                    className: 'field'
                },                
                city: {
                    onChange: this.onChangeForm.bind(this, 'city'),
                    className: 'field'
                },
                state: {
                    onChange: this.onChangeForm.bind(this, 'state'),
                    className: 'field'
                },
                practitionerId: {
                    onChange: this.onChangeForm.bind(this, 'practitionerId'),
                    className: 'field',
                },
                scheduledDate: {
                    onChange: this.onChangeFormValue.bind(this, 'scheduledDate'),
                    className: 'field flex-1',
                    value: []
                },                
                scheduledDateGt: {
                    onChange: this.onChangeForm.bind(this, 'scheduledDateGt'),
                    className: 'field flex-1',
                    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
                    format: '####-##-##',
                    placeholder: 'YYYY-MM-DD'
                },
                scheduledDateLt: {
                    onChange: this.onChangeForm.bind(this, 'scheduledDateLt'),
                    className: 'field flex-1',
                    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
                    format: '####-##-##',
                    placeholder: 'YYYY-MM-DD'
                },
            },
            form: {
                filters: []
            },
            errors: {},
        }
    }

    componentDidMount() {

    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }    

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onFilterClick(e) {

        e.preventDefault();

        const fields = this.getFields();

        const errors = {};
        const data = {};

        this.state.form.filters.map((item) => {

            const value = this.state.form[item];

            errors[item] = value ? null : '*';

            const field = fields.find((f) => f.id === item);

            data[field.expField || item] = { [field.exp]: value };
        })

        this.setState({ errors: errors });

        this.props.onFilterChange(data);
    }

    onClearAllClick(e) {

        this.setState({ form: { filters: [] }, errors: {} });

        this.props.onFilterChange({});
    }

    getFields() {

        const practitioners = this.props.practitioners && this.props.practitioners.map((item) => ({ id: item.id, label: item.name }));

        const tpas = this.props.tpas && this.props.tpas.items && this.props.tpas.items.map((item) => ({ id: item.id, label: item.name }));

        const statusValues = domain.appointment.statuses.map((item) => ({ id: item.id, label: I18n.get(item.label) }));

        const fields = [
            {
                id: 'appointmentTpaId',
                label: I18n.get('TPA'),
                exp: 'eq',
                explabel: I18n.get('is'),
                cmp: (<SelectField {...this.state.fields.appointmentTpaId} value={this.state.form.appointmentTpaId} error={this.state.errors.appointmentTpaId} values={tpas} />),
            },            
            {
                id: 'alternateId',
                label: I18n.get('Id'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: (<TextField {...this.state.fields.alternateId} value={this.state.form.alternateId} error={this.state.errors.alternateId} />)
            },
            {
                id: 'status',
                label: I18n.get('Status'),
                exp: 'eq',
                expLabel: 'is',
                cmp: (<SelectField {...this.state.fields.status} value={this.state.form.status} error={this.state.errors.status} values={statusValues} />),
            },
            {
                id: 'name',
                label: I18n.get('Name'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />
            },            
            {
                id: 'address1',
                label: I18n.get('Address'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.address1} value={this.state.form.address1} error={this.state.errors.address1} />
            },            
            {
                id: 'city',
                label: I18n.get('City'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.city} value={this.state.form.city} error={this.state.errors.city} />
            },
            {
                id: 'state',
                label: I18n.get('State'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.state} value={this.state.form.state} error={this.state.errors.state} />
            },
            {
                id: 'practitionerId',
                label: I18n.get('Practitioner'),
                exp: 'eq',
                expLabel: 'is',
                cmp: <SelectField {...this.state.fields.practitionerId} value={this.state.form.practitionerId} error={this.state.errors.practitionerId} values={practitioners} />
            },
            {
                id: 'scheduledDate',
                label: I18n.get('Scheduled Date'),
                exp: 'between',
                expLabel: 'between',
                cmp: <DateRangeField {...this.state.fields.scheduledDate} value={this.state.form.scheduledDate} error={this.state.errors.scheduledDate} />
            },
            // {
            //     id: 'scheduledDateGt',
            //     label: 'Scheduled Date - from',
            //     exp: 'gt',
            //     expLabel: '',
            //     expField: 'scheduledDate',
            //     cmp: <MaskedTextField {...this.state.fields.scheduledDateGt} value={this.state.form.scheduledDateGt} error={this.state.errors.scheduledDateGt} />
            // },
            // {
            //     id: 'scheduledDateLt',
            //     label: 'Scheduled Date - to',
            //     exp: 'lt',
            //     expLabel: '',
            //     expField: 'scheduledDate',
            //     cmp: <MaskedTextField {...this.state.fields.scheduledDateLt} value={this.state.form.scheduledDateLt} error={this.state.errors.scheduledDateLt} />
            // },
            
        ];

        return fields;
    }

    render() {

        const fields = this.getFields();

        return (<div className="flex flex-col sm:flex-row mb-4">

            <div className="flex-none mr-8">


                <nav className="flex flex-row border-b-2 py-2">
                    <span className="py-1 px-2 ml-1 block italic text-gray-600">filter:</span>
                    {fields.map((item, index) => {

                        if (item.id == 'appointmentTpaId' && !this.props.auth.is_master_or_provider) return null;

                        if (item.id == 'practitionerId' && this.props.auth.is_practitioner) return null;

                        const checked = this.state.form.filters.find((s) => s === item.id);
                        const className = 'py-1 px-2 ml-1 mt-1 block text-sm rounded ' + (checked !== undefined ? 'bg-primary shadow text-smart-gray-lighter' : 'text-gray-600 hover:bg-smart-gray-lighter');

                        return (
                            <button key={index} onClick={(e) => this.onChangeFormArrayToggle('filters', item.id)} className={className}>
                                {item.label}
                            </button>
                        );
                    })}
                </nav>

                <div className="mt-4 text-right">

                    {fields.map((item) => {
                        const enabled = this.state.form.filters.find((s) => s === item.id);

                        if (!enabled) return null;

                        const index = this.state.form.filters.indexOf(item.id);

                        return (
                            <div key={item.id} className="flex mb-1">
                                <div className="flex-1 flex items-center justify-end">
                                    {/* {index > 0 && <span className="text-xs text-gray-600 px-4">{I18n.get('and')}</span>} */}
                                    <span className="text-sm">{item.label}</span>
                                    <span className="text-xs text-gray-600 px-4">{item.expLabel}</span>
                                </div>
                                <div className="flex-1">
                                    {item.cmp}
                                </div>
                            </div>
                        );
                    })}

                    <div className="flex mb-1">
                        <div className="flex-1">
                            <button onClick={this.onClearAllClick.bind(this)} className="btn-tertiary mt-4">{I18n.get('Clear All')}</button>
                        </div>
                        <div className="flex-1">
                            <button onClick={this.onFilterClick.bind(this)} className="btn-primary mt-4" disabled={!this.state.form.filters.length} >{I18n.get('Filter')}</button>
                        </div>
                    </div>



                </div>

            </div>

        </div>);
    }
}

export default ReportList;