import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DetailsItem from '../../components/ui/details-item';

import DateTimeSpan from '../../components/datetime-span';

import { DetailsListIcon } from '../../components/ui/icons';

import domain from '../../domain';

class DetailsPage extends React.Component {

    constructor(props) {
        super();

        this.state = {
            edit: true,
            selected: {},
            fields: {
                id: '',
                addToGroup: {
                    onChange: this.onChangeForm.bind(this, 'addToGroup'),
                },
            },
            form: {

            }
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    render() {

        const provider = this.props.provider || {};

        const status = domain.medicalProvider.statusMap[provider.status] || {};

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <DetailsListIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Provider Details')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/edit`} className="btn-primary">
                        {I18n.get('Edit')}
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="card">

                    <div className="body">

                        <p className="subtitle mb-8">{I18n.get('Provider Details')}</p>

                        <DetailsItem label={I18n.get('Name')} value={provider.name} />
                        <DetailsItem label={I18n.get('Public URL')} value={provider.publicURL} />
                        <DetailsItem label={I18n.get('Website')} value={provider.website} />
                        <DetailsItem label={I18n.get('Status')} value={I18n.get(status.label)} />
                        <DetailsItem label={I18n.get('Created')} value={<DateTimeSpan value={provider.createdAt} />} />
                        <DetailsItem label={I18n.get('Updated')} value={<DateTimeSpan value={provider.updatedAt} />} />

                        <p className="subtitle mt-12 mb-8">{I18n.get('Billing Information')}</p>

                        <DetailsItem label={I18n.get('Contact Name')} value={provider.contactName} />
                        <DetailsItem label={I18n.get('Contact Email')} value={provider.contactEmail} />
                        <DetailsItem label={I18n.get('Contact Phone')} value={`${provider.contactPhoneNumberCode} ${provider.contactPhoneNumber}`} />
                        <DetailsItem label={I18n.get('Tax Id')} value={provider.taxId} />
                        <DetailsItem label={I18n.get('Preferred Due Date')} value={provider.preferredDueDate} />

                        <p className="subtitle mt-12 mb-8">{I18n.get('Billing Address')}</p>

                        <DetailsItem label={I18n.get('Street Address')} value={provider.address1} />
                        <DetailsItem label={I18n.get('Unit')} value={provider.address2} />
                        <DetailsItem label={I18n.get('Zip Code')} value={provider.zipcode} />
                        <DetailsItem label={I18n.get('City')} value={provider.city} />
                        <DetailsItem label={I18n.get('State')} value={provider.state} />
                        <DetailsItem label={I18n.get('Country')} value={provider.countryId} />

                    </div>

                </div>

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(DetailsPage);

