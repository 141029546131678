import React from 'react';

function computeTotalDistance(result) {
    var total = 0;
    var myroute = result.routes[0];
    for (var i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value;
    }
    total = total / 1000;
    return total;
}

class Map extends React.Component {
    constructor(props) {

        super();

        this.state = {
            distance: null
        }

        this.mapEl = React.createRef();
    }

    static defaultProps = {
        className: ''
    }

    componentDidMount() {

        console.log('componentDidMount Map');
        console.log(this.props);

        this.renderMap();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps) != JSON.stringify(this.props)) {
            this.renderMap();
        }
    }

    renderMap() {
        var map;

        if (this.props.defaultCenter) {
            map = new window.google.maps.Map(this.mapEl.current, {
                center: this.props.defaultCenter,
                zoom: this.props.zoom || 15
            });

            if (this.props.marker) {
                var marker = new window.google.maps.Marker({
                    position: this.props.marker,
                    map: map,
                });
            }

            if (this.props.radius) {
                var circle = new window.google.maps.Circle({
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                    map: map,
                    center: this.props.defaultCenter,
                    radius: this.props.radius * 1000,
                    //editable: true
                });

                map.fitBounds(circle.getBounds());

                window.google.maps.event.addListener(circle, 'radius_changed', function () {
                    console.log(circle.getRadius());
                });
            }

            if (this.props.coverage && this.props.coverage.length) {

                this.props.coverage.map((item) => {

                    var circle = new window.google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: new window.google.maps.LatLng(item.lat, item.lng),
                        radius: item.radius * 1000,
                        //editable: true
                    });
                })
            }

            if (this.props.origin) {

                var directionsService = new window.google.maps.DirectionsService;
                var directionsDisplay = new window.google.maps.DirectionsRenderer({
                    draggable: true,
                    map: map,
                    panel: document.getElementById('right-panel')
                });

                directionsDisplay.addListener('directions_changed', () => {

                    const distance = computeTotalDistance(directionsDisplay.getDirections());

                    this.setState({ distance: distance });
                });

                directionsService.route({
                    origin: this.props.origin,
                    destination: this.props.defaultCenter,
                    travelMode: 'DRIVING'
                }, function (response, status) {
                    if (status === 'OK') {
                        directionsDisplay.setDirections(response);
                    } else {
                        alert('Could not display directions due to: ' + status);
                    }
                });
            }


            if (this.props.geojson && this.props.geojson.features && this.props.geojson.features.length) {
                map.data.addGeoJson(this.props.geojson);
            }
        }
    }

    render() {

        const className = 'map-container ' + this.props.className;

        return (<div className={className}>
            <div ref={this.mapEl} className="map-element shadow-lg" style={this.props.style}></div>

            <br />

            {this.state.distance > 0 && <div>

                <p>
                    Total driving distance is {this.state.distance} kilometers.
                </p>

            </div>}

        </div>);
    }
};

export default Map;