import React from 'react';

import NumberFormat from 'react-number-format';

const TextField = ({ label, onChange, name, mask, format, placeholder = '', value = '', style = {}, isOptional, error, className = 'field mb-4' }) => {


    const errorClassName = 'border-red-500';

    const inputClassName = 'flex-grow appearance-none border rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:shadow-outline ';

    return (
        <div className={className}>
            {label && <label className="label">{label}</label>}
            <div className="control">
                <NumberFormat
                    onChange={onChange}
                    className={inputClassName + (error ? errorClassName : '')}
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    style={style}
                    mask={mask}
                    format={format}
                />
                {error && <p className="mt-2 text-red-500 text-xs italic">{error}</p>}
            </div>
        </div>
    );
}

export default TextField;