import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import Dropdown from '../../components/ui/drop-down';

import NotificationItem from './notification-item';

import { NotificationFillIcon } from '../../components/ui/icons';


class NotificationBox extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
            task: {}
        };
    }

    select(item, e) {

        if (e) e.preventDefault();

        const data = {
            id: item.id,
            username: item.username,
            status: 1
        };

        this.props.update_notification(data);

        CORE_API.select_notification(item);
    }

    clearAll(e){
        e.preventDefault();

        var task = new Task();

        this.setState({ task: task });

        this.props.clearAll_notifications()
            .then(({ notifications }) => {

                this.setState({ task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`All notifications have been marked as read.`) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });        
    }

    render() {


        const not_seen = this.props.notifications && this.props.notifications.filter((item) => item.status == 0);

        const Bell = ({ count = 0 }) => {

            console.log({ count: count });

            if (count > 0) {
                return (<span data-badge={count}>
                    <NotificationFillIcon className="icon md" />
                </span>);
            }
            else {
                return (<NotificationFillIcon className="icon md" />);
            }
        }

        return (
            <Dropdown
                key="notification"
                className="h-full sm:border-l dropdown-lg"
                style={{ width: '65px' }}
                label={(<Bell count={not_seen ? not_seen.length : 0} />)}
            >
                <div className="px-2 shadow-lg" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {this.props.notifications && this.props.notifications.map((item, index) => {
                        return (
                            <a key={index} className="cursor-pointer" onClick={this.select.bind(this, item)}>
                                <NotificationItem item={item} />
                            </a>);
                    })}
                    {this.props.notifications && !this.props.notifications.length && <div>
                        <div className="px-4 py-8">
                            No notifications.
                        </div>
                    </div>}
                    {this.props.notifications && this.props.notifications.length > 0 && <div className="p-4 text-center">
                        <button onClick={this.clearAll.bind(this)} className="btn-tertiary" disabled={not_seen && not_seen.length == 0} >{I18n.get('Clear All')}</button>
                    </div>}
                </div>
            </Dropdown>
        );
    }
}


export default CORE_API.clearAll_notifications(CORE_API.update_notification(NotificationBox));