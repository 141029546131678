import { 
    CORE_NOTIFICATION_NEW,
    CORE_CREATE_TOAST,
    CORE_DELETE_TOAST
} from '../actions/core/actions';

const initialState = {
    toasts: [],
    errors: {},
    task: {}
};

export default function coreReducer(state = initialState, action) {

    if (action.type == 'AUTH_LOGOUT') return initialState;

    if (action.type.indexOf('CORE_') == -1) return state;

    if (action.type == CORE_NOTIFICATION_NEW){
        
        return Object.assign({}, state, { notifications: [...state.notifications, action.payload] });
    }

    if (action.type == CORE_CREATE_TOAST){

        const toasts =  [...state.toasts, action.payload];
        
        return Object.assign({}, state, { toasts: toasts });
    }

    if (action.type == CORE_DELETE_TOAST){

        const toasts = state.toasts.filter((item) => item.id !== action.payload.id);

        return Object.assign({}, state, { toasts: toasts });
    }

    return Object.assign({}, state, action.payload);
}
