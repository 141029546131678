import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';

//import './layout.css';

import ToastContainer from '../shared/toast-container';

class Layout extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <nav className="flex justify-between bg-white shadow-lg fixed w-full z-10">

                    <div className="md:flex-none flex-1 md:border-r flex justify-center" style={{ width: '245px' }}>
                        <NavLink to={`/`}>
                            <img className="h-12 sm:h-16" src="/img/smartprovider-logo.svg" />
                        </NavLink>
                    </div>

                </nav>

                <div className="flex justify-center items-center h-screen">

                    {this.props.children}

                    <br />

                </div>

                <ToastContainer toasts={this.props.core.toasts} />

            </div>
        );
    }
}

export default Layout;