import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { ListIcon, ProviderUserIcon, AuditLogIcon } from '../../components/ui/icons';

const ASide = ({ auth, is_ctx_master, menuIsOpen = false }) => {

    if (!auth.is_master) return null;

    if (!is_ctx_master) return null;

    const labelCssClass = menuIsOpen ? 'inline-block label ' : 'hidden lg:inline-block label ';

    return (
        <aside className="mt-12 sm:mt-16 text-gray-300">

            <ul className="list-reset flex flex-col">

                <li className="flex-1" style={{ backgroundColor: '#3D4961' }}>
                    <div className="h-20 px-5 py-6">
                        <span className={labelCssClass + ' text-2xl'}>{I18n.get('Smart Provider')}</span>
                    </div>
                </li>

                <li className="mt-8">
                    <NavLink to={`/providers`} className="menu-item hover:text-smart-blue">
                        <ListIcon />
                        <span className={labelCssClass}>{I18n.get('Providers')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/users`} className="menu-item hover:text-smart-blue">
                        <ProviderUserIcon />
                        <span className={labelCssClass}>{I18n.get('Master Users')}</span>
                    </NavLink>

                </li>

                <li>
                    <NavLink to={`/audit-logs`} className="menu-item hover:text-smart-blue">
                        <AuditLogIcon />
                        <span className={labelCssClass}>{I18n.get('Audit Logs')}</span>
                    </NavLink>
                </li>                

            </ul>

        </aside>
    );

}

export default ASide;