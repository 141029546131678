import React from 'react';

import { InformationIcon } from './ui/icons';

const TaskMessage = (props) => {

    if (props.result && props.children) {
        return (<div className="p-2">
            <InformationIcon /> {props.children}
        </div>);
    }

    if (props.error) {
        return (<article className="task-failure">
            <span>
                {props.error.message}
            </span>
        </article>);
    }

    return null;
}

export default TaskMessage;