import React from 'react';

import NumberFormat from 'react-number-format';

import MaskedTextField from './masked-text-field';

const DateRangeField = ({ label, onChange, fieldName, placeholder = '', value = [], style = {}, maxLength, isOptional, error, className = 'field mb-4' }) => {

    function handleChangeFrom(e) {

        const from = e.target.value;

        onChange([from, value[1]]);
    }

    function handleChangeTo(e) {

        const to = e.target.value;

        onChange([value[0], to]);
    }

    return (<div className="flex flex-row items-center">
        <MaskedTextField
            onChange={handleChangeFrom}
            className={"flex-1" + (error ? 'is-danger' : '')}
            mask={['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D']}
            format="####-##-##"
            placeholder='YYYY-MM-DD'
            value={value[0] || ''}
            style={style}
        />
        <MaskedTextField
            onChange={handleChangeTo}
            className={"flex-1 ml-2" + (error ? 'is-danger' : '')}
            mask={['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D']}
            format="####-##-##"
            placeholder='YYYY-MM-DD'
            value={value[1] || ''}
            style={style}
        />
        {error && <p className="help is-danger">
            {error}
        </p>}
    </div>);
}

export default DateRangeField;