
export const getPractitionerGroup = /* GraphQL */ `
  query GetPractitionerGroup($id: ID!) {
    getPractitionerGroup(id: $id) {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
        groups {
          id
          name
          score
          createdAt
          updatedAt
        }        
      }
    }
  }
`