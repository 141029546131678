
export const getPractitioner = `query GetPractitioner($id: ID!) {
  getPractitioner(id: $id) {
    id
    email
    name
    dob
    phoneNumberCode
    phoneNumber
    gender
    speciality
    license
    location
    status
    createdAt
    updatedAt
    provider {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      publicURL
      settings
      groups {
        items {
          id
          name
          score
          createdAt
          updatedAt
          provider {
            id
            name
          }
        }
        nextToken
      }      
    }
    groups {
      id
      name
      score
      createdAt
      updatedAt
    }
    includeTPAs {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
    }
    excludeTPAs {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
    }
    address1
    address2
    address3
    address4
    zipcode
    city
    state
    countryId
    radius
    radiusUnit
    availability
    lat
    lng
    timezoneId
    timezoneName
    calendar
    user {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
    isVIP
    specialities
    position
  }
}
`