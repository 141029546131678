import React from 'react';

import config from '../aws-exports';

class GoogleMapsLoader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    componentDidMount() {

        window.initMap = () => {
            console.log('initMap');
            this.setState({ loaded: true });
        }

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${config.google_maps_api_key}&callback=initMap&libraries=places`;
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        if (this.state.loaded) {
            return this.props.children;
        }
        else {
            return (<div>Loading Google Maps...</div>);
        }
    }
}


export default GoogleMapsLoader;