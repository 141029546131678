import React from 'react';
import { connect } from 'react-redux';

import { CORE_API } from '../actions/core/actions';

import NotificationContainer from './notification-container';

import { compose } from 'react-apollo';

export default function TPAContainer(Component) {

    const mapStateToProps = state => {

        console.log(state);

        return Object.assign({}, { 
            auth: state.auth, 
            core: state.core,
            appointment: {},
            task: {}, 
            prepare_task: {}, 
            request_task: {}, 
            errors: {},
            is_ctx_tpa: true
        });
    };

    const mapDispatchToProps = dispatch => {
        return {};
    };    

    class TPAContext extends React.Component {

        constructor(props) {
            super();
            this.state = {
            };
        }

        componentDidMount() {
        }

        componentDidUpdate(prevProps, prevState, snapshot) {

        }

        componentWillUnmount() {
        }

        render() {

            return (<Component {...this.props} />);
        }
    }

    return compose(
        CORE_API.get_tpa, 
        CORE_API.update_tpa,
        CORE_API.prepare_appointment,
        CORE_API.request_appointment,
        CORE_API.update_user,
        CORE_API.disable_user,
        CORE_API.enable_user,
        CORE_API.delete_user,
        NotificationContainer
    )(connect(mapStateToProps, mapDispatchToProps)(TPAContext));

}