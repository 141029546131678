import React from 'react';
import { connect } from 'react-redux';

import * as subscriptions from '../graphql/subscriptions';

import { CORE_API } from '../actions/core/actions';

import gql from 'graphql-tag';
import { compose } from 'react-apollo';

import NotificationContainer from './notification-container';

export default function PractitionerContainer(Component) {

    const mapStateToProps = state => {

        console.log(state);

        return Object.assign({}, {
            auth: state.auth,
            core: state.core,
            task: {},
            prepare_task: {},
            request_task: {},
            errors: {},
        });
    };

    const mapDispatchToProps = dispatch => {
        return {};
    };

    class PractitionerContext extends React.Component {

        constructor(props) {
            super();
            this.state = {
            };
        }

        componentDidMount() {
        }

        componentDidUpdate(prevProps, prevState, snapshot) {


        }

        componentWillUnmount() {
        }

        render() {

            return (<Component {...this.props} />);
        }
    }

    return compose(
        CORE_API.get_practitioner,
        CORE_API.update_practitioner,
        CORE_API.update_appointment_request,
        CORE_API.disable_practitioner,
        CORE_API.enable_practitioner,
        CORE_API.delete_practitioner,
        CORE_API.update_user,
        NotificationContainer
    )(connect(mapStateToProps, mapDispatchToProps)(PractitionerContext));
}

