import { getPractitioner } from '../../graphql/queries/getPractitioner';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${getPractitioner}`,
    {
        options: ({ match: { params: { practitionerId } } }) => ({
            variables: {
                id: practitionerId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getPractitioner = {}, loading } }) => {

            const practitioner = getPractitioner || {};
            const provider = practitioner.provider || {};

            if (typeof (practitioner.specialities) === 'string') {
                practitioner.specialities = JSON.parse(practitioner.specialities)
            }            

            if (typeof (practitioner.availability) === 'string') {
                practitioner.availability = JSON.parse(practitioner.availability)
            }

            if (typeof (practitioner.calendar) === 'string') {
                practitioner.calendar = JSON.parse(practitioner.calendar)
            }

            if (typeof (practitioner.includeTPAs) === 'string') {
                practitioner.includeTPAs = JSON.parse(practitioner.includeTPAs)
            }

            if (typeof (practitioner.excludeTPAs) === 'string') {
                practitioner.excludeTPAs = JSON.parse(practitioner.excludeTPAs)
            }

            if (typeof (practitioner.position) === 'string') {
                practitioner.position = JSON.parse(practitioner.position)
            }

            return {
                practitioner: practitioner,
                provider: provider,
                providerId: provider.id,
                loading: loading
            }
        }
    }
)