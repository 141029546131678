import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.updatePractitioner}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            update_practitioner: (data) => {

                if (typeof (data.specialities) !== 'string') {
                    data.specialities = JSON.stringify(data.specialities);
                }

                if (typeof (data.groups) !== 'string') {
                    data.groups = JSON.stringify(data.groups);
                }

                if (typeof (data.availability) !== 'string') {
                    data.availability = JSON.stringify(data.availability);
                }

                if (typeof (data.includeTPAs) !== 'string') {
                    data.includeTPAs = JSON.stringify(data.includeTPAs);
                }

                if (typeof (data.excludeTPAs) !== 'string') {
                    data.excludeTPAs = JSON.stringify(data.excludeTPAs);
                }

                if (typeof (data.position) !== 'string') {
                    data.position = JSON.stringify(data.position);
                }                

                return props.mutate({
                    variables: { input: data }
                })
                    .then(({ data: { updatePractitioner = {} } }) => {

                        const practitioner = updatePractitioner;

                        if (typeof (practitioner.specialities) === 'string') {
                            practitioner.specialities = JSON.parse(practitioner.specialities);
                        }

                        if (typeof (practitioner.groups) === 'string') {
                            practitioner.groups = JSON.parse(practitioner.groups);
                        }

                        if (typeof (practitioner.availability) === 'string') {
                            practitioner.availability = JSON.parse(practitioner.availability);
                        }

                        if (typeof (practitioner.includeTPAs) === 'string') {
                            practitioner.includeTPAs = JSON.parse(practitioner.includeTPAs);
                        }

                        if (typeof (practitioner.excludeTPAs) === 'string') {
                            practitioner.excludeTPAs = JSON.parse(practitioner.excludeTPAs);
                        }
                        
                        if (typeof (practitioner.position) === 'string') {
                            practitioner.position = JSON.parse(practitioner.position);
                        }

                        return {
                            practitioner: practitioner
                        }
                    });
            }
        })
    })
