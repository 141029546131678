import React from 'react';
import { I18n } from 'aws-amplify';

import moment from 'moment';

const Selector = ({ value, timezoneId, onClick, isSelected }) => {

    const ts = moment.tz(value, timezoneId);

    console.log({ value: value, timezoneId: timezoneId, ts: ts.format() });

    const className = 'inline-block text-xs rounded-full text-center w-16 h-16 p-4 uppercase cursor-pointer ' + (isSelected ? ' bg-primary text-white' : 'bg-smart-blue-light text-secondary');

    return (<div className="flex-none ml-4 text-center">

        <div className="uppercase text-center text-gray-500 p-2"> {ts.format('ddd')} </div>

        <span className={className} style={{ minWidth: '50px', minHeight: '50px' }} onClick={onClick}>
            {ts.format('MMM')} <br />{ts.format('DD')}
        </span>

    </div>)
}

export default Selector;