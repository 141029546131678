import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import UserItem from '../shared/_user-item';

import DateTimeSpan from '../../components/datetime-span';

class List extends React.Component {

    constructor(props) {
        super();
        this.state = {
            pages: [],
            token: '',
            form: {
            },
            limit: 20
        }
    }

    componentDidMount() {
    }

    nextPage(token) {

        this.setState(prevState => {

            return { pages: [...prevState.pages, prevState.token], token: token };

        }, function () {

            const data = {
                nextToken: this.state.token,
                limit: this.state.limit
            }

            this.props.next(data);
        });
    }

    render() {

        const users = this.props.users || [];

        //if (this.props.loading) return (<div>Loading...</div>);

        return (<section className={this.props.loading ? 'loading-mask' : ''}>

            <div className="hidden sm:block font-semibold">
                <div className="px-4 py-4">

                    <div className="flex justify-between">

                        <div className="flex-1">
                            {I18n.get('User')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Status')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Created')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Updated')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                {users && users.map((item, index) =>
                    (<div key={index} onClick={(e) => this.props.select(item, e)} className="cursor-pointer">
                        <UserItem key={index} item={item} />
                    </div>
                    )
                )}
            </div>


        </section>);

    }
}

export default List;
