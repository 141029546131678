import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.deletePractitioner}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            delete_practitioner: (data) => {

                if (typeof (data.tpas) !== 'string') {
                    data.tpas = JSON.stringify(data.tpas);
                }
        
                if (typeof (data.groups) !== 'string') {
                    data.groups = JSON.stringify(data.groups);
                }
        
                if (typeof (data.availability) !== 'string') {
                    data.availability = JSON.stringify(data.availability);
                }

                return props.mutate({
                    variables: { input: data }
                })
                .then(({ data: { deletePractitioner = {} } }) =>{

                    const practitioner = deletePractitioner;

                    if (typeof (practitioner.tpas) === 'string') {
                        practitioner.tpas = JSON.parse(practitioner.tpas);
                    }
            
                    if (typeof (practitioner.groups) === 'string') {
                        practitioner.groups = JSON.parse(practitioner.groups);
                    }
            
                    if (typeof (practitioner.availability) === 'string') {
                        practitioner.availability = JSON.parse(practitioner.availability);
                    }                    
                    
                    return {
                        practitioner: practitioner
                    }
                });
            }
        })
    })
