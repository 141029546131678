import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import TPAContainer from '../../containers/tpa-container';
import TPALayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';

import { CloseButtonIcon } from '../../components/ui/icons';

class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                id: '',
                profile: {
                    name: 'profile',
                    label: I18n.get('Profile'),
                    onChange: this.onChangeForm.bind(this, 'profile'),
                },
                name: {
                    name: 'name',
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
                email: {
                    name: 'email',
                    label: I18n.get('Email'),
                    onChange: this.onChangeForm.bind(this, 'email'),
                },
            },
            form: {
                profile: 'TPA'
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        const data = {
            ...this.state.form,
            providerId: provider.id,
            tpaId: tpa.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.create_user(data)
            .then(({ user }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/t/${tpa.id}/users`);

                CORE_API.create_toast({ message: I18n.get(`TPA User  '@name' has been created. An email has been sent to the user with a temporary password`).replace('@name', user.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    getProfiles() {
        return [
            { id: "TPA", label: "TPA" },
        ];
    }

    render() {

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        const profiles = this.getProfiles();

        return (<TPALayout {...this.props}>


            <div className="page-header">

                <div className="title">
                    {I18n.get('Create TPA User')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/users`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('User Details')}</p>

                            <SelectField {...this.state.fields.profile} value={this.state.form.profile} error={this.state.errors.profile} values={profiles} />

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>User successfully created.</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Create')} />

                        </div>

                    </div>

                </form>

            </div>

        </TPALayout>);
    }

}

export default TPAContainer(CORE_API.create_user(UsersPage));

