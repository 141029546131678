import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import TPALayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import MaskedTextField from '../../components/form/masked-text-field';
import SelectField from '../../components/form/select-field';
import SelectSearchField from '../../components/form/select-search-field';

import domain from '../../domain';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
};

class CreatePage extends React.Component {

    constructor(props) {

        super();

        this.state = {
            fields: {
                id: '',
                name: {
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
                alternateId: {
                    label: I18n.get('Id'),
                    onChange: this.onChangeForm.bind(this, 'alternateId'),
                },
                dob: {
                    label: I18n.get('Date of birth'),
                    onChange: this.onChangeForm.bind(this, 'dob'),
                    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
                    format: "####-##-##",
                    placeholder: 'YYYY-MM-DD'
                },
                phoneNumber: {
                    label: I18n.get('Phone number'),
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: '_',
                    format: '+### ###-###-####',
                },
                gender: {
                    label: I18n.get('Gender'),
                    onChange: this.onChangeForm.bind(this, 'gender'),
                },
                symptoms: {
                    label: I18n.get('Symptoms'),
                    onChange: this.onChangeForm.bind(this, 'symptoms'),
                },
                otherSymptom: {
                    label: I18n.get('Other Symptoms'),
                    onChange: this.onChangeForm.bind(this, 'otherSymptom'),
                },
                address1: {
                    label: I18n.get('Street Address'),
                    onChange: this.onChangeForm.bind(this, 'address1'),
                },
                address2: {
                    label: I18n.get('Unit'),
                    placeholder: 'Apt, Suite, Bldg, Gate Code.',
                    onChange: this.onChangeForm.bind(this, 'address2'),
                },
                zipcode: {
                    label: I18n.get('Zip Code'),
                    onChange: this.onChangeForm.bind(this, 'zipcode'),
                },
                city: {
                    label: I18n.get('City'),
                    onChange: this.onChangeForm.bind(this, 'city'),
                },
                state: {
                    label: I18n.get('State'),
                    onChange: this.onChangeForm.bind(this, 'state'),
                },
                comments: {
                    label: I18n.get('Comment'),
                    onChange: this.onChangeForm.bind(this, 'comments'),
                },
                symptoms: {
                    onChange: this.onChangeFormArrayToggle.bind(this, 'symptoms'),
                },
            },
            form: {
                symptoms: [],
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
        const data = {
            appointmentId: this.props.match.params.appointmentId
        }

        CORE_API.get_appointment(data);

    }

    componentWillUnmount() {
        CORE_API.reset();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var nextState = Object.assign({}, prevState);
        var updateState = false;

        if (this.state.form.id != this.props.core.appointment.id) {

            Object.keys(this.state.fields).forEach((item) => {
                nextState.form[item] = this.props.core.appointment[item];
            });

            updateState = true;
        }

        if (JSON.stringify(this.props.core.task) != JSON.stringify(this.state.task)) {

            nextState.task = this.props.core.task;
            nextState.errors = this.props.core.errors;

            updateState = true;
        }

        if (updateState) {
            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onChangeFormArrayDel(prop, value) {

        this.setState(prevState => {

            const arr = prevState.form[prop].filter((item) => item !== value);

            return {
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            };
        });
    }

    onSubmitForm(e) {
        e.preventDefault();

        const data = {
            ...this.state.form,
            appointmentTpaId: this.props.core.tpa.id,
            appointmentProviderId: this.props.core.tpa.provider.id
        }

        CORE_API.update_appointment(data);
    }

    getStates() {

        //TODO: Fix

        return domain['US'].states;
    }

    getGenders() {
        return domain.genders;
    }

    getSymptoms() {
        return domain.symptoms;
    }

    getSymptom (id) {
        return domain.symptomMap[id] || {};
    }

    render() {

        const tpa = this.props.core.tpa || {};
        const provider = tpa.provider || {};

        return (<TPALayout {...this.props}>

            <nav className="breadcrumb" aria-label={I18n.get('breadcrumbs')}>
                <ul>
                    <li><NavLink to={`/`} className="is-link">{I18n.get('Admin')}</NavLink></li>
                    <li className="is-active"><NavLink to={``} className="is-link">{I18n.get('Edit Appointment')}</NavLink></li>
                </ul>
            </nav>

            <br />

            <div className="columns">

                <div className="column is-5">

                    <div className="card">
                        <div className="card-content">

                            <BackButton {...this.props} />

                            <p className="title is-4">{I18n.get('Appointment')}</p>

                            <form onSubmit={this.onSubmitForm.bind(this)}>

                                <p className="form-group">{I18n.get('Paitent Information')}</p>

                                <br />

                                <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                                <TextField {...this.state.fields.alternateId} value={this.state.form.alternateId} error={this.state.errors.alternateId} />

                                <MaskedTextField {...this.state.fields.dob} value={this.state.form.dob} error={this.state.errors.dob} />

                                <MaskedTextField {...this.state.fields.phoneNumber} value={this.state.form.phoneNumber} error={this.state.errors.naphoneNumberme} />

                                <SelectField {...this.state.fields.gender} value={this.state.form.gender} error={this.state.errors.gender} values={this.getGenders()} />

                                <br />

                                <p className="form-group">{I18n.get('Patient Location')}</p>

                                <br />

                                <TextField {...this.state.fields.address1} value={this.state.form.address1} error={this.state.errors.address1} />

                                <TextField {...this.state.fields.address2} value={this.state.form.address2} error={this.state.errors.address2} />

                                <TextField {...this.state.fields.zipcode} value={this.state.form.zipcode} error={this.state.errors.zipcode} style={{ width: '50%' }} />

                                <TextField {...this.state.fields.city} value={this.state.form.city} error={this.state.errors.city} />

                                <SelectField {...this.state.fields.state} value={this.state.form.state} error={this.state.errors.state} values={this.getStates()} />

                                <br />

                                <p className="form-group">{I18n.get('Symptoms')}</p>

                                <SelectSearchField {...this.state.fields.symptoms} values={domain.symptoms} />

                                <br />

                                {this.state.form.symptoms && this.state.form.symptoms.map((symptomId, index) => {

                                    return (
                                        <span key={index} className="tag is-medium" style={{ margin: '0.25rem', minWidth: '170px' }}>
                                            {this.getSymptom(symptomId).label}
                                            <button type="button" onClick={(e) => this.onChangeFormArrayToggle('symptoms', symptomId)} className="delete is-small is-pulled-right"></button>
                                        </span>
                                    );
                                })}

                                <br />
                                <br />
                                <br />

                                <TaskMessage {...this.props.core.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                                <ActionButton task={this.props.core.task} label={I18n.get('Save Changes')} />

                            </form>

                        </div></div>

                </div>

            </div>

        </TPALayout>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);

