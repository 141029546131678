import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { getMedicalProviderCoverage } from '../../graphql/queries/getMedicalProviderCoverage';

export default graphql(
    gql`${getMedicalProviderCoverage}`,
    {
        options: ({ match: { params: { providerId } } }) => ({
            variables: {
                id: providerId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getMedicalProvider = {}, loading } }) => {

            const provider = getMedicalProvider;

            return {
                coverage: provider.coverage,
            }
        }
    }
)