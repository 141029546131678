
import { CORE_UPDATE } from './actions';

const api_call = (data) => {
    return (dispatch, getState) => {

        dispatch({
            type: CORE_UPDATE,
            payload: {
                task: {},
                prepare_task: {},
                request_task: {},
                errors: {}
            }
        });

    }
}

export default api_call;