
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { getPractitionerGroup } from '../../graphql/queries/getPractitionerGroup';

export default graphql(
    gql`${getPractitionerGroup}`,
    {
        options: ({ practitionerGroupId }) => ({
            variables: {
                id: practitionerGroupId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getPractitionerGroup = {}, loading, refetch } }) => {

            const practitioner_group = getPractitionerGroup || {};

            practitioner_group.practitioners && practitioner_group.practitioners.map((practitioner) => {
                if (typeof (practitioner.specialities) === 'string') {
                    practitioner.specialities = JSON.parse(practitioner.specialities)
                }
            });

            return {
                practitioner_group: practitioner_group,
                loading: loading,
                practitioner_group_refetch: refetch
            }
        }
    }
);