export class AppointmentStatus {

    static get Draft() {
        return 0;
    }

    static get Requested() {
        return 1;
    }

    static get Assigned() {
        return 2;
    }

    static get Started() {
        return 3;
    }

    static get NoShow() {
        return 4;
    }

    static get Completed() {
        return 5;
    }    

    static get Cancelled() {
        return 9;
    }
}


export class ProviderStatus {

    static get DISABLED() {
        return 0;
    }

    static get ENABLED() {
        return 1;
    }

    static get ARCHIVED() {
        return 9;
    }
}
