import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import { AppointmentStatus } from '../../model';

import * as subscriptions from '../../graphql/subscriptions';

import { listAppointmentsByPractitioner } from '../../graphql/queries/listAppointmentsByPractitioner';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { createCalendar } from '../../utils';

export default graphql(
    gql`${listAppointmentsByPractitioner}`,
    {
        options: ({ practitionerId, filter = {} }) => ({
            variables: {
                practitionerId: practitionerId,
                filter: filter,
                limit: 50
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { listAppointmentsByPractitioner = {}, loading, fetchMore, refetch, subscribeToMore } }) => {

            const appointments = listAppointmentsByPractitioner;

            appointments.items && appointments.items.map((item) => {
                if (typeof (item.specialities) === 'string') {
                    item.specialities = JSON.parse(item.specialities)
                }
                if (typeof (item.symptoms) === 'string') {
                    item.symptoms = JSON.parse(item.symptoms)
                }
            });

            const calendar = createCalendar(appointments.items);

            return {
                appointments: appointments,
                calendar: calendar,
                loading: loading,
                refetch: refetch,
                filter: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAppointmentsByPractitioner: {
                                ...fetchMoreResult.listAppointmentsByPractitioner
                            }
                        });
                    }
                }),                
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAppointmentsByPractitioner: {
                                ...fetchMoreResult.listAppointmentsByPractitioner,
                                items: [...prev.listAppointmentsByPractitioner.items, ...fetchMoreResult.listAppointmentsByPractitioner.items],
                            }
                        });
                    }
                }),
                subscribeToPractitionerAppointment: (practitionerId) => {

                    const info = {
                        document: gql`${subscriptions.onPractitionerAppointment}`,
                        updateQuery: (prev, { subscriptionData }) => {

                            //console.log({ prev: prev, subscriptionData: subscriptionData });

                            const item = subscriptionData.data.onPractitionerAppointment || {};

                            if (item.status == AppointmentStatus.Requested){

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been created.`).replace('@appointment', item.name) })
                            }
                            else {

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been updated.`).replace('@appointment', item.name) })
                            }

                            return prev;
                        },
                        variables: {
                            practitionerId: practitionerId
                        }
                    }

                    subscribeToMore(info);
                }
            }
        }
    }
)