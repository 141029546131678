import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.clearAllNotifications}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            clearAll_notifications: (data) => {

                return props.mutate({
                    variables: { input: data }
                })
                .then(({ data: { clearAllNotifications = [] } }) =>{

                    const listNotifications = clearAllNotifications && clearAllNotifications.map((item) => {

                        if (typeof (item.data) === 'string') {
                            item.data = JSON.parse(item.data);
                        }
                    });                    

                    return {
                        listNotifications: listNotifications
                    }
                });
            }
        })
    })
