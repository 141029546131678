import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.prepareAppointment}`,
    {
        options: {
        },
        props: (props) => ({
            prepare_appointment: (input) => {

                if (typeof (input.specialities) !== 'string') {
                    input.specialities = JSON.stringify(input.specialities);
                }

                if (typeof (input.symptoms) !== 'string') {
                    input.symptoms = JSON.stringify(input.symptoms);
                }

                return props.mutate({
                    variables: { input: input }
                })
                    .then(({ data: { prepareAppointment = {} } }) => {

                        const appointment = prepareAppointment;

                        if (typeof (appointment.availabilities) === 'string') {
                            appointment.availabilities = JSON.parse(appointment.availabilities);
                        }

                        if (typeof (appointment['specialities']) === 'string') {
                            appointment['specialities'] = JSON.parse(appointment['specialities']);
                        }                        

                        if (typeof (appointment['symptoms']) === 'string') {
                            appointment['symptoms'] = JSON.parse(appointment['symptoms']);
                        }                        

                        return {
                            appointment: appointment,
                        };
                    })
            }
        })
    })