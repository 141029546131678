import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { getMedicalProvider } from '../../graphql/queries/getMedicalProvider';

export default graphql(
    gql`${getMedicalProvider}`,
    {
        options: ({ match: { params: { providerId } } }) => ({
            variables: {
                id: providerId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getMedicalProvider = {}, loading } }) => {

            const provider = getMedicalProvider;

            if (typeof (provider.settings) === 'string') {
                provider.settings = JSON.parse(provider.settings)
            }            

            return {
                provider: provider,
                providerId: provider.id,
                loading: loading
            }
        }
    }
)