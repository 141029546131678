import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.deleteAdminUser}`,
    {
        options: {
        },
        props: (props) => ({
            delete_user: (data) => {

                return props.mutate({
                    variables: { input: data }
                }).then(({ data }) => {
                    return {
                        user: data.deleteAdminUser,
                    }
                })
            }
        })
    })
