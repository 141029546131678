import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import Modal from '../../components/modal';

import domain from '../../domain';

import { AppointmentStatus } from '../../model';

import SelectField from '../../components/form/select-field';

import moment from 'moment';

class AppointmentFilter extends React.Component {

    constructor(props) {

        super();

        const today = moment(new Date()).add(1, 'days').format("YYYY-MM-DD");
        const tomorrow = moment(new Date()).add(2, 'days').format("YYYY-MM-DD");
        const sevendays = moment(new Date()).add(7, 'days').format("YYYY-MM-DD");
        const thirtydays = moment(new Date()).add(30, 'days').format("YYYY-MM-DD");


        const scheduledDateValues = [
            { id: today, label: I18n.get('Today') },
            { id: tomorrow, label: I18n.get('Tomorrow') },
            { id: sevendays, label: I18n.get('Next 7 days') },
            { id: thirtydays, label: I18n.get('Next 30 days') },
        ];

        this.state = {
            selected: {},
            pages: [],
            token: '',
            fields: {
                scheduledDate: {
                    onChange: this.onChangeForm.bind(this, 'scheduledDate'),
                    values: scheduledDateValues,
                    selectClassName: 'select bg-primary text-white focus:outline-none',
                    chevronClassName: 'select-chevron text-white'
                },
            },
            form: {
                status: props.status || [],
                scheduledDate: props.scheduledDate || sevendays
            },
            load_task: {},
            errors: {},
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
            , () => {

                this.props.onFilterChange(this.state.form)
            });
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        }, () => {

            this.props.onFilterChange(this.state.form)
        });
    }

    getStatues() {
        return domain.appointment.statuses.map((item) => ({ code: item.id, label: I18n.get(item.label) }));
    }

    render() {

        const appointments = this.props.appointments || {};

        const items = appointments.items || [];
        const nextToken = appointments.nextToken;

        const filterStatus = (item) => {
            if (this.props.auth.is_practitioner) {
                return item.id >= AppointmentStatus.Assigned;
            }
            else {
                return true;
            }
        }

        return (<section>
            <div className="flex flex-col sm:flex-row">

                <div className="flex-none mr-8">

                    <nav className="flex flex-row border-b-2 py-2">
                        <span className="py-1 px-2 ml-1 block italic text-gray-600">status:</span>
                        {domain.appointment.statuses.filter(filterStatus).map((item, index) => {

                            const checked = this.state.form.status.find((s) => s === item.id);
                            const className = 'py-1 px-2 ml-1 mt-1 block text-sm rounded ' + (checked !== undefined ? 'bg-primary shadow text-smart-gray-lighter' : 'text-gray-600 hover:bg-smart-gray-lighter');

                            return (
                                <button key={index} onClick={(e) => this.onChangeFormArrayToggle('status', item.id)} className={className} style={{ fontSize: '0.7rem' }}>
                                    {I18n.get(item.label)}
                                </button>
                            );
                        })}
                    </nav>

                </div>
                <div className="flex-none py-3">

                    <SelectField {...this.state.fields.scheduledDate} value={this.state.form.scheduledDate} error={this.state.errors.scheduledDate} />

                </div>

            </div>
        </section>);

    }
}

export default AppointmentFilter;
