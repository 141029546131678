
export const getAppointment = `query GetAppointment($id: ID!) {
  getAppointment(id: $id) {
    id
    name
    alternateId
    dob
    phoneNumberCode
    phoneNumber
    gender
    specialities
    symptoms
    otherSymptom
    address1
    address2
    address3
    address4
    zipcode
    city
    state
    country
    comments
    status
    createdAt
    updatedAt
    requestedAt
    scheduledDate
    scheduledDateUtc
    timezoneId
    timezoneName
    lat
    lng
    tpa {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
    }
    provider {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      publicURL
      settings
    }
    practitioner {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      calendar
      isVIP
      specialities
      position
    }
    availabilities
    startedAt
    completedAt
  }
}
`