import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import DetailsItem from '../../components/ui/details-item';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';

import { DOB, PhoneNumber, Gender, Status, ScheduledDate, ScheduledTime, AssignedTo } from './_appointment';

const AppointmentCard = ({ item }) => {

    var getSymptom = (id) => {
        return domain.symptomMap[id] || {};
    }

    return (<div>

        <div className="mt-2 p-3 w-full rounded text-lg text-white text-center" style={{ backgroundColor: '#404E68' }}>{item.name}</div>

        <div className="mt-4">

            <DOB v={item.dob} />
            <PhoneNumber v={item.phoneNumber} />
            <Gender v={item.gender} />

        </div>

        <div className="mt-8">

            <ScheduledDate v={item.scheduledDate} timezoneId={item.timezoneId} />
            <ScheduledTime v={item.scheduledDate} timezoneId={item.timezoneId} timezoneName={item.timezoneName} />

            <AssignedTo practitioner={item.practitioner} className="mt-2" />

        </div>

        <div className="mt-6 border-b border-dashed"></div>

        <p className="mt-4 font-semibold text-lg leading-wider">{I18n.get('Symptoms')}</p>

        <div className="mt-4">
            {item.symptoms && item.symptoms.map((item, index) => {

                return (
                    <span className="tag" key={index}>
                        {getSymptom(item).label}
                    </span>
                );
            })}
        </div>

    </div>);
}

export default AppointmentCard;