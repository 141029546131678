/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAdminUsers = /* GraphQL */ `
  query ListAdminUsers($profile: String!, $providerId: String, $tpaId: String) {
    listAdminUsers(profile: $profile, providerId: $providerId, tpaId: $tpaId) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const getAdminUser = /* GraphQL */ `
  query GetAdminUser($id: ID!) {
    getAdminUser(id: $id) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $providerId: String
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      nextToken
    }
  }
`;
export const listAppointmentsByTpa = /* GraphQL */ `
  query ListAppointmentsByTpa(
    $tpaId: String!
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentsByTPA(
      tpaId: $tpaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      nextToken
    }
  }
`;
export const listAppointmentsByPractitioner = /* GraphQL */ `
  query ListAppointmentsByPractitioner(
    $practitionerId: String!
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentsByPractitioner(
      practitionerId: $practitionerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      nextToken
    }
  }
`;
export const getAppointmentRequest = /* GraphQL */ `
  query GetAppointmentRequest($id: ID!, $practitionerId: String!) {
    getAppointmentRequest(id: $id, practitionerId: $practitionerId) {
      id
      practitionerId
      accepted
      createdAt
      updatedAt
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
    }
  }
`;
export const listAppointmentRequestsByPractitioner = /* GraphQL */ `
  query ListAppointmentRequestsByPractitioner(
    $practitionerId: String!
    $filter: ModelAppointmentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentRequestsByPractitioner(
      practitionerId: $practitionerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        practitionerId
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($id: ID!) {
    getAuditLog(id: $id) {
      id
      username
      field
      type
      profile
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      arguments
      success
      error_message
      createdAt
      updatedAt
    }
  }
`;
export const listAuditLogs = /* GraphQL */ `
  query ListAuditLogs(
    $ts: String!
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogs(
      ts: $ts
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        field
        type
        profile
        arguments
        success
        error_message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditLogsByProvider = /* GraphQL */ `
  query ListAuditLogsByProvider(
    $providerId: String!
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogsByProvider(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        field
        type
        profile
        arguments
        success
        error_message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAvailability = /* GraphQL */ `
  query GetAvailability($ts: String!, $practitionerId: String!) {
    getAvailability(ts: $ts, practitionerId: $practitionerId) {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const listAvailabilities = /* GraphQL */ `
  query ListAvailabilities(
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ts
        slots
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAvailabilitiesByProvider = /* GraphQL */ `
  query ListAvailabilitiesByProvider(
    $providerId: String!
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilitiesByProvider(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ts
        slots
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmr = /* GraphQL */ `
  query GetEmr($id: ID!) {
    getEMR(id: $id) {
      id
      url
      allergies
      weight
      weightUnit
      height
      heightUnit
      bp {
        sbp
        dbp
      }
      bpUnit
      hr
      hrUnit
      rr
      rrUnit
      temp
      tempUnit
      history
      chronic
      activeMedication
      examination
      diagnoses {
        code
        treatmentPlan
      }
      referralHP
      ambulance
      labwork
      medicationPrescription
      medicationInHouse
      immunizations
      procsTestsScreenings
      followUp
      referralSpec
      createdAt
      updatedAt
    }
  }
`;
export const getMedicalProvider = /* GraphQL */ `
  query GetMedicalProvider($id: ID!) {
    getMedicalProvider(id: $id) {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const listMedicalProviders = /* GraphQL */ `
  query ListMedicalProviders(
    $filter: ModelMedicalProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications($limit: Int) {
    listNotifications(limit: $limit) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const getPractitioner = /* GraphQL */ `
  query GetPractitioner($id: ID!) {
    getPractitioner(id: $id) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const listPractitioners = /* GraphQL */ `
  query ListPractitioners($filter: ModelPractitionerFilterInput) {
    listPractitioners(filter: $filter) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const listPractitionersByProvider = /* GraphQL */ `
  query ListPractitionersByProvider(
    $providerId: String!
    $filter: ModelPractitionerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractitionersByProvider(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      nextToken
    }
  }
`;
export const getPractitionerGroup = /* GraphQL */ `
  query GetPractitionerGroup($id: ID!) {
    getPractitionerGroup(id: $id) {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const listPractitionerGroups = /* GraphQL */ `
  query ListPractitionerGroups(
    $filter: ModelPractitionerGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractitionerGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTpa = /* GraphQL */ `
  query GetTpa($id: ID!) {
    getTPA(id: $id) {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
export const listTpAs = /* GraphQL */ `
  query ListTpAs(
    $filter: ModelTPAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTPAs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      nextToken
    }
  }
`;
export const listTpAsByProvider = /* GraphQL */ `
  query ListTpAsByProvider(
    $providerId: String!
    $filter: ModelTPAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTPAsByProvider(
      providerId: $providerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      nextToken
    }
  }
`;
