import React from 'react';
import { connect } from 'react-redux';

import gql from 'graphql-tag';
import { compose } from 'react-apollo';

import * as subscriptions from '../graphql/subscriptions';

import { CORE_API } from '../actions/core/actions';

import NotificationContainer from './notification-container';


export default function ProviderContainer(Component) {

    const mapStateToProps = state => {

        return {
            auth: state.auth,
            core: state.core,
            task: state.task,
            errors: state.errors || {},
            is_ctx_provider: true
        };
    };

    const mapDispatchToProps = dispatch => {
        return {};
    };

    class ProviderContext extends React.Component {

        constructor(props) {
            super();
            this.state = {
            };
        }

        componentDidMount() {
        }

        render() {

            return (<Component {...this.props} />);
        }
    }

    return compose(
        CORE_API.list_countries,
        // CORE_API.list_notifications,
        CORE_API.get_provider,
        CORE_API.create_tpa,
        CORE_API.create_practitioner,
        CORE_API.create_practitioner_group,
        CORE_API.update_practitioner_group,
        CORE_API.update_provider,
        CORE_API.update_user,
        CORE_API.disable_user,
        CORE_API.enable_user,
        CORE_API.delete_user,
        NotificationContainer
    )(connect(mapStateToProps, mapDispatchToProps)(ProviderContext));
}