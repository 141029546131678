import React from 'react';
import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';

import TimeAgo from '../../components/time-ago';

const AuditLogItem = ({ item }) => {

    const args = item.arguments ? JSON.parse(item.arguments) : {};

    const text = Object.keys(args).map((item, index) => {

        if (typeof args[item] === "object" && args[item] !== null) {

            const nested = Object.keys(args[item]).map((sub, sub_index) => {
                return (<div key={sub_index} className="ml-4">  {sub}: <span className="text-gray-500">{JSON.stringify(args[item][sub], null, ' ')}</span></div>)
            });

            return (<div key={index}>{item}: {nested}</div>);
        }
        else {
            return (<div key={index}>{item}: <span className="text-gray-500">{JSON.stringify(args[item], null, ' ')}</span></div>)
        }
    });

    return (<div className="bg-white border-b border-grey-100 hover:bg-gray-100">
        <div className="p-2">

            <div className="flex justify-between flex-col sm:flex-row">

                <div className="flex-1 p-2">
                    <p className="text-sm">{item.username}</p>
                </div>

                <div className="flex-1 p-2">
                    <p className="text-sm">{item.profile}</p>
                </div>

                <div className="flex-1 p-2">
                    <p className="text-sm">{item.type} - {item.field}</p>
                </div>

                <div className="flex-1 p-2 text-xs text-gray-600">
                    {text}
                </div>

                <div className="flex-1 p-2">
                    <p className="text-sm">{!!item.success ? 'success' : 'failure'} {item.error_message && <span className="text-red-500">{item.error_message}</span>} </p>
                </div>

                <div className="flex-1 p-2">
                    <p className="text-sm"><DateTimeSpan value={item.createdAt} /> &middot; <TimeAgo v={item.createdAt} className="text-gray-500" /> </p>
                </div>

            </div>
        </div>
    </div >);
};

export default AuditLogItem;