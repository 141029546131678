import { AUTH_UPDATE } from './actions';

const api_call = () => {
    return (dispatch) => {

        dispatch({
            type: AUTH_UPDATE,
            payload: {
                task: {}
            }
        });
    }
}

export default api_call;