import React from 'react';
import { I18n } from 'aws-amplify';

const SelectField = ({ 
    label, 
    onChange, 
    name, 
    placeholder = '', 
    value, 
    disabled = false, 
    small = false, 
    values = [], 
    style = {}, 
    tooltip, 
    isOptional,
    error, 
    className = 'field mb-4', 
    selectClassName = 'select hover:border-gray-500  focus:outline-none focus:shadow-outline',
    chevronClassName = 'select-chevron'
}) => {

    const Tooltip = (props) => {

        if (!tooltip) return null;

        return (
            <span className="icon tooltip is-tooltip-multiline has-text-centered" data-tooltip={tooltip}>
                <i className="fas fa-info-circle"></i>
            </span>
        )
    }

    const errorClassName = 'border-red-500';

    return (
        <div className={className}>
            {label && <label className="label">{label}</label>}
            <div className="control inline-block relative">
                <select
                    name={name}
                    className={selectClassName + (error ? errorClassName : '')}
                    value={value || ''}
                    onChange={onChange}
                    disabled={disabled}
                >
                    <option value="" className="firstItem">{small ? '...' : I18n.get('Select')}</option>
                    {values.map((item, index) =>
                        <option key={index} value={item.id}>{item.label}</option>
                    )}
                </select>
                <div className={chevronClassName}>
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
                {error && <p className="mt-2 text-red-500 text-xs italic">{error}</p>}
            </div>
        </div>
    )
};

export default SelectField;