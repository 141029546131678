
import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listAdminUsers}`,
    {
        options: ({ profile, providerId, tpaId }) => ({
            variables: {
                profile,
                providerId,
                tpaId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { listAdminUsers = [], loading, fetchMore } }) => {

            return {
                users: listAdminUsers,
                loading: loading
            }
        }
    }
)
