import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import ICD from '../../domain/ICD.json';

import DetailsItem from '../../components/ui/details-item';

const EmrCard = ({ emr }) => {

    console.log({ emr: emr });

    const item = emr;

    const bp = item.bp && `${item.bp.sbp} / ${item.bp.dbp}`;

    return (<div>

        <div className="mt-4">

            <p className="mb-8 font-semibold text-lg leading-wider">{I18n.get('Vitals/Allergies')}</p>

            <DetailsItem label={I18n.get('Weight')} value={`${item.weight} ${item.weightUnit}`} />
            <DetailsItem label={I18n.get('Height')} value={`${item.height} ${item.heightUnit}`} />
            <DetailsItem label={I18n.get('Blood Pressure')} value={`${bp} ${item.bpUnit}`} />
            <DetailsItem label={I18n.get('Heart Rate')} value={`${item.hr} ${item.hrUnit}`} />
            <DetailsItem label={I18n.get('Resp. Rate')} value={`${item.rr} ${item.rrUnit}`} />
            <DetailsItem label={I18n.get('Temperature')} value={`${item.temp} ${item.tempUnit}`} />
            <br />

            <DetailsItem label={I18n.get('Allergies')} value={item.allergies} />
            <DetailsItem label={I18n.get('Medical History')} value={item.history} />
            <DetailsItem label={I18n.get('Chronic')} value={item.chronic} />
            <DetailsItem label={I18n.get('Active Medication')} value={item.activeMedication} />

            <p className="mt-12 mb-8 font-semibold text-lg leading-wider">{I18n.get('Diagnosis and Treatment Plan')}</p>

            <DetailsItem label={I18n.get('Medical Examination')} value={item.examination} />

            {item.diagnoses && item.diagnoses.map((d, index) =>

                (<div key={index}>
                    <DetailsItem label={I18n.get('Diagnosis')} value={d.code ? `${d.code} - ${ICD[d.code]}` : '-'} />
                    <DetailsItem label={I18n.get('Treatment Plan')} value={d.treatmentPlan} />
                </div>)
            )}


            <p className="mt-12 mb-8 font-semibold text-lg leading-wider">{I18n.get('Orders')}</p>

            <DetailsItem label={I18n.get('Referral to HP?')} value={item.referralHP} />
            <DetailsItem label={I18n.get('Ambulance transportion required?')} value={item.ambulance} />
            <DetailsItem label={I18n.get('Lab Work')} value={item.labwork} />
            <DetailsItem label={I18n.get('Medication (Prescription)')} value={item.medicationPrescription} />
            <DetailsItem label={I18n.get('Medication (In-house)')} value={item.medicationInHouse} />
            <DetailsItem label={I18n.get('Immunizations')} value={item.immunizations} />
            <DetailsItem label={I18n.get('Procedure/Tests/Screenings')} value={item.procsTestsScreenings} />
            <DetailsItem label={I18n.get('Follow-up appointment')} value={item.followUp} />
            <DetailsItem label={I18n.get('Referral to specialist')} value={item.referralSpec} />

        </div>

    </div>);
}

export default EmrCard;