import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { compose } from 'react-apollo';

import Task from '../../task';

import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';
import RadioField from '../../components/form/radio-field';

import { CloseButtonIcon } from '../../components/ui/icons';



class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                id: '',
                profile: {
                    label: I18n.get('Profile'),
                    onChange: this.onChangeForm.bind(this, 'profile'),
                },
                name: {
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                    maxLength: 200
                },
                email: {
                    label: I18n.get('Email'),
                    onChange: this.onChangeForm.bind(this, 'email'),
                },
                notification: {
                    name: 'notification',
                    label: I18n.get('Notifications'),
                    onChange: this.onChangeForm.bind(this, 'notification'),
                    values: [{ id: true, label: I18n.get('ENABLED') }, { id: false, label: I18n.get('DISABLED') }]
                },
            },
            form: {
                profile: 'MedicalProvider'
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        const provider = this.props.provider || {};

        var task = new Task();

        const data = {
            ...this.state.form,
            providerId: provider.id,
        };

        this.setState({ task: task });

        this.props.create_user(data)
            .then(({ user }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/users`);

                CORE_API.create_toast({ message: I18n.get(`Provider User  '@name' has been created. An email has been sent to the user with a temporary password`).replace('@name', user.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    getProfiles() {
        return [
            { id: "MedicalProvider", label: "Medical Provider" },
        ];
    }

    render() {

        const provider = this.props.provider;

        if (this.state.task.result) {

            return (<Redirect to={`/${provider.publicURL}/users`} />);
        }

        const profiles = this.getProfiles();

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Create Provider User')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/users`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('User Details')}</p>

                            <SelectField {...this.state.fields.profile} value={this.state.form.profile} error={this.state.errors.profile} values={profiles} />

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                            <RadioField {...this.state.fields.notification} value={this.state.form.notification} error={this.state.errors.notification} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>User successfully created. An email has been sent to the user with a temporary password.</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Create')} />

                        </div>

                    </div>

                </form>

            </div>

        </ProviderLayout>);
    }

}

export default compose(
    CORE_API.create_user
)(ProviderContainer(UsersPage));

