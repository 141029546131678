import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import MaskedTextField from '../../components/form/masked-text-field';
import SelectField from '../../components/form/select-field';
import RadioField from '../../components/form/radio-field';

import SelectSearchField from '../../components/form/select-search-field';

import domain from '../../domain';

import { TimeClearIcon, CheckIcon, CloseButtonIcon, CalendarRegularIcon } from '../../components/ui/icons';

import DetailsItem from '../../components/ui/details-item';

import { DOB, PhoneNumber, Gender, Status, ScheduledDate, ScheduledTime, AssignedTo } from '../shared/_appointment';

import { getCountryCodes, getSpeciality, getSymptom, normalizeAddress } from '../../utils';

import * as phoneNumberCodeUi from '../../components/ui/phone-number-code';

import 'moment-timezone';

import 'whatwg-fetch';

class PatientForm extends React.Component {

    constructor(props) {

        super();

        this.searchRef = React.createRef()

        this.state = {
            fields: {
                id: '',
                name: {
                    name: 'name',
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
                alternateId: {
                    name: 'alternateId',
                    label: I18n.get('Id'),
                    onChange: this.onChangeForm.bind(this, 'alternateId'),
                },
                dob: {
                    name: 'dob',
                    label: I18n.get('Date of birth'),
                    onChange: this.onChangeForm.bind(this, 'dob'),
                    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
                    format: "####-##-##",
                    placeholder: 'YYYY-MM-DD'
                },
                phoneNumberCode: {
                    name: 'phoneNumberCode',
                    placeholder: I18n.get('Country code'),
                    onChange: this.onChangeFormValue.bind(this, 'phoneNumberCode'),
                    components: {
                        ...phoneNumberCodeUi
                    }
                },
                phoneNumber: {
                    name: 'phoneNumber',
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    placeholder: I18n.get('5555555555'),
                    mask: '',
                    format: '###############',
                },
                gender: {
                    name: 'gender',
                    label: I18n.get('Gender'),
                    onChange: this.onChangeForm.bind(this, 'gender'),
                    values: [{ id: 'F', label: I18n.get('Female') }, { id: 'M', label: I18n.get('Male') }]
                },
                specialities: {
                    name: 'speciality',
                    label: I18n.get('Speciality'),
                    onChange: this.onChangeFormArrayToggle.bind(this, 'specialities'),
                    className: 'field mb-1'
                },
                symptoms: {
                    name: 'symptoms',
                    label: I18n.get('Symptoms'),
                    onChange: this.onChangeFormArrayToggle.bind(this, 'symptoms'),
                    className: 'field mb-1'
                },
            },
            form: props.form,
            errors: props.errors,
            appointment: {},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(this.props.errors) != JSON.stringify(this.state.errors)) {
            this.setState({ errors: this.props.errors });
        }
    }

    componentWillUnmount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        }), () => {
            this.props.onNext(this.state.form);
        });
    }

    onChangeFormValue(prop, value) {

        console.log({ prop: prop, value: value });

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        }), () => {
            this.props.onNext(this.state.form);
        });
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        }, () => {
            this.props.onNext(this.state.form);
        });
    }

    render() {

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        const item = this.props.form;

        const specialities = item.specialities && item.specialities.map((item) => getSpeciality(item).label).join(', ');

        const symptoms = item.symptoms && item.symptoms.map((item) => getSymptom(item).label).join(', ');

        // if (this.props.step != 1) {
        //     return (<div className="card h-full">

        //         <div className="body">

        //             <NavLink to={`/${provider.publicURL}/t/${tpa.id}/appointments/create?step=1`} className="float-right text-sm">
        //                 {I18n.get('Edit')}
        //             </NavLink>

        //             <p className="tracking-wider uppercase text-gray-500 mb-8">{I18n.get('Patient')}</p>

        //             <DetailsItem label={I18n.get('Full Name')} value={item.name} />
        //             <DOB v={item.dob} />
        //             <PhoneNumber v={item.phoneNumber} />
        //             <Gender v={item.gender} />
        //             <DetailsItem label={I18n.get('Speciality')} value={specialities} />
        //             <DetailsItem label={I18n.get('Symptoms')} value={symptoms} />

        //         </div>

        //     </div>)
        // }

        const genderValues = domain.genders.map((item) => ({ id: item.id, label: I18n.get(item.label) }));

        return (<form className="w-full" onSubmit={this.props.onNext.bind(this, this.state.form)}>

            <div className="card h-full">

                <div className="body">

                    <p className="tracking-wider uppercase text-gray-500 mb-12">{I18n.get('Patient')}</p>

                    <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                    <TextField {...this.state.fields.alternateId} value={this.state.form.alternateId} error={this.state.errors.alternateId} />

                    <MaskedTextField {...this.state.fields.dob} value={this.state.form.dob} error={this.state.errors.dob} />

                    <div className="field mb-4">
                        <label className="label">{I18n.get('Phone')}</label>

                        <div className="flex flex-grow flex-row">

                            <SelectSearchField {...this.state.fields.phoneNumberCode} value={this.state.form.phoneNumberCode} error={this.state.errors.phoneNumberCode} values={getCountryCodes()} className="flex-1 mb-0 mr-8" />

                            <MaskedTextField {...this.state.fields.phoneNumber} value={this.state.form.phoneNumber} error={this.state.errors.phoneNumber} className="flex-1 mb-0" />

                        </div>

                    </div>

                    <RadioField {...this.state.fields.gender} value={this.state.form.gender} error={this.state.errors.gender} values={genderValues} />

                    <SelectSearchField {...this.state.fields.specialities} error={this.state.errors.specialities} values={domain.specialities} />

                    <div className="field mb-4">
                        <div className="label"></div>
                        <div className="control">
                            {this.state.form.specialities && this.state.form.specialities.map((specialityId, index) => {

                                return (
                                    <span key={index} className="select-search-selected">
                                        {getSpeciality(specialityId).label}
                                        <button onClick={(e) => this.onChangeFormArrayToggle('specialities', specialityId, e)} className="float-right">
                                            <CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" />
                                        </button>
                                    </span>
                                );
                            })}

                        </div>
                    </div>

                    <SelectSearchField {...this.state.fields.symptoms} error={this.state.errors.symptoms} values={domain.symptoms} />

                    <div className="field mb-4">
                        <div className="label"></div>
                        <div className="control">
                            {this.state.form.symptoms && this.state.form.symptoms.map((symptomId, index) => {

                                return (
                                    <span key={index} className="select-search-selected">
                                        <button onClick={(e) => this.onChangeFormArrayToggle('symptoms', symptomId, e)} className="float-right">
                                            <CloseButtonIcon className="icon h-2 w-2 ml-2 text-gray-500" />
                                        </button>
                                        {getSymptom(symptomId).label}
                                    </span>
                                );
                            })}

                        </div>
                    </div>

                    {/* <div className="mt-8">

                        <TaskMessage {...this.props.task}></TaskMessage>

                        <ActionButton task={this.props.task} disabled={!this.state.form.name} label={I18n.get('Next')}></ActionButton>

                    </div> */}

                </div>

            </div>

        </form>);
    }

}
export default PatientForm;