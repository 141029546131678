import React from 'react';
import { connect } from 'react-redux';

import { CORE_API } from '../actions/core/actions';

import { compose } from 'react-apollo';

export default function MasterContainer(Component) {

    const mapStateToProps = state => {

        console.log(state);

        return Object.assign({}, { 
            auth: state.auth, 
            core: state.core,
            task: {}, 
            prepare_task: {}, 
            request_task: {}, 
            errors: {}, 
            is_ctx_master: true
        });
    };

    const mapDispatchToProps = dispatch => {
        return {};
    };    

    class MasterContext extends React.Component {

        constructor(props) {
            super();
            this.state = {
            };
        }

        componentDidMount() {
            console.log({ componentDidMount: MasterContext });
        }

        componentDidUpdate(prevProps, prevState, snapshot) {

        }

        componentWillUnmount() {
        }

        render() {

            return (<Component {...this.props} />);
        }
    }

    return compose(
        CORE_API.list_countries,
        CORE_API.list_notifications,
        CORE_API.list_providers,
        CORE_API.create_provider,
        CORE_API.create_user,
        CORE_API.disable_user,
        CORE_API.enable_user,
        CORE_API.delete_user
    )(connect(mapStateToProps, mapDispatchToProps)(MasterContext));

}