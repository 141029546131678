/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAdminUser = /* GraphQL */ `
  mutation CreateAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const updateAdminUser = /* GraphQL */ `
  mutation UpdateAdminUser($input: UpdateAdminUserInput!) {
    updateAdminUser(input: $input) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const disableAdminUser = /* GraphQL */ `
  mutation DisableAdminUser($input: UpdateAdminUserInput!) {
    disableAdminUser(input: $input) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const enableAdminUser = /* GraphQL */ `
  mutation EnableAdminUser($input: UpdateAdminUserInput!) {
    enableAdminUser(input: $input) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdminUser = /* GraphQL */ `
  mutation DeleteAdminUser($input: DeleteAdminUserInput!) {
    deleteAdminUser(input: $input) {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment($input: DeleteAppointmentInput!) {
    deleteAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const prepareAppointment = /* GraphQL */ `
  mutation PrepareAppointment($input: CreateAppointmentInput!) {
    prepareAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const requestAppointment = /* GraphQL */ `
  mutation RequestAppointment($input: UpdateAppointmentInput!) {
    requestAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const assignAppointment = /* GraphQL */ `
  mutation AssignAppointment($input: AssignAppointmentInput!) {
    assignAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const startAppointment = /* GraphQL */ `
  mutation StartAppointment($input: StartAppointmentInput!) {
    startAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const completeAppointment = /* GraphQL */ `
  mutation CompleteAppointment($input: CompleteAppointmentInput!) {
    completeAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const cancelAppointment = /* GraphQL */ `
  mutation CancelAppointment($input: CancelAppointmentInput!) {
    cancelAppointment(input: $input) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const updateAppointmentRequest = /* GraphQL */ `
  mutation UpdateAppointmentRequest($input: UpdateAppointmentRequestInput!) {
    updateAppointmentRequest(input: $input) {
      id
      practitionerId
      accepted
      createdAt
      updatedAt
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability($input: CreateAvailabilityInput!) {
    createAvailability(input: $input) {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability($input: UpdateAvailabilityInput!) {
    updateAvailability(input: $input) {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability($input: DeleteAvailabilityInput!) {
    deleteAvailability(input: $input) {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry($input: DeleteCountryInput!) {
    deleteCountry(input: $input) {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateEmr = /* GraphQL */ `
  mutation UpdateEmr($input: UpdateEMRInput!) {
    updateEMR(input: $input) {
      id
      url
      allergies
      weight
      weightUnit
      height
      heightUnit
      bp {
        sbp
        dbp
      }
      bpUnit
      hr
      hrUnit
      rr
      rrUnit
      temp
      tempUnit
      history
      chronic
      activeMedication
      examination
      diagnoses {
        code
        treatmentPlan
      }
      referralHP
      ambulance
      labwork
      medicationPrescription
      medicationInHouse
      immunizations
      procsTestsScreenings
      followUp
      referralSpec
      createdAt
      updatedAt
    }
  }
`;
export const createMedicalProvider = /* GraphQL */ `
  mutation CreateMedicalProvider($input: CreateMedicalProviderInput!) {
    createMedicalProvider(input: $input) {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const updateMedicalProvider = /* GraphQL */ `
  mutation UpdateMedicalProvider($input: UpdateMedicalProviderInput!) {
    updateMedicalProvider(input: $input) {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const deleteMedicalProvider = /* GraphQL */ `
  mutation DeleteMedicalProvider($input: DeleteMedicalProviderInput!) {
    deleteMedicalProvider(input: $input) {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const clearAllNotifications = /* GraphQL */ `
  mutation ClearAllNotifications($username: String) {
    clearAllNotifications(username: $username) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const createPractitioner = /* GraphQL */ `
  mutation CreatePractitioner($input: CreatePractitionerInput!) {
    createPractitioner(input: $input) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const updatePractitioner = /* GraphQL */ `
  mutation UpdatePractitioner($input: UpdatePractitionerInput!) {
    updatePractitioner(input: $input) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const disablePractitioner = /* GraphQL */ `
  mutation DisablePractitioner($input: UpdatePractitionerInput!) {
    disablePractitioner(input: $input) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const enablePractitioner = /* GraphQL */ `
  mutation EnablePractitioner($input: UpdatePractitionerInput!) {
    enablePractitioner(input: $input) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const deletePractitioner = /* GraphQL */ `
  mutation DeletePractitioner($input: DeletePractitionerInput!) {
    deletePractitioner(input: $input) {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const createPractitionerGroup = /* GraphQL */ `
  mutation CreatePractitionerGroup($input: CreatePractitionerGroupInput!) {
    createPractitionerGroup(input: $input) {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const updatePractitionerGroup = /* GraphQL */ `
  mutation UpdatePractitionerGroup($input: UpdatePractitionerGroupInput!) {
    updatePractitionerGroup(input: $input) {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const deletePractitionerGroup = /* GraphQL */ `
  mutation DeletePractitionerGroup($input: DeletePractitionerGroupInput!) {
    deletePractitionerGroup(input: $input) {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const createTpa = /* GraphQL */ `
  mutation CreateTpa($input: CreateTPAInput!) {
    createTPA(input: $input) {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
export const updateTpa = /* GraphQL */ `
  mutation UpdateTpa($input: UpdateTPAInput!) {
    updateTPA(input: $input) {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
export const deleteTpa = /* GraphQL */ `
  mutation DeleteTpa($input: DeleteTPAInput!) {
    deleteTPA(input: $input) {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
