import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import PractitionerContainer from '../../containers/practitioner-container';
import PractitionerLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DetailsItem from '../../components/ui/details-item';

import { LocationIcon } from '../../components/ui/icons';

import domain from '../../domain';

class PractitionerDetails extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {

        const practitioner = this.props.practitioner || {};
        const provider = practitioner.provider || {};

        return (
            <PractitionerLayout {...this.props}>

                <div className="page-header">

                    <div className="title">
                        <LocationIcon className="icon md mr-3 opacity-50" />
                        {I18n.get('Location & Availability')}
                    </div>

                    <div className="bar">

                        <NavLink to={`/${provider.publicURL}/p/${practitioner.id}/location/edit`} className="btn-primary">
                            {I18n.get('Edit')}
                        </NavLink>

                    </div>

                </div>
                <ToastContainer toasts={this.props.core.toasts} />

                <div className="page-body">

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-8">{I18n.get('Location')}</p>

                            <DetailsItem label={I18n.get('Street Address')} value={practitioner.address1} />
                            <DetailsItem label={I18n.get('Zip Code')} value={practitioner.zipcode} />
                            <DetailsItem label={I18n.get('City')} value={practitioner.city} />
                            <DetailsItem label={I18n.get('State')} value={practitioner.state} />
                            <DetailsItem label={I18n.get('Country')} value={practitioner.countryId} />
                            <DetailsItem label={I18n.get('Radius')} value={`${practitioner.radius} ${practitioner.radiusUnit}`} />
                            <DetailsItem label={I18n.get('Timezone')} value={practitioner.timezoneName} />

                            <div className="mt-8 border-b border-dashed"></div>

                            <p className="subtitle mt-12 mb-8">{I18n.get('Availability')}</p>

                            {domain.weekAvailability.map((domainDay) => {

                                const day = (practitioner.availability && practitioner.availability[domainDay.id]) || [];

                                const value = day.length == 0 ?  I18n.get('Unavailable') : day.map((hour, index) => {
                                    return (<div key={index}>{hour.start} - {hour.end}</div>);
                                });

                                return (<DetailsItem key={domainDay.id} label={I18n.get(domainDay.label)} value={value} />);
                            })}

                        </div>

                    </div>

                </div>
            </PractitionerLayout>
        );
    }

}

export default PractitionerContainer(PractitionerDetails);