import React from 'react';
import moment from 'moment';


const Span = ({value, timezoneId, timezoneName, className = '' }) => {

    if (!value) return (<span className={className}>-</span>);

    //console.log({v: value, timezoneId: timezoneId});

    const local = moment.tz(value, timezoneId);

    return (<span className={className}>{local.format('HH:mm ')} {timezoneName && <> &middot; {timezoneName.split(' ').map((word) => word.substring(0, 1))} </>} </span>);
}

export default Span;