import React from 'react';
import moment from 'moment';


const Span = ({value, timezoneId, className = ''}) => {

    const local = moment.tz(value, timezoneId);

    return (<span className={className}>{local.format('DD/MM/YYYY ')}</span>);
}

export default Span;