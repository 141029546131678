import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';

import Badge from '../../components/ui/badge';

import TimeAgo from '../../components/time-ago';

function getSpeciality(id) {
    return domain.specialityMap[id] || { label: '-' };
}

const PractitionerItem = ({ item }) => {

    return (<div className="practitioner-item bg-white border-b border-grey-100 hover:bg-gray-100">
        <div className="px-4 py-4">

            <div className="flex justify-between">

                <div className="flex-1">
                    <p className="text-xl">{item.name}</p>
                    <p className="text-sm text-gray-600">
                        {item.isVIP ? <Badge label={I18n.get('VIP')} /> : 'Non-VIP'} {getSpeciality(item.speciality).label}
                    </p>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg">{item.city}, {item.state}</p>
                    <p className="text-sm text-gray-600">{item.radius} {item.radiusUnit || 'km'}</p>
                </div>


                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.createdAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.createdAt} /></p>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.updatedAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.updatedAt} /></p>
                </div>

            </div>
        </div>
    </div >);
};

export default PractitionerItem;