import { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';

const api_call = ({ email }) => {
    return async (dispatch, getState) => {

        return Auth.forgotPassword(email)
            .then((data) => {

                const payload = {
                    password_reset_required: true,
                    task: {
                        result: true
                    },
                    errors: {}
                }

                dispatch({
                    type: 'AUTH_FORGOT_PASSWORD',
                    payload: payload
                });

                return payload;
            })
            .catch(err => {

                // console.log({err: err});

                const payload = {
                    task: {
                        result: false,
                        error: {
                            message: I18n.get(err.code || err.message)
                        }
                    },
                    errors: {}
                }

                dispatch({
                    type: 'AUTH_FORGOT_PASSWORD',
                    payload: payload
                });

                return payload;

            });
    }
}

export default api_call;