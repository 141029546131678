import React from 'react';
import { I18n } from 'aws-amplify';

import EmrCard from './emr-card';

import { AppointmentStatus } from '../../model';

import domain from '../../domain';

import { CloseButtonIcon } from '../../components/ui/icons';

import DateSpan from '../../components/date-span';
import TimeSpan from '../../components/time-span';

import { DOB, PhoneNumber, Gender, Status, ScheduledDate, ScheduledTime, AssignedTo } from './_appointment';

import { getSpeciality, getSymptom } from '../../utils';

import DetailsItem from '../../components/ui/details-item';


class EmrDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {

        const { item = {}, emr = {} } = this.props;

        const status = domain.appointment.statusMap[item.status] || {};

        console.log({ specialities: item.specialities });

        const visitType = item.specialities && item.specialities.map((item) => getSpeciality(item).label).join(', ');

        const symptoms = item.symptoms && item.symptoms.map((item) => getSymptom(item).label).join(', ');

        const scheduledDate = (<div><DateSpan value={item.scheduledDate} timezoneId={item.timezoneId} /> <TimeSpan value={item.scheduledDate} timezoneId={item.timezoneId} /> {item.timezoneName && item.timezoneName.split(' ').map((word) => word.substring(0, 1))} </div>);

        return (<div>
            <div className="bg-white flex justify-end items-center border-b">
                <div className="flex-1 p-4">
                    <span className="font-semibold mr-2">{I18n.get('EMR')}</span>
                </div>
                <div className="flex-1 text-right pr-4">
                    <a onClick={(e) => this.props.select({}, e)} className="card-header-icon">
                        <CloseButtonIcon className="icon w-3 h-3" />
                    </a>
                </div>
            </div>
            <div className="p-4">

                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 p-8">

                        <p className="mb-8 font-semibold text-lg leading-wider">{I18n.get('Appointment')}</p>

                        <DetailsItem label={I18n.get('Patient')} value={item.name} />
                        <DOB v={item.dob} />
                        <PhoneNumber v={item.phoneNumber} />
                        <Gender v={item.gender} />

                        

                        <DetailsItem label={I18n.get('Date')} value={scheduledDate} />
                        <DetailsItem label={I18n.get('Visit Type')} value={visitType} />

                        <DetailsItem label={I18n.get('Address')} value={<p>{item.address1}, {item.city}, {item.state} {item.zipcode}<br />{item.address2}</p>} />

                        <DetailsItem label={I18n.get('Practitioner')} value={item.practitioner && item.practitioner.name} />

                        <DetailsItem label={I18n.get('Chief Complaint')} value={symptoms} />

                        <DetailsItem label={I18n.get('External Id')} value={item.alternateId} />
                        <DetailsItem label={I18n.get('Status')} value={I18n.get(status.label)} />

                        <EmrCard emr={emr} />

                        <a href={emr.url} target="_blank" className="mt-12 btn-secondary">
                            Download
                        </a>

                    </div>
                </div>

            </div>
        </div >);
    }
}

export default EmrDetails;