import React from 'react';
import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';

import TimeAgo from '../../components/time-ago';

const TPAItem = ({ item }) => {

    return (<div data-id={item.id} data-name={item.name} className="bg-white border-b border-grey-100 hover:bg-gray-100">
        <div className="px-4 py-4">

            <div className="flex justify-between">

                <div className="flex-1">
                    <p className="text-xl">{item.name}</p>
                    <p className="text-sm text-gray-600"></p>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg">{item.contactName}</p>
                    <p className="text-sm text-gray-600">{item.contactEmail}</p>
                </div>


                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.createdAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.createdAt} /></p>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg"><DateTimeSpan value={item.updatedAt} /></p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.updatedAt} /></p>
                </div>

            </div>
        </div>
    </div >);
};

export default TPAItem;