import React from 'react';
import moment from 'moment';


const Span = ({value, timezoneId}) => {

    const ts = moment.tz(value, timezoneId);

    console.log({ts: ts});

    return (<span>{ts.format('dddd, MMMM Do, YYYY')}</span>);
}

export default Span;