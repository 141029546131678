import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import ProviderContainer from '../../containers/provider-container';

import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';


import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import Address1Field from '../../components/form/address1-field';
import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';
import FileField from '../../components/form/file-field';
import MaskedTextField from '../../components/form/masked-text-field';
import SelectSearchField from '../../components/form/select-search-field';

import { CloseButtonIcon } from '../../components/ui/icons';
import * as phoneNumberCodeUi from '../../components/ui/phone-number-code';


import domain from '../../domain';
import { getCountryCodes, normalizeAddress } from '../../utils';

class EditPage extends React.Component {

    constructor(props) {

        super();

        this.searchRef = React.createRef()

        const provider = props.provider || {};

        this.state = {
            fields: {
                id: '',
                name: {
                    name: 'name',
                    label: I18n.get('Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                    maxLength: 100
                },
                publicURL: {
                    name: 'publicURL',
                    label: I18n.get('Public URL'),
                    onChange: this.onChangeForm.bind(this, 'publicURL'),
                    placeholder: '/providerabc',
                    maxLength: 50
                },
                medicalProviderCountryId: {
                    name: 'medicalProviderCountryId',
                    label: I18n.get('Country'),
                    onChange: this.onChangeForm.bind(this, 'medicalProviderCountryId'),
                },
                website: {
                    name: 'website',
                    label: I18n.get('Website'),
                    onChange: this.onChangeForm.bind(this, 'website'),
                    maxLength: 100,
                    isOptional: true
                },
                logo: {
                    label: I18n.get('Image'),
                    onChange: this.onChangeFile.bind(this, 'logo'),
                },
                status: {
                    name: 'status',
                    label: I18n.get('Status'),
                    onChange: this.onChangeForm.bind(this, 'status'),
                },
                contactName: {
                    name: 'contactName',
                    label: I18n.get('Contact Name'),
                    onChange: this.onChangeForm.bind(this, 'contactName'),
                    maxLength: 100
                },
                contactEmail: {
                    name: 'contactEmail',
                    label: I18n.get('Contact Email'),
                    onChange: this.onChangeForm.bind(this, 'contactEmail'),
                    maxLength: 200
                },
                contactPhoneNumberCode: {
                    name: 'contactPhoneNumberCode',
                    placeholder: I18n.get('Country code'),
                    onChange: this.onChangeFormValue.bind(this, 'contactPhoneNumberCode'),
                    components: {
                        ...phoneNumberCodeUi
                    }
                },
                contactPhoneNumber: {
                    name: 'contactPhoneNumber',
                    onChange: this.onChangeForm.bind(this, 'contactPhoneNumber'),
                    mask: '',
                    format: '###############',
                },
                taxId: {
                    name: 'taxId',
                    label: I18n.get('Tax Id'),
                    onChange: this.onChangeForm.bind(this, 'taxId'),
                    maxLength: 100
                },
                preferredDueDate: {
                    name: 'preferredDueDate',
                    label: I18n.get('Preferred Due Date'),
                    onChange: this.onChangeForm.bind(this, 'preferredDueDate'),
                    isOptional: true
                },
                address1: {
                    name: 'address1',
                    label: I18n.get('Street Address'),
                    onChange: this.onChangeForm.bind(this, 'address1'),
                    maxLength: 100
                },
                address2: {
                    name: 'address2',
                    label: I18n.get('Unit'),
                    placeholder: 'Apt, Suite, Bldg, Gate Code.',
                    onChange: this.onChangeForm.bind(this, 'address2'),
                    maxLength: 100,
                    isOptional: true
                },
                zipcode: {
                    name: 'zipcode',
                    label: I18n.get('Zip Code'),
                    onChange: this.onChangeForm.bind(this, 'zipcode'),
                    maxLength: 50
                },
                city: {
                    name: 'city',
                    label: I18n.get('City'),
                    onChange: this.onChangeForm.bind(this, 'city'),
                    maxLength: 100,
                    readOnly: true
                },
                state: {
                    name: 'state',
                    label: I18n.get('State'),
                    onChange: this.onChangeForm.bind(this, 'state'),
                    readOnly: true
                },
                /* Billing address country */
                countryId: {
                    name: 'countryId',
                    label: I18n.get('Country'),
                    onChange: this.onChangeForm.bind(this, 'countryId'),
                    maxLength: 2,
                    readOnly: true
                },
            },
            form: {
            },
            errors: {
            },
            task: {
            }
        };

        Object.keys(this.state.fields).forEach((item) => {
            this.state.form[item] = provider[item];
        });

        this.state.form['medicalProviderCountryId'] = provider.country && provider.country.id;
    }

    componentDidMount() {
        const options = {
            types: ['address']
        };

        const geoAutocomplete = new window.google.maps.places.Autocomplete(this.searchRef.current, options);
        geoAutocomplete.addListener('place_changed', () => {

            const place = geoAutocomplete.getPlace();

            console.log({ place: place });

            var { address1, city, state, zipcode, countryId } = normalizeAddress(place);

            this.setState(prevState => {

                var nextState = Object.assign({}, prevState);

                nextState.form = { ...prevState.form, address1, city, state, zipcode, countryId };
                nextState.formatted_address = place.formatted_address;

                return nextState;
            });
        });

    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.provider) != JSON.stringify(this.props.provider)) {

            const provider = this.props.provider || {};

            var nextState = Object.assign({}, prevState);

            Object.keys(this.state.fields).forEach((item) => {
                nextState.form[item] = provider[item];
            });

            nextState.form['medicalProviderCountryId'] = provider.country && provider.country.id;

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    onChangeFile(prop, e) {

        const file = e.target.files[0];

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            this.setState(prevState => ({ form: { ...prevState.form, [prop]: e.target.result } }));
        };
        reader.readAsDataURL(file);
    }

    onSubmitForm(e) {
        e.preventDefault();

        const data = {
            ...this.state.form
        }

        var task = new Task();

        this.setState({ task: task });

        this.props.update_provider(data)
            .then(({ provider }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/details`);

                CORE_API.create_toast({ message: I18n.get(`Provider '@provider' has been updated.`).replace('@provider', provider.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }
    getCountries() {
        return this.props.countries.items && this.props.countries.items.map((item) => ({ id: item.id, label: item.name }));
    }

    getPreferredDueDates() {
        return domain.medicalProvider.preferredDueDates;
    }


    render() {

        const provider = this.props.provider;

        const statusValues = domain.medicalProvider.statuses.map((item) => ({ id: item.id, label: I18n.get(item.label) }));

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Edit Provider')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/details`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-8">{I18n.get('Provider Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField
                                {...this.state.fields.publicURL}
                                value={this.state.form.publicURL}
                                error={this.state.errors.publicURL}
                                readOnly={!this.props.auth.is_master}
                                style={{ width: '50%' }} />

                            <TextField
                                {...this.state.fields.website}
                                value={this.state.form.website}
                                error={this.state.errors.website} />

                            <SelectField
                                {...this.state.fields.medicalProviderCountryId}
                                value={this.state.form.medicalProviderCountryId}
                                error={this.state.errors.medicalProviderCountryId}
                                values={this.getCountries()} />

                            <FileField
                                {...this.state.fields.logo}
                                value={this.state.form.logo} />

                            <SelectField
                                {...this.state.fields.status}
                                value={this.state.form.status}
                                error={this.state.errors.status}
                                values={statusValues}
                                disabled={!this.props.auth.is_master} />


                            <p className="subtitle mt-12 mb-8">{I18n.get('Billing Information')}</p>

                            <TextField
                                {...this.state.fields.contactName}
                                value={this.state.form.contactName}
                                error={this.state.errors.contactName} />

                            <TextField
                                {...this.state.fields.contactEmail}
                                value={this.state.form.contactEmail}
                                error={this.state.errors.contactEmail} />

                            <div className="field mb-4">
                                <label className="label">{I18n.get('Contact Phone')}</label>

                                <div className="flex flex-grow flex-row">

                                    <SelectSearchField
                                        {...this.state.fields.contactPhoneNumberCode}
                                        value={this.state.form.contactPhoneNumberCode}
                                        error={this.state.errors.contactPhoneNumberCode}
                                        values={getCountryCodes()} 
                                        className="flex-1 mb-0 mr-8" />

                                    <MaskedTextField
                                        {...this.state.fields.contactPhoneNumber}
                                        value={this.state.form.contactPhoneNumber}
                                        error={this.state.errors.contactPhoneNumber}
                                        className="flex-1 mb-0" />

                                </div>

                            </div>

                            <TextField
                                {...this.state.fields.taxId}
                                value={this.state.form.taxId}
                                error={this.state.errors.taxId} />

                            <SelectField
                                {...this.state.fields.preferredDueDate}
                                value={this.state.form.preferredDueDate}
                                error={this.state.errors.preferredDueDate}
                                values={this.getPreferredDueDates()} 
                                disabled={!this.props.auth.is_master}
                                />

                            <p className="subtitle mt-12 mb-8">{I18n.get('Billing Address')}</p>

                            {/* <div className="field mb-12">

                                <div className="control">

                                    <input type="search" ref={this.searchRef} className="input-search px-5 py-3 focus:outline-none focus:shadow-outline" placeholder={I18n.get('Type an address')} />

                                </div>
                            </div> */}


                            <Address1Field
                                {...this.state.fields.address1}
                                createRef={this.searchRef}
                                value={this.state.form.address1}
                                error={this.state.errors.address1} />

                            <TextField
                                {...this.state.fields.address2}
                                value={this.state.form.address2}
                                error={this.state.errors.address2} />

                            <TextField
                                {...this.state.fields.zipcode}
                                value={this.state.form.zipcode}
                                error={this.state.errors.zipcode}
                                style={{ width: '50%' }} />

                            <TextField
                                {...this.state.fields.city}
                                value={this.state.form.city}
                                error={this.state.errors.city} />

                            <TextField
                                {...this.state.fields.state}
                                value={this.state.form.state}
                                error={this.state.errors.state} />

                            <TextField
                                {...this.state.fields.countryId}
                                value={this.state.form.countryId}
                                error={this.state.errors.countryId} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />


                        </div>
                    </div>

                </form>

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(EditPage);

