import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';
import Task from '../../task';


import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import domain from '../../domain';

import Calendar from '../shared/_calendar';
import Modal from '../../components/modal';

import TimeSlotSelector from '../../components/time-slot-selector';

import TimeSpan from '../../components/time-span';
import FullDateSpan from '../../components/full-date-span';

import { TimeClearIcon, CheckIcon, CloseButtonIcon, CalendarRegularIcon } from '../../components/ui/icons';

import Spinner from '../../components/ui/spinner';
import TimeAgo from '../../components/time-ago';

import moment from 'moment';
import 'moment-timezone';

import 'whatwg-fetch';

class AvailabilityForm extends React.Component {

    constructor(props) {

        super();

        const { referenceDate, scheduledDate, timezoneId, timezoneName } = props.form;


        this.searchRef = React.createRef()

        this.state = {
            fields: {
                id: '',
            },
            ts: 0,
            form: {
                referenceDate,
                scheduledDate,
                timezoneId,
                timezoneName
            },
            errors: props.errors,
            appointment: {},
            prepare_task: {},
            request_task: {},
            openDatePicker: false
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(this.props.errors) != JSON.stringify(this.state.errors)) {
            this.setState({ errors: this.props.errors });
        }

    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        console.log({ prop: prop, value: value });

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onSelectTS(ts, e) {
        this.setState({ ts: ts });
    }

    selectDate(date) {

        this.setState(prevState => {

            let nextState = Object.assign({}, prevState);

            nextState.form.referenceDate = date;
            nextState.openDatePicker = false;

            return nextState;

        }, () => {

            this.props.onPrev(this.state.form);
        });

        //this.setState({ openDatePicker: false });
    }

    onChangeScheduledDate(slot, timezoneId, timezoneName) {

        console.log({ slot: slot, timezoneId: timezoneId });

        this.setState(prevState => {

            let nextState = Object.assign({}, prevState);

            nextState.form.scheduledDate = slot;
            nextState.form.timezoneId = timezoneId;
            nextState.form.timezoneName = timezoneName;

            nextState.errors.scheduledDate = undefined;
            nextState.errors.timezoneId = undefined;
            nextState.errors.timezoneName = undefined;

            return nextState;
        });
    }



    getStates() {

        //TODO: Fix

        return domain['US'].states;
    }

    getGenders() {
        return domain.genders;
    }

    getSymptoms() {
        return domain.symptoms;
    }

    render() {

        if (this.props.step != 3) return null;

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};
        const appointment = this.props.appointment || {};

        if (appointment.status == 1) {

            const message = I18n.get(`Appointment for '@appointment' has been created.`).replace('@appointment', appointment.name);

            CORE_API.create_toast({ message: message });

            return <Redirect to={`/${provider.publicURL}/t/${tpa.id}/dashboard`} />
        }

        return (<form onSubmit={this.props.onNext.bind(this, this.state.form)}>


            <div className="card h-full">

                <div className="body">

                    <div className="float-right bg-smart-purple">
                        {this.props.prepare_task && this.props.prepare_task.busy && <Spinner />}
                        {this.props.prepare_task && !this.props.prepare_task.busy && <div>

                            <span className="text-sm text-gray-600"><TimeAgo v={appointment.updatedAt} /></span> &middot; <button type="button" onClick={this.props.onPrev.bind(this, this.state.form)} className="btn-tertiary w-auto">{I18n.get('Update')}</button>

                        </div>}

                    </div>

                    <p className="tracking-wider uppercase text-gray-500 mb-12">{I18n.get('Availability')}</p>

                    <div className="flex">

                        {appointment.availabilities && Object.keys(appointment.availabilities).map((ts, ts_index) => {

                            const timezoneId = appointment.availabilities[ts].timeZoneId;

                            return (<TimeSlotSelector value={ts} timezoneId={timezoneId} isSelected={ts_index == this.state.ts} onClick={this.onSelectTS.bind(this, ts_index)} />);
                        })}

                        <div className="flex-none ml-4 text-center">

                            <div className="uppercase text-center text-gray-500 p-2">{I18n.get('Calendar')}</div>

                            <span className="inline-block text-xs rounded-full text-center w-16 h-16 p-6 uppercase cursor-pointer bg-smart-blue-light text-secondary" style={{ minWidth: '50px', minHeight: '50px' }} onClick={(e) => this.setState({ openDatePicker: true })} >
                                <CalendarRegularIcon className="icon w-4 h-4" />
                            </span>

                        </div>

                    </div>

                    {appointment.availabilities && Object.keys(appointment.availabilities).map((ts, ts_index) => {

                        if (ts_index != this.state.ts) return null;

                        const timetable = appointment.availabilities[ts].timetable;
                        const timezoneId = appointment.availabilities[ts].timeZoneId;
                        const timezoneName = appointment.availabilities[ts].timeZoneName;

                        const hours = timetable && Object.keys(timetable).sort();

                        let total = 0;

                        return (<div key={ts} className="mt-8">

                            <p className="my-4 text-gray-600">
                                <TimeClearIcon /> &nbsp; &nbsp; {timezoneName}
                            </p>

                            {hours && hours.map((hour, index) => {

                                const hour_label = moment.tz(hour, timezoneId);

                                return (<div key={index}>
                                    <div className="text-gray-500">
                                        {hour_label.format('HH')}h &nbsp;

                                            {timetable[hour] && Object.keys(timetable[hour]).map((slot, index) => {

                                            //console.log(slot);

                                            const checked = this.state.form.scheduledDate == slot;
                                            const available = timetable[hour][slot];

                                            total += available ? 1 : 0; // incremets total

                                            const local = moment.tz(slot, timezoneId);
                                            const tooltip = local.format('dddd, MMMM Do, YYYY   HH:mm Z');
                                            const label = local.format('HH:mm')

                                            const className = 'btn-toggle m-1 ' + (available ? (checked ? ' bg-smart-blue text-white' : ' border border-smart-blue text-smart-blue hover:bg-smart-blue hover:text-white') : ' text-gray-300 border-gray-300');

                                            return (<button key={slot} type="button" key={index} onClick={(e) => this.onChangeScheduledDate(slot, timezoneId, timezoneName)} className={className} disabled={!available} style={{ width: '4rem', padding: '0.25rem' }} title={tooltip}>
                                                {label}
                                            </button>);

                                        })}
                                    </div>
                                </div>)
                            })}

                            {!total && <p className="has-text-grey" style={{ marginTop: '1rem' }}>No availability.</p>}

                        </div>);

                    })}

                    {this.state.form.scheduledDate && this.state.form.timezoneId && <div className="mt-4 p-2 border bg-primary text-primary text-center bg-smart-gray-lighter rounded flex">
                        <div className="flex-none ml-2">
                            <CheckIcon />
                        </div>
                        <div className="flex-grow">
                            <FullDateSpan value={this.state.form.scheduledDate} timezoneId={this.state.form.timezoneId} /> &middot; <TimeSpan value={this.state.form.scheduledDate} timezoneId={this.state.form.timezoneId} />
                            {this.state.form.timezoneName.split(' ').map((word) => word.substring(0, 1))}
                        </div>
                    </div>}

                    {/* <p className="mt-4 text-xl">
                                                {this.state.form.scheduledDate && <span>{I18n.get(' ')}<FullDateSpan value={this.state.form.scheduledDate} timezoneId={this.state.form.timezoneId} /> @ <TimeSpan value={this.state.form.scheduledDate} timezoneId={this.state.form.timezoneId} />{I18n.get(' ')}</span>}
                                            </p> */}


                    <br />

                    <TaskMessage {...this.props.request_task}>Appointment has been requested.</TaskMessage>

                    <ul className="ml-8 mb-4 list-disc">

                        {this.state.errors && Object.keys(this.state.errors).map((item) => {

                            const error = this.state.errors[item];

                            if (!error) return null;

                            return (<li className="text-red-500 text-sm italic">{error} ({item})</li>);
                        })}

                    </ul>

                    <ActionButton task={this.props.request_task} disabled={!this.state.form.scheduledDate} label={I18n.get('Request appointment')} />

                    <Modal isOpen={this.state.openDatePicker}>

                        <div className="bg-white flex justify-end items-center border-b">
                            <div className="flex-1 p-4">
                                <span className="font-semibold mr-2">{I18n.get('Pick a date')}</span>
                            </div>
                            <div className="flex-1 text-right pr-4">
                                <a onClick={(e) => this.setState({ openDatePicker: false })} className="card-header-icon" aria-label={I18n.get('more options')}>
                                    <CloseButtonIcon className="icon w-3 h-3" />
                                </a>
                            </div>
                        </div>

                        <div className="p-4">

                            <Calendar selectDate={this.selectDate.bind(this)} />

                        </div>

                    </Modal>

                </div>

            </div>

        </form>



        );
    }

}
export default AvailabilityForm;