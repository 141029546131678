import React from 'react';

import { CORE_API } from '../../actions/core/actions';

import { CloseButtonIcon } from '../../components/ui/icons';

const onDelete = (item) => {

    console.log(item);

    CORE_API.delete_toast(item);
}

const ToastContainer = ({ toasts }) => {

    if (!toasts || !toasts.length) return null;

    return (<div className="toast-container">
        {toasts.map((item, index) => {

            let className = `toast-${item.type || 'success'}`;

            return (<div key={index} className={className}>
                <button onClick={(e) => onDelete(item)} className="float-left">
                    <CloseButtonIcon className="fill-current icon h-3 w-3" />
                </button>
                <div className="text-center">
                    {item.message}
                </div>
            </div>);
        })}
    </div>);

}

export default ToastContainer;