import { Auth } from 'aws-amplify';

import { LOGOUT } from './actions';

export const api_logout = () => {

    return (dispatch, getState) => {

        dispatch({
            type: LOGOUT,
            payload: {
                logged_user: null,
                email: null,
                task: {
                    result: true
                }
            }
        });

        Auth.signOut();
    }
}

export default api_logout;