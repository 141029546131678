import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import ReportItem from '../shared/_report-item';

import Task from '../../task';

import { CloseButtonIcon } from '../../components/ui/icons';

import Modal from '../../components/modal';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';
import DateRangeField from '../../components/form/date-range-field';

class AuditLogFilter extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
            fields: {
                providerId: {
                    onChange: this.onChangeForm.bind(this, 'providerId'),
                    className: 'field'
                },                
                username: {
                    onChange: this.onChangeForm.bind(this, 'username'),
                    className: 'field'
                },
                profile: {
                    onChange: this.onChangeForm.bind(this, 'profile'),
                    className: 'field'
                },
                type: {
                    onChange: this.onChangeForm.bind(this, 'type'),
                    className: 'field'
                },
                field: {
                    onChange: this.onChangeForm.bind(this, 'field'),
                    className: 'field'
                },
                updatedAt: {
                    onChange: this.onChangeFormValue.bind(this, 'updatedAt'),
                    className: 'field',
                    value: []
                }                
            },
            form: {
                filters: [
                ]
            },
            errors: {},
        }
    }

    componentDidMount() {

    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }  

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onFilterClick(e) {

        const fields = this.getFields();

        const errors = {};
        const data = {};

        this.state.form.filters.map((item) => {

            const value = this.state.form[item];

            errors[item] = value ? null : '*';

            const field = fields.find((f) => f.id === item);

            data[item] = { [field.exp]: value };
        })

        this.setState({ errors: errors });

        this.props.onFilterChange(data);
    }

    onClearAllClick(e) {

        this.setState({ form: { filters: [] }, errors: {} });

        this.props.onFilterChange({});
    }

    getFields() {

        const providers = this.props.providers && this.props.providers.items && this.props.providers.items.map((item) => ({ id: item.id, label: item.name }));

        const fields = [
            {
                id: 'providerId',
                label: I18n.get('Provider'),
                exp: 'eq',
                expLabel: 'is',
                cmp: (<SelectField {...this.state.fields.providerId} value={this.state.form.providerId} error={this.state.errors.providerId} values={providers} />),
                hide: this.props.is_ctx_provider || !this.props.auth.is_master
            },
            {
                id: 'username',
                label: I18n.get('Username'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: (<TextField {...this.state.fields.username} value={this.state.form.username} error={this.state.errors.username} />)
            },
            {
                id: 'profile',
                label: I18n.get('Profile'),
                exp: 'eq',
                expLabel: 'is',
                cmp: (<SelectField {...this.state.fields.profile} value={this.state.form.profile} error={this.state.errors.profile} values={domain.profiles} />),
            },
            {
                id: 'type',
                label: I18n.get('Type'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.type} value={this.state.form.type} error={this.state.errors.type} />
            },            
            {
                id: 'field',
                label: I18n.get('Field'),
                exp: 'contains',
                expLabel: 'contains',
                cmp: <TextField {...this.state.fields.field} value={this.state.form.field} error={this.state.errors.field} />
            },
            {
                id: 'updatedAt',
                label: I18n.get('Activity Date'),
                exp: 'between',
                expLabel: 'between',
                cmp: <DateRangeField {...this.state.fields.updatedAt} value={this.state.form.updatedAt} error={this.state.errors.updatedAt} />
            },            
        ];

        return fields;
    }

    render() {

        const providers = this.props.providers && this.props.providers.items && this.props.providers.items.map((item) => ({ id: item.id, label: item.name }));

        const fields = this.getFields();

        return (<div className="flex flex-col sm:flex-row mb-4">

            <div className="flex-none mr-8">


                <nav className="flex flex-row border-b-2 py-2">
                    <span className="py-1 px-2 ml-1 block italic text-gray-600">filter:</span>
                    {fields.map((item, index) => {

                        if (item.hide) return null;

                        const checked = this.state.form.filters.find((s) => s === item.id);
                        const className = 'py-1 px-2 ml-1 mt-1 block text-sm rounded ' + (checked !== undefined ? 'bg-primary shadow text-smart-gray-lighter' : 'text-gray-600 hover:bg-smart-gray-lighter');

                        return (
                            <button key={index} onClick={(e) => this.onChangeFormArrayToggle('filters', item.id)} className={className}>
                                {item.label}
                            </button>
                        );
                    })}
                </nav>

                <div className="mt-4 text-right">

                    {fields.map((item) => {
                        const enabled = this.state.form.filters.find((s) => s === item.id);

                        if (!enabled) return null;

                        const index = this.state.form.filters.indexOf(item.id);

                        return (
                            <div key={item.id} className="flex mb-1">
                                <div className="flex-1 flex items-center justify-end">
                                    {/* {index > 0 && <span className="text-xs text-gray-600 px-4">{I18n.get('and')}</span>} */}
                                    <span className="text-sm">{item.label}</span>
                                    <span className="text-xs text-gray-600 px-4">{item.expLabel}</span>
                                </div>
                                <div className="flex-1">
                                    {item.cmp}
                                </div>
                            </div>
                        );
                    })}

                    <div className="flex mb-1">
                        <div className="flex-1">
                            <button onClick={this.onClearAllClick.bind(this)} className="btn-tertiary mt-4">{I18n.get('Clear All')}</button>
                        </div>
                        <div className="flex-1">
                            <button onClick={this.onFilterClick.bind(this)} className="btn-primary mt-4" disabled={!this.state.form.filters.length} >{I18n.get('Filter')}</button>
                        </div>
                    </div>



                </div>

            </div>

        </div>);
    }
}

export default AuditLogFilter;