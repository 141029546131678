import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.updatePractitionerGroup}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            update_practitioner_group: (data) => {

                return props.mutate({
                    variables: { input: data }
                })
                .then(({ data: { updatePractitionerGroup = {} } }) =>{

                    return {
                        practitioner_group: updatePractitionerGroup
                    }
                });
            }
        })
    })

