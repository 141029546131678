import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listAuditLogs}`,
    {
        options: ({ ts, filter = {} }) => {

            return {

            variables: {
                ts: ts,
                filter: filter,
                limit: 50
            },
            fetchPolicy: 'cache-and-network',
        }},
        props: ({ data: { listAuditLogs = {}, loading, fetchMore } }) => {

            return {
                auditLogs: listAuditLogs,
                loading: loading,
                refresh: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAuditLogs: {
                                ...fetchMoreResult.listAuditLogs
                            }
                        });
                    }
                }),
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAuditLogs: {
                                ...fetchMoreResult.listAuditLogs,
                                items: [...prev.listAuditLogs.items, ...fetchMoreResult.listAuditLogs.items],
                            }
                        });
                    }
                })
            }
        }
    }
)