import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listTpAsByProvider}`,
    {
        options: ({ providerId, filter = {} }) => {

            console.log({ providerId: providerId });

            return {
                variables: {
                    providerId: providerId,
                    filter: filter,
                    //limit: 50
                },
                fetchPolicy: 'cache-and-network',
            };
        },
        props: ({ data: { listTPAsByProvider = {}, loading, fetchMore } }) => {

            const tpas = listTPAsByProvider;

            return {
                tpas: tpas,
                loading: loading,
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listTPAsByProvider: {
                                ...fetchMoreResult.listTPAsByProvider,
                                items: [...prev.listTPAsByProvider.items, ...fetchMoreResult.listTPAsByProvider.items],
                            }
                        });
                    }
                })
            }
        }
    }
)