import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';
import Task from '../../task';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import Address1Field from '../../components/form/address1-field';
import DetailsItem from '../../components/ui/details-item';

import { getCountryCodes, getSpeciality, getSymptom, normalizeAddress } from '../../utils';

import moment from 'moment';
import 'moment-timezone';

import 'whatwg-fetch';

class LocationForm extends React.Component {

    constructor(props) {

        super();

        this.searchRef = React.createRef();

        const { address1, address2, city, zipcode, state, country } = props.form;

        this.state = {
            fields: {
                address1: {
                    name: 'address1',
                    label: I18n.get('Street Address'),
                    onChange: this.onChangeAddress1.bind(this),

                },
                address2: {
                    name: 'address2',
                    label: I18n.get('Unit'),
                    placeholder: 'Apt, Suite, Bldg, Gate Code.',
                    onChange: this.onChangeForm.bind(this, 'address2'),
                },
                city: {
                    name: 'city',
                    label: I18n.get('City'),
                    onChange: this.onChangeForm.bind(this, 'city'),
                    readOnly: true
                },
                zipcode: {
                    name: 'zipcode',
                    label: I18n.get('Zipcode'),
                    onChange: this.onChangeForm.bind(this, 'zipcode'),
                },
                state: {
                    name: 'state',
                    label: I18n.get('State'),
                    onChange: this.onChangeForm.bind(this, 'state'),
                    readOnly: true
                },
                country: {
                    name: 'country',
                    label: I18n.get('Country'),
                    onChange: this.onChangeForm.bind(this, 'country'),
                    maxLength: 2,
                    readOnly: true
                },
            },
            form: {
                address1, 
                address2, 
                city, 
                zipcode, 
                state, 
                country 
            },
            errors: props.errors,
            appointment: {},
            openDatePicker: false,
            autocomplete: null
        };

        Object.keys(this.state.fields).forEach((item) => {
            this.state.form[item] = props[item];
        });
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        //console.log({ LocationForm: 'mount'  });
        this.setupAutocomplete();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //console.log({ LocationForm: 'update'  });

        this.setupAutocomplete();

        if (JSON.stringify(this.props.errors) != JSON.stringify(this.state.errors)) {
            this.setState({ errors: this.props.errors });
        }
    }

    setupAutocomplete() {

        if (this.searchRef.current) {
            const options = {
                types: ['address']
            };

            const geoAutocomplete = new window.google.maps.places.Autocomplete(this.searchRef.current, options);

            geoAutocomplete.addListener('place_changed', () => {

                const place = geoAutocomplete.getPlace();

                console.log({ place: place });

                var { address1, city, state, countryId, zipcode, lat, lng } = normalizeAddress(place);

                this.setState(prevState => {

                    var nextState = Object.assign({}, prevState);
                    nextState.form = { ...prevState.form, address1, city, state, country: countryId, zipcode, lat, lng }
                    nextState.formatted_address = place.formatted_address;

                    return nextState;
                }, () => {

                    this.props.onNext(this.state.form);

                });
                
            });
        }
    }

    onChangeAddress1(e) {

        const value = e.target.value;

        // Reset lat/lng on address1 change so the user has to pick an address from the list

        this.setState(prevState => ({
            form: { ...prevState.form, 'address1': value, 'lat': null, 'lng': null },
            errors: { ...prevState.errors, 'address1': undefined }
        })
        );

        this.props.onChange();
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        console.log({ prop: prop, value: value });

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    render() {

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        // if (this.props.step < 2) return null;

        const item = this.props.form;

        // if (this.props.step > 2) {
        //     return (<div className="card h-full">

        //         <div className="body">

        //             <NavLink to={`/${provider.publicURL}/t/${tpa.id}/appointments/create?step=2`} className="float-right text-sm">
        //                 {I18n.get('Edit')}
        //             </NavLink>


        //             <p className="tracking-wider uppercase text-gray-500 mb-8">{I18n.get('Address')}</p>

        //             <DetailsItem label={I18n.get('Street Address')} value={item.address1} />
        //             <DetailsItem label={I18n.get('Unit')} value={item.address2} />
        //             <DetailsItem label={I18n.get('Zip Code')} value={item.zipcode} />
        //             <DetailsItem label={I18n.get('City')} value={item.city} />
        //             <DetailsItem label={I18n.get('State')} value={item.state} />
        //             <DetailsItem label={I18n.get('Country')} value={item.country} />

        //         </div>

        //     </div>)
        // }

        return (

            <form className="flex-1" onSubmit={this.props.onNext.bind(this, this.state.form)}>

                <div className="card h-full">

                    <div className="body">

                        <p className="tracking-wider uppercase text-gray-500 mb-8">{I18n.get('Address')}</p>
{/* 
                        <div className="field mb-8">

                            <div className="control ">

                                <input type="search" name="address-search" ref={this.searchRef} className="input-search  px-5 py-3 focus:outline-none focus:shadow-outline" placeholder={I18n.get('Type an address')} />

                            </div>
                        </div> */}

                        <Address1Field {...this.state.fields.address1} createRef={this.searchRef} value={this.state.form.address1} error={this.state.errors.address1} />

                        <TextField {...this.state.fields.address2} value={this.state.form.address2} error={this.state.errors.address2} style={{ width: '50%' }} />

                        <TextField {...this.state.fields.city} value={this.state.form.city} error={this.state.errors.city} />

                        <TextField {...this.state.fields.zipcode} value={this.state.form.zipcode} error={this.state.errors.zipcode} style={{ width: '50%' }} />


                        <TextField {...this.state.fields.state} value={this.state.form.state} error={this.state.errors.state} />

                        <TextField {...this.state.fields.country} value={this.state.form.country} error={this.state.errors.country} style={{ width: '50%' }} />


                        <div className="mt-8">

                            <TaskMessage {...this.props.prepare_task}></TaskMessage>

                            {/* <ActionButton task={this.props.prepare_task} disabled={!this.state.form.address1} label={I18n.get('Next')}></ActionButton> */}

                        </div>

                    </div>

                </div>

            </form>);
    }

}
export default LocationForm;