export const getMedicalProvider = `query GetMedicalProvider($id: ID!) {
    getMedicalProvider(id: $id) {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber      
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      groups {
        items {
          id
          name
          score
          createdAt
          updatedAt
          provider {
            id
            name
          }
        }
        nextToken
      }
    }
  }
  `;