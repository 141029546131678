
import { CORE_DELETE_TOAST } from './actions';

const api_call = (data) => {
    return (dispatch, getState) => {

        dispatch({
            type: CORE_DELETE_TOAST,
            payload: {
                ...data
            }
        });
    }
}

export default api_call;