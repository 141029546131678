
import gql from 'graphql-tag';

import { CORE_UPDATE } from './actions';

const api_call = (data) => {
    return (dispatch, getState) => {

        console.log(data);

        dispatch({
            type: CORE_UPDATE,
            payload: {
                notification: data
            }
        });        

        // window.appSyncClient.query({
        //     query: gql`${getTpa}`, variables: data, options: { fetchPolicy: 'network-only' },
        // })
        //     .then(response => {

        //         const tpa = response.data.getTPA || {}

        //         if (typeof (tpa.groups) === 'string') {
        //             tpa.groups = JSON.parse(tpa.groups)
        //         }

        //         dispatch({
        //             type: CORE_UPDATE,
        //             payload: {
        //                 tpa: tpa
        //             }
        //         });

        //     });
    }
}

export default api_call;