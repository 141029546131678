import React from 'react';
import { NavLink, Redirect, withRouter } from 'react-router-dom';

import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';
import Task from '../../task';

import TPAContainer from '../../containers/tpa-container';
import TPALayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DetailsItem from '../../components/ui/details-item';

import PatientForm from './_patient-form';
import LocationForm from './_location-form';
import AvailabilityForm from './_availability-form';

import { DOB, PhoneNumber, Gender, Status, ScheduledDate, ScheduledTime, AssignedTo } from '../shared/_appointment';

import { TimeClearIcon, CheckIcon, CloseButtonIcon, CalendarRegularIcon } from '../../components/ui/icons';

import { getCountryCodes, getSpeciality, getSymptom, normalizeAddress } from '../../utils';

import * as phoneNumberCodeUi from '../../components/ui/phone-number-code';

import moment from 'moment';
import 'moment-timezone';

import 'whatwg-fetch';

class CreatePage extends React.Component {

    constructor(props) {

        super();

        this.searchRef = React.createRef();

        let queryString = {
            step: 1
        };

        if (props.location.search) {

            props.location.search.replace(
                new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                function ($0, $1, $2, $3) { queryString[$1] = $3; return $0; }
            );
        }

        this.state = {
            step: parseInt(queryString.step),
            fields: {
                id: '',
            },
            form: {
                specialities: [],
                symptoms: [],
                scheduledDate: null,
                timezoneId: null,
                timezoneName: null
            },
            errors: {
            },
            appointment: {},
            prepare_task: {},
            request_task: {},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let queryString = {
        };

        if (this.props.location.search) {

            this.props.location.search.replace(
                new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                function ($0, $1, $2, $3) { queryString[$1] = $3; return $0; }
            );

            if (this.state.step != queryString.step) {
                this.setState({ step: queryString.step });
            }

        }
    }

    componentWillUnmount() {
    }


    onPatient(form, e) {

        if (e) e.preventDefault();

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        this.setState(prevState => ({
            form: { ...prevState.form, ...form },
            errors: {}
        }));

    }

    onLocation(form, e) {

        console.log({ onLocation: form });

        if (e) e.preventDefault();

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        const prepare_task = new Task();

        this.setState(prevState => ({
            prepare_task: prepare_task,
            form: { ...prevState.form, ...form },
        }));

        const data = {
            ...this.state.form,
            ...form,
            appointmentTpaId: this.props.tpa.id,
            appointmentProviderId: this.props.tpa.provider.id,
        }

        this.props.prepare_appointment(data)
            .then(({ appointment }) => {

                this.setState({ prepare_task: prepare_task.complete_as_success(), appointment: appointment, ts: 0, step: 3 });

            })
            .catch((err) => {

                const { task, errors } = prepare_task.complete_as_failure(err);

                this.setState({ prepare_task: task, errors: errors });
            });
    }

    onLocationChange(e) {
        this.setState(prevState => ({
            appointment: { ...prevState.appointment, availabilities: {} },
            form: { ...prevState.form, scheduledDate: null, timezoneId: null, timezoneName: null }
        }));
    }

    onRequest(form, e) {

        console.log({ onRequest: form })

        if (e) e.preventDefault();

        const request_task = new Task();

        this.setState(prevState => ({
            request_task: request_task,
            form: { ...prevState.form, ...form },
        }));


        const data = {
            id: this.state.appointment.id,
            ...this.state.form,
            ...form,
            appointmentTpaId: this.props.tpa.id,
            appointmentProviderId: this.props.tpa.provider.id,
        }

        console.log({ data: data });

        this.props.request_appointment(data)
            .then(({ task, appointment }) => {

                this.setState({
                    request_task: task,
                    appointment: appointment
                });
            })
            .catch((err) => {

                const { task, errors } = request_task.complete_as_failure(err);

                this.setState({ request_task: task, errors: errors });
            });
    }

    render() {

        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};
        const appointment = this.state.appointment || {};
        
        const specialities = appointment.specialities && appointment.specialities.map((item) => getSpeciality(item).label).join(', ');

        const symptoms = appointment.symptoms && appointment.symptoms.map((item) => getSymptom(item).label).join(', ');        

        if (appointment.status == 1) {

            const message = I18n.get(`Appointment for '@appointment' has been created.`).replace('@appointment', appointment.name);

            CORE_API.create_toast({ message: message });

            return <Redirect to={`/${provider.publicURL}/t/${tpa.id}/dashboard`} />
        }

        return (<TPALayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Appointment Request')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/dashboard`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="lg:flex">

                    <div className="flex-1">

                        <PatientForm {...this.props} {...this.state} onNext={this.onPatient.bind(this)} />

                        <LocationForm {...this.props} {...this.state} onNext={this.onLocation.bind(this)} onChange={this.onLocationChange.bind(this)} />

                    </div>

                    <div className="flex-1 ml-4">

                        <AvailabilityForm {...this.props} {...this.state} onPrev={this.onLocation.bind(this)} onNext={this.onRequest.bind(this)} />

                    </div>


                </div>

            </div>

        </TPALayout>);
    }

}
export default TPAContainer(withRouter(CreatePage));