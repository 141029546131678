import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.updateTpa}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            update_tpa: (data) => {

                if (typeof (data.groups) !== 'string') {
                    data.groups = JSON.stringify(data.groups);
                }

                return props.mutate({
                    variables: { input: data }
                }).then(({ data: { updateTPA = {} } }) => {

                    return {
                        tpa: updateTPA
                    }
                });
            }
        })
    })

