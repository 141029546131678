import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class IndexPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        console.log(this.props);

        if (this.props.auth.logged_user) {

            if (this.props.auth.is_master) {
                return <Redirect to={`/providers`} />
            }

            if (this.props.auth.is_provider) {
                return <Redirect to={`/${this.props.auth.providerId}/dashboard`} />
            }

            if (this.props.auth.is_tpa) {
                return <Redirect to={`/${this.props.auth.providerId}/t/${this.props.auth.tpaId}/dashboard`} />
            }

            if (this.props.auth.is_practitioner) {
                return <Redirect to={`/${this.props.auth.providerId}/p/${this.props.auth.practitionerId}/dashboard`} />
            }
        }
        else if (this.props.match.params.providerId){
            return <Redirect to={`/${this.props.match.params.providerId}/login`} /> 
        }
        else {
            return <Redirect to={`/login`} />
        }


        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);

