import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import ProviderContainer from '../../containers/provider-container';

import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';

import { CloseButtonIcon } from '../../components/ui/icons';

class EditPractitionerGroupPage extends React.Component {

    constructor(props) {
        super();

        console.log({ props: props });

        const practitioner_group = props.practitioner_group || {};

        this.state = {
            fields: {
                id: '',
                name: {
                    label: I18n.get('Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
            },
            form: {
            },
            errors: {
            },
            task: {
            }
        };

        Object.keys(this.state.fields).forEach((item) => {
            this.state.form[item] = practitioner_group[item];
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.practitioner_group) != JSON.stringify(this.props.practitioner_group)) {

            var nextState = Object.assign({}, prevState);

            Object.keys(this.state.fields).forEach((item) => {
                nextState.form[item] = this.props.practitioner_group[item];
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value || e.target.checked;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {
        e.preventDefault();

        const provider = this.props.provider || {};

        const data = {
            id: this.props.match.params.practitionerGroupId,
            practitionerGroupProviderId: provider.id,
            ...this.state.form,
        }

        var task = new Task();

        this.setState({ task: task });

        this.props.update_practitioner_group(data)
            .then(({ practitioner_group }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/groups`);

                CORE_API.create_toast({ message: I18n.get(`Practitioner Group '@practitioner_group' has been updated.`).replace('@practitioner_group', practitioner_group.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        const provider = this.props.provider;
        const practitioner_group = this.props.practitioner_group || {};

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Edit Practitioner Group')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/groups/${practitioner_group.id}/details`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-8">{I18n.get('Group Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />


                            <br /><br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />


                        </div>
                    </div>

                </form>

            </div>

        </ProviderLayout>);
    }
}

export default ProviderContainer(CORE_API.get_practitioner_group(EditPractitionerGroupPage));



