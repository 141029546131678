import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import PractitionerGroupList from './_practitioner-group-list';

import { GroupIcon } from '../../components/ui/icons';

class PractitionerGroupsPage extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {

    }

    render() {

        const provider = this.props.provider;

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <GroupIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Practitioner Groups')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/groups/new`} className="btn-primary">
                        {I18n.get('Create Group')}
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <PractitionerGroupList {...this.props} />

            </div>

        </ProviderLayout>);
    }

}

export default ProviderContainer(PractitionerGroupsPage);

