import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import { AppointmentStatus } from '../../model';

import * as subscriptions from '../../graphql/subscriptions';

import { listAppointmentsByTpa } from '../../graphql/queries/listAppointmentsByTpa';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${listAppointmentsByTpa}`,
    {
        options: ({ tpaId, filter = {} }) => ({
            variables: {
                tpaId: tpaId,
                filter: filter,
                limit: 50
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { listAppointmentsByTPA = {}, loading, fetchMore, subscribeToMore } }) => {

            const appointments = listAppointmentsByTPA;

            appointments.items && appointments.items.map((item) => {
                if (typeof (item.specialities) === 'string') {
                    item.specialities = JSON.parse(item.specialities)
                }
                if (typeof (item.symptoms) === 'string') {
                    item.symptoms = JSON.parse(item.symptoms)
                }
            });

            return {
                appointments: appointments,
                loading: loading,
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAppointmentsByTPA: {
                                ...fetchMoreResult.listAppointmentsByTPA,
                                items: [...prev.listAppointmentsByTPA.items, ...fetchMoreResult.listAppointmentsByTPA.items],
                            }
                        });
                    }
                }),
                subscribeToTpaAppointment: (tpaId) => {

                    const info = {
                        document: gql`${subscriptions.onTpaAppointment}`,
                        updateQuery: (prev, { subscriptionData }) => {

                            console.log({ prev: prev, subscriptionData: subscriptionData });

                            const item = subscriptionData.data.onTpaAppointment || {};

                            if (item.status == AppointmentStatus.Requested){

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been created.`).replace('@appointment', item.name) })
                            }
                            else {

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been updated.`).replace('@appointment', item.name) })
                            }

                            return prev;
                        },
                        variables: {
                            appointmentTpaId: tpaId
                        }
                    }

                    subscribeToMore(info);
                }
            }
        }
    }
)