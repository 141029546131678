import React from 'react';
import { components } from 'react-select';

export const SingleValue = ({ children, data, ...props }) => {

    const item = data.data;

    return (<components.SingleValue {...props}>{item.Code}</components.SingleValue>);

};

export const Option = ({ innerProps, data }) => {

    const item = data.data;

    innerProps = item.disabled ? {} : innerProps;

    const className = 'p-1 cursor-pointer hover:bg-react-select-blue ' + (item.disabled ? 'opacity-25' : '')

    return (<div  {...innerProps} className={className}>
        <div className="text-sm">{item.Code}</div>
        <div className="text-xs text-gray-500">{item.Name}</div>
    </div>);
}
