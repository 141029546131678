import React from 'react';
import { I18n } from 'aws-amplify';

import moment from 'moment';

import Task from '../../task';

import { CORE_API } from '../../actions/core/actions';

import AppointmentCard from './appointment-card';

import Map from '../../components/map';

import { Button } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import { AppointmentStatus } from '../../model';

import TextAreaField from '../../components/form/textarea-field';
import RadioField from '../../components/form/radio-field';

import { compose } from 'react-apollo';

import domain from '../../domain';

import { CloseButtonIcon } from '../../components/ui/icons';


class AppointmentDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
            view: 'details',
            fields: {
                noShow: false,
                medicalHistory: {
                    label: I18n.get('Medical History'),
                    onChange: this.onChangeForm.bind(this, 'medicalHistory'),
                    rows: 2
                },
                diagnosticHypothesis: {
                    label: I18n.get('Diagnostic Hypothesis'),
                    onChange: this.onChangeForm.bind(this, 'diagnosticHypothesis'),
                    rows: 2
                },
                physicalExamination: {
                    label: I18n.get('Physical Examination'),
                    onChange: this.onChangeForm.bind(this, 'physicalExamination'),
                    rows: 2
                },
                requestedTests: {
                    label: I18n.get('Tests Requested'),
                    onChange: this.onChangeForm.bind(this, 'requestedTests'),
                    rows: 2
                },
                medications: {
                    label: I18n.get('Medications'),
                    onChange: this.onChangeForm.bind(this, 'medications'),
                    rows: 2
                },
                referredER: {
                    label: I18n.get('Referred to Emergency Room'),
                    name: 'referredER',
                    onChange: this.onChangeForm.bind(this, 'referredER'),
                    values: [{ id: true, label: I18n.get('Yes') }, { id: false, label: I18n.get('No') }]
                },
                removal: {
                    label: I18n.get('Removal'),
                    name: 'removal',
                    onChange: this.onChangeForm.bind(this, 'removal'),
                    values: [{ id: true, label: I18n.get('Yes') }, { id: false, label: I18n.get('No') }]
                },
                assessment: {
                    label: I18n.get('Assessment'),
                    onChange: this.onChangeForm.bind(this, 'assessment'),
                    rows: 4
                }
            },
            form: {
            },
            errors: {

            },
            cancel_task: {},
            start_task: {},
            noshow_task: {},
            complete_task: {},
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    selectView(view, e) {
        this.setState({ view: view });
    }

    onChangeForm(prop, e) {

        const value = e.target.value || e.target.checked;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }


    onCancel(e) {
        e.preventDefault();

        var cancel_task = new Task();

        const data = {
            id: this.props.appointment.id,
        }

        this.setState({ cancel_task: cancel_task });

        this.props.cancel_appointment(data)
            .then(({ appointment }) => {

                CORE_API.create_toast({ message: I18n.get(`Appointment '@appointment' has been cancelled.`).replace('@appointment', appointment.name) });

                this.setState({ cancel_task: cancel_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = cancel_task.complete_as_failure(err);

                this.setState({ cancel_task: task, errors: errors });
            });
    }

    onStart(e) {
        e.preventDefault();

        var start_task = new Task();

        const data = {
            id: this.props.appointment.id,
            practitionerId: this.props.practitioner.id
        }

        this.setState({ start_task: start_task });

        this.props.start_appointment(data)
            .then(({ appointment }) => {

                //CORE_API.create_toast({ message: `Appointment '@appointment' has been started.`.replace('@appointment', appointment.name) });

                this.setState({ start_task: start_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = start_task.complete_as_failure(err);

                this.setState({ start_task: task, errors: errors });
            });
    }

    onNoShow(e) {
        e.preventDefault();

        var noshow_task = new Task();

        const data = {
            id: this.props.appointment.id,
            practitionerId: this.props.practitioner.id,
            noShow: true
        }

        this.setState({ noshow_task: noshow_task });

        this.props.complete_appointment(data)
            .then(({ appointment }) => {

                //CORE_API.create_toast({ message: `Appointment '@appointment' has been started.`.replace('@appointment', appointment.name) });

                this.setState({ noshow_task: noshow_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = noshow_task.complete_as_failure(err);

                this.setState({ noshow_task: task, errors: errors });
            });
    }

    onComplete(e) {
        e.preventDefault();

        var complete_task = new Task();

        const data = {
            id: this.props.appointment.id,
            practitionerId: this.props.practitioner.id,
            noShow: false,
            ...this.state.form
        }

        this.setState({ complete_task: complete_task });

        this.props.complete_appointment(data)
            .then(({ appointment }) => {

                //CORE_API.create_toast({ message: `Appointment '@appointment' has been started.`.replace('@appointment', appointment.name) });

                this.setState({ complete_task: complete_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = complete_task.complete_as_failure(err);

                this.setState({ complete_task: task, errors: errors });
            });
    }

    render() {

        const item = this.props.appointment;

        const status = domain.appointment.statusMap[item.status] || {};

        const location = new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng));

        const displayCancel = item.status < AppointmentStatus.Started && (this.props.auth.is_provider || this.props.auth.is_tpa);
        const enableCancel = this.props.auth.is_provider || (item.scheduledDateUtc && moment.utc().add(60, 'minutes') < moment(item.scheduledDateUtc));

        console.log({ displayCancel: displayCancel, enableCancel: enableCancel });

        const displayStart = this.props.auth.is_practitioner;
        const enableStart = item.status == AppointmentStatus.Assigned;

        const displayNoShow = this.props.auth.is_practitioner;
        const enableNoShow = item.status == AppointmentStatus.Assigned;

        const displayComplete = this.props.auth.is_practitioner;
        const enableComplete = item.status == AppointmentStatus.Started;

        return (<div>
            <div className="bg-white flex justify-end items-center border-b">
                <div className="flex-1 p-4">
                    <span className="font-semibold mr-2">{I18n.get('Appointment')}</span> &middot; <span className="ml-2 italic text-sm">{I18n.get(status.label)}</span>
                </div>
                <div className="flex-1 text-right pr-4">
                    <a href="#" onClick={(e) => this.props.select({}, e)} className="card-header-icon" aria-label={I18n.get('more options')}>
                        <CloseButtonIcon className="icon w-3 h-3" />
                    </a>
                </div>
            </div>
            <div className="p-4">

                {/* {this.props.auth.is_practitioner && <div className="tabs is-medium">
                    <ul>
                        <li className={this.state.view == 'details' ? 'is-active' : ''}>
                            <a onClick={(e) => this.selectView('details', e)}>
                                <span className="icon">
                                    <i className="fas fa-user-injured"></i>
                                </span>
{I18n.get('Details')}
                            </a>
                        </li>
                        <li className={this.state.view == 'record' ? 'is-active' : ''}>
                            <a onClick={(e) => this.selectView('record', e)}>
                                <span className="icon">
                                    <i className="fas fa-notes-medical"></i>
                                </span>
{I18n.get('Medical Record')}
                            </a>
                        </li>
                    </ul>
                </div>} */}

                <form>

                    {this.state.view == 'details' && <div className="flex flex-col sm:flex-row">
                        <div className="flex-1 p-8">

                            {(this.props.auth.is_master || this.props.auth.is_provider) && <>

                                <p className="font-semibold text-lg leading-wider">{I18n.get('TPA')}</p>

                                <p className="mt-2 mb-8">{item.tpa && item.tpa.name}</p>

                            </>}

                            <AppointmentCard item={item} />

                        </div>

                        <div className="flex-1 p-8">

                            <p className="font-semibold text-lg leading-wider">{I18n.get('Location')}</p>

                            <div className="mt-4">
                                <p className="text-lg">{item.address1}</p>
                                <p className="text-sm">{item.address2}</p>
                            </div>
                            <div className="mt-1">
                                <p className="text-lg">{item.city}, {item.state} {item.zipcode}</p>
                            </div>

                            <br className="mt-4" />

                            <Map defaultCenter={location} marker={location} style={{ height: '300px' }}></Map>

                        </div>


                    </div>}

                    <div className="p-8 mt-4">

                        {displayStart && <Button onClick={this.onStart.bind(this)} task={this.state.start_task} className="btn-primary" label={I18n.get('Start')} disabled={!enableStart}></Button>}

                        {displayComplete && <Button onClick={this.onComplete.bind(this)} task={this.state.complete_task} className="btn-primary mx-2" label={I18n.get('Complete')} disabled={!enableComplete}></Button>}

                        {displayNoShow && <Button onClick={this.onNoShow.bind(this)} task={this.state.noshow_task} className="btn-tertiary" label={I18n.get('No show')} disabled={!enableNoShow}></Button>}

                        <TaskMessage {...this.state.start_task}>{I18n.get('Started successfully.')}</TaskMessage>
                        <TaskMessage {...this.state.complete_task}>{I18n.get('Completed successfully.')}</TaskMessage>
                        <TaskMessage {...this.state.noshow_task}>{I18n.get('Completed as no-show successfully.')}</TaskMessage>

                    </div>

                </form>

                <div className="text-right">

                    {displayCancel && <Button label={I18n.get('Cancel Appointment')} onClick={this.onCancel.bind(this)} className="btn-tertiary" task={this.state.cancel_task} disabled={!enableCancel}></Button>}

                    <TaskMessage {...this.state.cancel_task}>Canceled successfully.</TaskMessage>

                </div>


            </div>
        </div >);
    }
}

export default compose(
    CORE_API.start_appointment,
    CORE_API.complete_appointment,
    CORE_API.cancel_appointment
)(AppointmentDetails);