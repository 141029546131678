import { LOGIN_SUCCESS } from './actions';

const api_login_success = (logged_user, task) => {

    const group = logged_user.signInUserSession.getIdToken().payload['cognito:groups'];
    const email = logged_user.signInUserSession.getIdToken().payload['email'];
    const username = logged_user.signInUserSession.getIdToken().payload['cognito:username'];
    const providerId = logged_user.signInUserSession.getIdToken().payload['family_name'];
    const tpaId = logged_user.signInUserSession.getIdToken().payload['custom:tpa_id'];
    const practitionerId = logged_user.signInUserSession.getIdToken().payload['custom:practitioner_id'];
    const locale = logged_user.signInUserSession.getIdToken().payload['custom:locale'];
    const userId = logged_user.signInUserSession.getIdToken().payload['sub'];

    const profile = group ? group[0] : '';

    const payload = {
        init: false,
        logged_user: logged_user,
        email: email,
        username: username,
        profile: profile,
        is_tpa: (profile == 'TPA'),
        is_practitioner: (profile == 'Practitioner'),
        is_provider: (profile == 'MedicalProvider'),
        is_master: (profile == 'Master'),
        is_master_or_provider: (profile == 'Master') || (profile == 'MedicalProvider'),
        providerId: providerId || '',
        tpaId: tpaId || '',
        practitionerId: practitionerId || '',
        locale: locale,
        userId: userId,
        task: task
    };

    return payload;

};

export default api_login_success;