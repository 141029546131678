import React from 'react';
import moment from 'moment';


const Span = ({value}) => {

    if (!value) return '-';

    return (<span>{moment(value).format('DD/MM/YYYY HH:mm ')}</span>);
}

export default Span;