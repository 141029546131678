import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import TPAContainer from '../../containers/tpa-container';

import TPALayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DateTimeSpan from '../../components/datetime-span';

import DetailsItem from '../../components/ui/details-item';

import { DetailsListIcon } from '../../components/ui/icons';

class DetailsPage extends React.Component {

    constructor(props) {
        super();

        this.state = {
        }
    }

    componentDidMount() {

    }


    getGroup(id) {

        if (!this.props.tpa.provider) return {};

        const groups = this.props.tpa.provider.groups && this.props.tpa.provider.groups.items;

        const items = groups && groups.filter((item) => (item.id === id));

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }
    }

    render() {

        const tpa = this.props.tpa;

        const provider = tpa.provider || {};

        return (<TPALayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <DetailsListIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('TPA Details')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/edit`} className="btn-primary">
                        {I18n.get('Edit')}
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="card">

                    <div className="body">

                        <p className="subtitle mb-8">{I18n.get('TPA Details')}</p>

                        <DetailsItem label={I18n.get('Name')} value={tpa.name} />
                        <DetailsItem label={I18n.get('Contact Name')} value={tpa.contactName} />
                        <DetailsItem label={I18n.get('Contact Email')} value={tpa.contactEmail} />
                        <DetailsItem label={I18n.get('Contact Phone')} value={`${tpa.contactPhoneNumberCode} ${tpa.contactPhoneNumber}`} />
                        <DetailsItem label={I18n.get('Created')} value={<DateTimeSpan value={tpa.createdAt} />} />

                        <p className="subtitle mt-12 mb-8">{I18n.get('Billing Address')}</p>

                        <DetailsItem label={I18n.get('Street Address')} value={tpa.address1} />
                        <DetailsItem label={I18n.get('Unit')} value={tpa.address2} />
                        <DetailsItem label={I18n.get('Zip Code')} value={tpa.zipcode} />
                        <DetailsItem label={I18n.get('City')} value={tpa.city} />
                        <DetailsItem label={I18n.get('State')} value={tpa.state} />
                        <DetailsItem label={I18n.get('Country')} value={tpa.countryId} />

                        {this.props.auth.is_master_or_provider && <>

                        <p className="subtitle mt-12 mb-8">{I18n.get('Practitioner Groups')}</p>

                        <div>
                            {tpa.groups && tpa.groups.map((item, index) => {

                                return (
                                    <div key={index} className="tag">
                                        {this.getGroup(item).name}
                                    </div>
                                );
                            })}

                            {tpa.groups && tpa.groups.length == 0 && <p className="mb-4 text-sm">
                                No group selected, all practitioners are elegible.
                            </p>}
                        </div>

                        </>}

                    </div>

                </div>

            </div>

            <br />

        </TPALayout >);
    }

}

export default TPAContainer(DetailsPage);

