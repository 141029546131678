import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';


import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';

import ProviderLayout from '../shared/layout';

import ToastContainer from '../shared/toast-container';

import { CoverageIcon } from '../../components/ui/icons';

import Map from '../../components/map';

class CoveragePage extends React.Component {

    constructor(props) {
        super();

        this.state = {
            fields: {
            },
            form: {

            },
            location: new window.google.maps.LatLng(39.8097343, -98.5556199)
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    render() {

        const provider = this.props.provider || {};
        const coverage = this.props.coverage || [];

        const features = [];

        coverage && coverage.map((item) => {

            if (item.lat && item.lng && item.radius) {

                var feature = {
                    "type": "Feature",
                    "properties": {
                        "radius": item.radius,
                    },
                    "geometry": { "type": "Point", "coordinates": [parseFloat(item.lng), parseFloat(item.lat)] }
                }

                features.push({ label: item.name, radius: parseInt(item.radius), lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
            }

        });

        return (<ProviderLayout {...this.props} >

            <div className="page-header">

                <div className="title">
                    <CoverageIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Coverage Area')}
                </div>

                <div className="bar">

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <Map defaultCenter={this.state.location} coverage={features} zoom={5} style={{ height: '800px' }} />

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(CORE_API.get_coverage(CoveragePage));

