import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import PractitionerContainer from '../../containers/practitioner-container';
import PractitionerLayout from '../shared/layout';

import ToastContainer from '../shared/toast-container';

import ReportList from '../shared/_report-list';

import { ReportIcon } from '../../components/ui/icons';

const ReportListWithData = CORE_API.listAppointmentsByPractitioner(ReportList);

class ReportPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {

        const practitioner = this.props.practitioner || {};

        return (<PractitionerLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <ReportIcon className="mr-3 opacity-50" />
                    {I18n.get('Report')}
                </div>

                <div className="flex-grow flex items-center justify-end">
                </div>

            </div>


            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {this.props.providerId && <ReportListWithData  {...this.props} practitionerId={practitioner.id} />}

            </div>

        </PractitionerLayout>);
    }

}

export default PractitionerContainer(ReportPage);

