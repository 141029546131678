import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { DashboardHomeIcon, DetailsListIcon, ProviderUserIcon, ReportIcon } from '../../components/ui/icons';

const ASide = ({ tpa, auth, menuIsOpen = false }) => {

    if (!tpa || !tpa.id) return null;

    const provider = tpa.provider || {};

    const labelCssClass = menuIsOpen ? 'inline-block label ' : 'hidden lg:inline-block label ';

    return (
        <aside className="mt-12 sm:mt-16 text-gray-300">

            <ul className="list-reset flex flex-col">

                {/* <li className="flex-1" style={{ backgroundColor: '#3D4961' }}>
                    <div className="h-20 px-5 py-6">
                        <span className="text-gray-300 text-2xl">
                            <NavLinkOrSpan auth={auth} />
                        </span>
                    </div>
                </li> */}

                <li className="flex-1" style={{ backgroundColor: '#3D4961' }}>
                    <div className="px-5 py-6">
                        <span className={labelCssClass + ' text-gray-300 text-xl'}>
                            {tpa.name}
                        </span>
                    </div>
                </li>

                <li className="mt-8">
                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/dashboard`} className="menu-item hover:text-smart-blue">
                        <DashboardHomeIcon />
                        <span className={labelCssClass}>{I18n.get('Dashboard')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/details`} className="menu-item hover:text-smart-blue">
                        <DetailsListIcon />
                        <span className={labelCssClass}>{I18n.get('TPA Details')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/users`} className="menu-item hover:text-smart-blue">
                        <ProviderUserIcon />
                        <span className={labelCssClass}>{I18n.get('TPA Users')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/report`} className="menu-item hover:text-smart-blue">
                        <ReportIcon />
                        <span className={labelCssClass}>{I18n.get('Report')}</span>
                    </NavLink>
                </li>

            </ul>
        </aside>
    );

}

export default ASide;