import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import Address1Field from '../../components/form/address1-field';
import TextField from '../../components/form/text-field';
import MaskedTextField from '../../components/form/masked-text-field';
import SelectSearchField from '../../components/form/select-search-field';

import SelectField from '../../components/form/select-field';

import domain from '../../domain';

import { getCountryCodes, normalizeAddress } from '../../utils';

import * as phoneNumberCodeUi from '../../components/ui/phone-number-code';

class CreatePage extends React.Component {

    constructor(props) {

        super();

        this.searchRef = React.createRef()

        this.state = {
            fields: {
                id: '',
                name: {
                    name: 'name',
                    label: I18n.get('Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                },
                contactName: {
                    name: 'contactName',
                    label: I18n.get('Contact Name'),
                    onChange: this.onChangeForm.bind(this, 'contactName'),
                },
                contactEmail: {
                    name: 'contactEmail',
                    label: I18n.get('Contact Email'),
                    onChange: this.onChangeForm.bind(this, 'contactEmail'),
                },
                contactPhoneNumberCode: {
                    name: 'contactPhoneNumberCode',
                    placeholder: I18n.get('Country code'),
                    onChange: this.onChangeFormValue.bind(this, 'contactPhoneNumberCode'),
                    components: {
                        ...phoneNumberCodeUi
                    }
                },
                contactPhoneNumber: {
                    name: 'contactPhoneNumber',
                    onChange: this.onChangeForm.bind(this, 'contactPhoneNumber'),
                    mask: '',
                    format: '###############',
                },
                address1: {
                    name: 'address1',
                    label: I18n.get('Street Address'),
                    onChange: this.onChangeForm.bind(this, 'address1'),
                },
                address2: {
                    name: 'address2',
                    label: I18n.get('Unit'),
                    placeholder: 'Apt, Suite, Bldg, Gate Code.',
                    onChange: this.onChangeForm.bind(this, 'address2'),
                    isOptional: true
                },
                zipcode: {
                    name: 'zipcode',
                    label: I18n.get('Zip Code'),
                    onChange: this.onChangeForm.bind(this, 'zipcode'),
                },
                city: {
                    name: 'city',
                    label: I18n.get('City'),
                    onChange: this.onChangeForm.bind(this, 'city'),
                    readOnly: true
                },
                state: {
                    name: 'state',
                    label: I18n.get('State'),
                    onChange: this.onChangeForm.bind(this, 'state'),
                    readOnly: true
                },
                countryId: {
                    name: 'countryId',
                    label: I18n.get('Country'),
                    onChange: this.onChangeForm.bind(this, 'countryId'),
                    readOnly: true,
                    maxLength: 2
                },
                groups: {
                }
            },
            form: {
                groups: []
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
        const options = {
            types: ['address']
        };

        const geoAutocomplete = new window.google.maps.places.Autocomplete(this.searchRef.current, options);
        geoAutocomplete.addListener('place_changed', () => {

            const place = geoAutocomplete.getPlace();

            console.log({ place: place });

            var { address1, city, state, zipcode, countryId } = normalizeAddress(place);

            this.setState(prevState => {

                var nextState = Object.assign({}, prevState);

                nextState.form = { ...prevState.form, address1, city, state, zipcode, countryId };
                nextState.formatted_address = place.formatted_address;

                return nextState;
            });
        });
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onSubmitForm(e) {

        e.preventDefault();

        const provider = this.props.provider || {};

        var task = new Task();

        const data = {
            tPAProviderId: this.props.provider.id,
            ...this.state.form
        }

        this.setState({ task: task });

        this.props.create_tpa(data)
            .then(({ tpa }) => {

                CORE_API.create_toast({ message: I18n.get(`TPA '@tpa' has been created.`).replace('@tpa', tpa.name) });

                this.props.history.push(`/${provider.publicURL}/tpas`);
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    getStates() {

        if (!this.props.provider.country || !this.props.provider.country.id) return [];

        return domain[this.props.provider.country.id] && domain[this.props.provider.country.id].states;
    }

    render() {

        const provider = this.props.provider;

        if (this.state.task.result) {

            return (<Redirect to={`/${provider.publicURL}/dashboard`} />);
        }

        return (<ProviderLayout {...this.props}>


            <div className="page-header">

                <div className="title">
                    {I18n.get('Create TPA')}
                </div>

                <div className="bar">

                    <BackButton {...this.props} />

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-8">{I18n.get('User Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField {...this.state.fields.contactName} value={this.state.form.contactName} error={this.state.errors.contactName} />

                            <TextField {...this.state.fields.contactEmail} value={this.state.form.contactEmail} error={this.state.errors.contactEmail} />

                            <div className="field mb-4">
                                <label className="label">{I18n.get('Contact Phone')}</label>

                                <div className="flex flex-grow flex-row">

                                    <SelectSearchField {...this.state.fields.contactPhoneNumberCode} value={this.state.form.contactPhoneNumberCode} error={this.state.errors.contactPhoneNumberCode} values={getCountryCodes()} className="flex-1 mb-0 mr-8" />

                                    <MaskedTextField {...this.state.fields.contactPhoneNumber} value={this.state.form.contactPhoneNumber} error={this.state.errors.contactPhoneNumber} className="flex-1 mb-0" />

                                </div>

                            </div>

                            <p className="subtitle mt-8 mb-12">{I18n.get('Address')}</p>

                            {/* <div className="field mb-12">

                                <div className="control">

                                    <input type="search" ref={this.searchRef} className="input-search px-5 py-3 focus:outline-none focus:shadow-outline" placeholder={I18n.get('Type an address')} />

                                </div>
                            </div> */}

                            <Address1Field {...this.state.fields.address1} createRef={this.searchRef} value={this.state.form.address1} error={this.state.errors.address1} />

                            <TextField {...this.state.fields.address2} value={this.state.form.address2} error={this.state.errors.address2} />

                            <TextField {...this.state.fields.zipcode} value={this.state.form.zipcode} error={this.state.errors.zipcode} style={{ width: '50%' }} />

                            <TextField {...this.state.fields.city} value={this.state.form.city} error={this.state.errors.city} />

                            <TextField {...this.state.fields.state} value={this.state.form.state} error={this.state.errors.state} />

                            <TextField {...this.state.fields.countryId} value={this.state.form.countryId} error={this.state.errors.countryId} />

                            <p className="subtitle mt-8 mb-12">{I18n.get('Groups')}</p>

                            <div>
                                {provider.groups && provider.groups.items.map((item, index) => {

                                    const checked = this.state.form.groups && this.state.form.groups.indexOf(item.id) > -1;
                                    const className = 'btn-toggle m-2 hover:bg-smart-blue hover:text-white' + (checked ? ' on' : '');

                                    return (
                                        <button type="button" key={index} onClick={(e) => this.onChangeFormArrayToggle('groups', item.id)} className={className} style={{ margin: '0.25rem', minWidth: '150px' }}>
                                            {item.name}
                                        </button>
                                    );
                                })}
                            </div>

                            <br />
                            <br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Create')} />

                        </div>

                    </div>

                </form>

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(CreatePage);

