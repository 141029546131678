
export const getTpa = /* GraphQL */ `
  query GetTpa($id: ID!) {
    getTPA(id: $id) {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
        groups {
          items {
            id
            name
            score
            createdAt
            updatedAt
          }
          nextToken
        }        
      }
    }
  }
`