
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as queries from '../../graphql/queries';

export default graphql(
    gql`${queries.getAppointmentRequest}`,
    {
        options: ({ appointmentId, appointmentRequestId }) => {

            console.log({ appointmentId, appointmentRequestId });
            
            return ({
                variables: {
                    id: appointmentId,
                    practitionerId: appointmentRequestId
                },
                fetchPolicy: 'cache-and-network',
            })
        },
        props: ({ data: { getAppointmentRequest = {}, loading, refetch } }) => {

            const appointmentRequest = getAppointmentRequest;

            if (appointmentRequest.appointment && typeof (appointmentRequest.appointment['symptoms']) === 'string') {
                appointmentRequest.appointment['symptoms'] = JSON.parse(appointmentRequest.appointment['symptoms']);
            }

            // console.log({ appointmentRequest: appointmentRequest });

            return {
                appointmentRequest: appointmentRequest,
                loading: loading,
                refetch: refetch
            }
        }
    }
)

