import { I18n } from 'aws-amplify';

const api_login_failure = (err) => {

    const password_reset_required = (err && err.code == "PasswordResetRequiredException");

    const payload = {
        init: false,
        logged_user: null,
        password_reset_required: password_reset_required,
        task: {
            result: false,
            error: {
                message: I18n.get(err.code || err.message)
            }
        },
        errors: {
        }
    }

    return  payload;
};

export default api_login_failure;