import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';
import TimeSpan from '../../components/time-span';

import { MaleIcon, FemaleIcon, CalendarRegularIcon, TimeClearIcon, PractitionersIcon, LocationIcon } from '../../components/ui/icons';

import DetailsItem from '../../components/ui/details-item';

var getGender = (id) => {
    return domain.genderMap[id] || {};
}

export const DOB = ({ v }) => {

    return (
        <DetailsItem label={I18n.get('DOB')} value={<DateSpan value={v} />} />
    )
}


export const PhoneNumber = ({ v }) => {

    return (
        <DetailsItem label={I18n.get('Phone')} value={v} />
    )
}

export const Gender = ({ v }) => {

    const label = getGender(v).label;

    return (
        <DetailsItem label={I18n.get('Gender')} value={I18n.get(label)} />
    )
}

export const Status = ({ v }) => {

    const status = domain.appointment.statusMap[v] || {};

    return (
        <DetailsItem label={I18n.get('Status')} value={I18n.get(status.label)} />
    )
}

export const ScheduledDate = ({ v, timezoneId }) => {

    return (
        (<div className="text-xl">
            <CalendarRegularIcon /> &nbsp;
            <DateSpan value={v} timezoneId={timezoneId} className="ml-3" />
        </div>)
    )

    return (
        <DetailsItem label={I18n.get('Date')} value={(<div className="text-lg">
            <CalendarRegularIcon /> &nbsp;
            <DateSpan value={v.scheduledDate} timezoneId={timezoneId} />
        </div>)} />
    )
}

export const ScheduledTime = ({ v, timezoneId, timezoneName }) => {

    return (
        <div className="text-xl">
            <TimeClearIcon /> &nbsp;
            <TimeSpan value={v} timezoneId={timezoneId} className="ml-3" /> {timezoneName && <span> &middot; {timezoneName.split(' ').map((word) => word.substring(0, 1))} </span>}
        </div>
    )
}

export const AssignedTo = ({ practitioner, className = '' }) => {

    if (!practitioner){
        return (<div className={'text-xl text-gray-400 ' + className}>
            <PractitionersIcon /> &nbsp;
            <span className="ml-3 italic">{I18n.get('Unassigned')}</span>
        </div>);
    }

    return (
        <div className={'text-xl ' + className}>
            <PractitionersIcon /> &nbsp;
            <span className="ml-3">{practitioner.name}</span>
        </div>
    );
}
