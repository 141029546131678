import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import Modal from '../../components/modal';

import domain from '../../domain';

import { AppointmentStatus } from '../../model';

import AppointmentItem from '../shared/_appointment-item';

import AppointmentDetails from '../shared/appointment-details';

import AppointmentFilter from './_appointment-filter';

import { Button } from '../../components/ui/buttons';

const AppointmentDetailsWithData = CORE_API.get_appointment(AppointmentDetails);

class List extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: {},
            pages: [],
            token: '',
            fields: {
            },
            form: {
            },
            load_task: {},
            errors: {},
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const providerId = this.props.provider && this.props.provider.id;

        if (this.props.subscribeToProviderAppointment && this.state.subscribed != providerId) {

            console.log(`subscribeToProviderAppointment: ${providerId}`);

            this.props.subscribeToProviderAppointment(providerId);

            this.setState({ subscribed: providerId })
        }

        const practitionerId = this.props.practitioner && this.props.practitioner.id;

        if (this.props.subscribeToPractitionerAppointment && this.state.subscribed != practitionerId) {

            console.log(`subscribeToPractitionerAppointment: ${practitionerId}`);

            this.props.subscribeToPractitionerAppointment(practitionerId);

            this.setState({ subscribed: practitionerId })
        }      
        
        const tpaId = this.props.tpa && this.props.tpa.id;

        if (this.props.subscribeToTpaAppointment && this.state.subscribed != tpaId) {

            console.log(`subscribeToTpaAppointment: ${tpaId}`);

            this.props.subscribeToTpaAppointment(tpaId);

            this.setState({ subscribed: tpaId })
        }              
    }

    select(item, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            if (prevState.selected.id == item.id) {

                return { selected: {} };
            }
            else {
                return {
                    selected: {
                        ...item,
                    }
                };
            }

        });
    }

    loadMore(token) {

        console.log({ loadMore: token });

        const data = {
            nextToken: token,
            filter: this.props.filter
        }

        var load_task = new Task();

        this.setState({ load_task: load_task });

        this.props.next(data)
            .then(({ listAppointments }) => {

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    getStatues() {
        return domain.appointment.statuses.map((item) => ({ code: item.id, label: I18n.get(item.label) }));
    }

    render() {

        const appointments = this.props.appointments || {};

        const items = appointments.items || [];
        const nextToken = appointments.nextToken;

        const filterStatus = (item) => {
            if (this.props.auth.is_practitioner) {
                return item.id >= AppointmentStatus.Assigned;
            }
            else {
                return true;
            }
        }

        return (<section className={this.props.loading ? 'loading-mask' : ''}>

            <AppointmentFilter {...this.props} />

            <div className="mt-4 bg-white shadow-md rounded-lg overflow-hidden">
                {items && items.map((item, index) =>
                    (<div key={index} onClick={(e) => this.select(item, e)} className="cursor-pointer">
                        <AppointmentItem item={item} />
                    </div>)
                )}
            </div>

            <br />

            <div className="text-center">
                <Button task={this.state.load_task} onClick={(e) => this.loadMore(nextToken)} disabled={!nextToken} label={I18n.get('Load more')}></Button>
            </div>

            <br />

            <Modal isOpen={this.state.selected.id}>

                <AppointmentDetailsWithData {...this.props} appointmentId={this.state.selected.id} select={this.select.bind(this)} />

            </Modal>


        </section>);

    }
}

export default List;
