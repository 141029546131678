import React from 'react';

const FileField = ({ label, onChange, fieldName, placeholder = '', value, style = {}, isOptional, error, onBlur, readOnly = false, className = 'field mb-4' }) => {

    const errorClassName = 'border-red-500';

    const inputClassName = 'flex-grow appearance-none border rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:shadow-outline ';

    return (
        <div className={className}>
            <label className="label">
                {label}
            </label>
            <div className="control">
                <input
                    type="file"
                    className={inputClassName + (error ? errorClassName : '')}
                    onChange={onChange}
                    name={fieldName}
                    placeholder={placeholder}
                    style={style}
                    onBlur={onBlur}
                    readOnly={readOnly}
                />
                {error && <p className="mt-2 text-red-500 text-xs italic">{error}</p>}
            </div>
        </div>
    );
}

export default FileField;