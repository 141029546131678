import React from 'react';

import NumberFormat from 'react-number-format';

import SelectField from './select-field';

const startValues = [];
const endValues = [];

startValues.push({ id: `00:00`, label: `00:00` });
startValues.push({ id: `00:15`, label: `00:15` });
startValues.push({ id: `00:30`, label: `00:30` });
startValues.push({ id: `00:45`, label: `00:45` });

endValues.push({ id: `00:15`, label: `00:15` });
endValues.push({ id: `00:30`, label: `00:30` });
endValues.push({ id: `00:45`, label: `00:45` });

for (let i = 1; i < 24; i++) {
    const hour = ('0' + i).slice(-2);

    startValues.push({ id: `${hour}:00`, label: `${hour}:00` });
    startValues.push({ id: `${hour}:15`, label: `${hour}:15` });
    startValues.push({ id: `${hour}:30`, label: `${hour}:30` });
    startValues.push({ id: `${hour}:45`, label: `${hour}:45` });

    endValues.push({ id: `${hour}:00`, label: `${hour}:00` });
    endValues.push({ id: `${hour}:15`, label: `${hour}:15` });
    endValues.push({ id: `${hour}:30`, label: `${hour}:30` });
    endValues.push({ id: `${hour}:45`, label: `${hour}:45` });
}

endValues.push({ id: `23:59`, label: `23:59` });

const TimeRangeField = ({ label, onChange, fieldName, placeholder = '', value = { start: '', end: '' }, style = {}, maxLength, isOptional, error, className = 'field mb-4' }) => {

    function handleChangeFrom(e) {

        const from = e.target.value;

        onChange({
            start: from,
            end: value.end
        });
    }

    function handleChangeTo(e) {

        const to = e.target.value;

        onChange({
            start: value.start,
            end: to
        });
    }

    return (<div className="flex flex-row items-center">
        <SelectField
            onChange={handleChangeFrom}
            className={"flex-1" + (error ? 'is-danger' : '')}
            placeholder="08:00"
            value={(value && value.start) || ''}
            style={style}
            values={startValues}
        />
        <SelectField
            onChange={handleChangeTo}
            className={"flex-1 ml-2" + (error ? 'is-danger' : '')}
            placeholder="18:00"
            value={(value && value.end) || ''}
            style={style}
            values={endValues}
        />
        {error && <p className="help is-danger">
            {error}
        </p>}
    </div>);
}

export default TimeRangeField;