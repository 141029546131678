import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listCountries}`,
    {
        options: (props) => {

            return ({
                variables: {
                },
                fetchPolicy: 'cache-and-network',
            })
        },
        props: ({ data: { listCountries = {}, loading } }) => {

            const countries = listCountries;

            return {
                countries: countries,
                loading: loading
            }
        }
    }
)