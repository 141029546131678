import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import AuditLogItem from '../shared/_audit-log-item';

import Task from '../../task';

import { Button } from '../../components/ui/buttons';

import AuditLogFilter from './_audit-log-filter';


class List extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
        }
    }

    componentDidMount() {
    }

    select(item, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            if (prevState.selected.id == item.id) {

                return { selected: {} };
            }
            else {
                return {
                    selected: item
                };
            }

        });
    }

    onFilterChange(filter) {

        console.log({ filter: filter });

        var load_task = new Task();

        this.setState({ load_task: load_task });

        const data = {
            filter: filter,
        }

        this.props.refresh(data)
            .then(({ listAuditLogs }) => {

                console.log({ listAuditLogs: listAuditLogs });

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                console.log(err);

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    loadMore(token) {

        console.log({ loadMore: token });

        const data = {
            nextToken: token,
            filter: this.props.filter
        }

        var load_task = new Task();

        //this.setState({ load_task: load_task });

        this.props.next(data)
            .then(({ listAppointments }) => {

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    render() {

        const provider = this.props.provider;
        const auditLogs = this.props.auditLogs || {};

        const items = auditLogs.items || [];
        const nextToken = auditLogs.nextToken;

        return (<section className={this.props.loading ? 'loading-mask' : ''}>

            <AuditLogFilter {...this.props} onFilterChange={this.onFilterChange.bind(this)} />

            <div className="hidden sm:block font-semibold">
                <div className="px-4 py-4">

                    <div className="flex justify-between">

                        <div className="flex-1">
                            {I18n.get('Username')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Profile')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Activity')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Activity Data')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Result')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Activity Date')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                {items && items.map((item, index) => (
                    <div key={index}>
                        <AuditLogItem item={item} />
                    </div>)
                )}
            </div>

            <br />

            <div className="text-center">
                <Button task={this.state.load_task} onClick={(e) => this.loadMore(nextToken)} disabled={!nextToken} label={I18n.get('Load more')}></Button>
            </div>


        </section>);
    }
}

export default List;