import React from 'react';

import Spinner from './spinner';

import { CloseButtonIcon } from './icons';

export const Button = ({ label, onClick, task, disabled = false, style = {}, className = 'btn-primary' }) => {

    const busy = task && task.busy;

    return (<button type="button" onClick={onClick} className={className} disabled={disabled} style={style}>
        {!busy && <span>{label}</span>}
        {busy && <Spinner />}
    </button>);
}

export const ActionButton = ({ label, onClick, task, disabled = false, style = {}, className = 'btn-primary' }) => {

    const busy = task && task.busy;

    //disabled = disabled || (task && task.completed && task.result);

    return (<button type="submit" className={className} disabled={disabled}>
        {!busy && <span>{label}</span>}
        {busy && <Spinner />}
    </button>);
}


export const BackButton = ({ history }) => {

    return (<a onClick={(e) => history.goBack()} className="cursor-pointer">
        <CloseButtonIcon className="icon h-3 w-3" />
    </a>);
}

