import timezones from './domain/timezones';

const locales = [
  { id: "en", label: "EN" },
  { id: "pt", label: "PT" },
  // { id: "es", label: "ES" },
];


const profiles = [
      { id: 'Master', label: 'Master' },
      { id: 'System', label: 'System' },
      { id: 'MedicalProvider', label: 'MedicalProvider' },
      { id: 'TPA', label: 'TPA' },
      { id: 'Practitioner', label: 'Practitioner' },
];

const appointment = {
  statuses: [
      { id: 0, label: 'DRAFT' },
      { id: 1, label: 'REQUESTED' },
      { id: 2, label: 'ASSIGNED' },
      { id: 3, label: 'STARTED' },
      { id: 4, label: 'NOSHOW' },
      { id: 5, label: 'COMPLETED' },
      { id: 9, label: 'CANCELLED' },
  ],
  colors: [
    { id: 0, hex: '#cccccc' },
    { id: 1, hex: '#03BBD7' },
    { id: 2, hex: '#803694' },
    { id: 3, hex: '803694' },
    { id: 4, hex: '803694' },
    { id: 5, hex: '803694' },
    { id: 9, hex: '#FF8E8E' },
]  
}

appointment.statusMap = appointment.statuses.reduce((obj, item) => {
  obj[item.id] = item;
  return obj
}, {});

appointment.colorMap = appointment.colors.reduce((obj, item) => {
  obj[item.id] = item;
  return obj
}, {});

const medicalProvider = {
    statuses: [
        { id: 0, label: 'DISABLED' },
        { id: 1, label: 'ENABLED' },
        { id: 9, label: 'ARCHIVED' },
    ],
    preferredDueDates: [
        { id: 1, label: '01' },
        { id: 2, label: '02' },
        { id: 3, label: '03' },
        { id: 4, label: '04' },
        { id: 5, label: '05' },
        { id: 6, label: '06' },
        { id: 7, label: '07' },
        { id: 8, label: '08' },
        { id: 9, label: '09' },
        { id: 10, label: '10' },
        { id: 11, label: '11' },
        { id: 12, label: '12' },
        { id: 13, label: '13' },
        { id: 14, label: '14' },
        { id: 15, label: '15' },
        { id: 16, label: '16' },
        { id: 17, label: '17' },
        { id: 18, label: '18' },
        { id: 19, label: '19' },
        { id: 20, label: '20' },
        { id: 21, label: '21' },
        { id: 22, label: '22' },
        { id: 23, label: '23' },
        { id: 24, label: '24' },
        { id: 25, label: '25' },
        { id: 26, label: '26' },
        { id: 27, label: '27' },
        { id: 28, label: '28' },
    ]
}

medicalProvider.statusMap = medicalProvider.statuses.reduce((obj, item) => {
    obj[item.id] = item;
    return obj
}, {});

// for(let i=1; i<29; i++){
//     medicalProvider.preferredDueDates.push({ id: i, label: `${i}` })
// }

const US = {
    states: [
        { label: 'Alabama', id: 'AL' },
        { label: 'Alaska', id: 'AK' },
        { label: 'Arizona', id: 'AZ' },
        { label: 'Arkansas', id: 'AR' },
        { label: 'California', id: 'CA' },
        { label: 'Colorado', id: 'CO' },
        { label: 'Connecticut', id: 'CT' },
        { label: 'Delaware', id: 'DE' },
        { label: 'Florida', id: 'FL' },
        { label: 'Georgia', id: 'GA' },
        { label: 'Hawaii', id: 'HI' },
        { label: 'Idaho', id: 'ID' },
        { label: 'Illinois', id: 'IL' },
        { label: 'Indiana', id: 'IN' },
        { label: 'Iowa', id: 'IA' },
        { label: 'Kansas', id: 'KS' },
        { label: 'Kentucky', id: 'KY' },
        { label: 'Louisiana', id: 'LA' },
        { label: 'Maine', id: 'ME' },
        { label: 'Maryland', id: 'MD' },
        { label: 'Massachusetts', id: 'MA' },
        { label: 'Michigan', id: 'MI' },
        { label: 'Minnesota', id: 'MN' },
        { label: 'Mississippi', id: 'MS' },
        { label: 'Missouri', id: 'MO' },
        { label: 'Montana', id: 'MT' },
        { label: 'Nebraska', id: 'NE' },
        { label: 'Nevada', id: 'NV' },
        { label: 'New Hampshire', id: 'NH' },
        { label: 'New Jersey', id: 'NJ' },
        { label: 'New Mexico', id: 'NM' },
        { label: 'New York', id: 'NY' },
        { label: 'North Carolina', id: 'NC' },
        { label: 'North Dakota', id: 'ND' },
        { label: 'Ohio', id: 'OH' },
        { label: 'Oklahoma', id: 'OK' },
        { label: 'Oregon', id: 'OR' },
        { label: 'Pennsylvania', id: 'PA' },
        { label: 'Rhode Island', id: 'RI' },
        { label: 'South Carolina', id: 'SC' },
        { label: 'South Dakota', id: 'SD' },
        { label: 'Tennessee', id: 'TN' },
        { label: 'Texas', id: 'TX' },
        { label: 'Utah', id: 'UT' },
        { label: 'Vermont', id: 'VT' },
        { label: 'Virginia', id: 'VA' },
        { label: 'Washington', id: 'WA' },
        { label: 'West Virginia', id: 'WV' },
        { label: 'Wisconsin', id: 'WI' },
        { label: 'Wyoming', id: 'WY' }
    ]
}

const genders = [
    { id: "M", label: "Male" },
    { id: "F", label: "Female" },
];

const specialities = [
    { id: "1", label: "Pediatrics" },
    { id: "2", label: "Urgent Care" },
    { id: "3", label: "Primary Care" },
    { id: "4", label: "Preventive Care" },
    { id: "5", label: "Ob/Gyn" },
];

const symptoms = [
    { id: '1', label: 'Abdominal Pain' },
    { id: '2', label: 'Aches' },
    { id: '3', label: 'Anxiety' },
    { id: '4', label: 'Back or side pain' },
    { id: '5', label: 'Binge eating' },
    { id: '6', label: 'Bleeding' },
    { id: '7', label: 'Blister' },
    { id: '8', label: 'Bloating' },
    { id: '9', label: 'Blurred Vision' },
    { id: '10', label: 'Body aches and pains' },
    { id: '11', label: 'Bruising' },
    { id: '12', label: 'Bumps' },
    { id: '13', label: 'Chest pain' },
    { id: '14', label: 'Chills' },
    { id: '15', label: 'Confusion' },
    { id: '16', label: 'Constipation' },
    { id: '17', label: 'Cough' },
    { id: '18', label: 'Coughing blood' },
    { id: '19', label: 'Cramps' },
    { id: '20', label: 'Dehydration' },
    { id: '21', label: 'Diarrhea' },
    { id: '22', label: 'Difficulty concentration' },
    { id: '23', label: 'Difficulty Sleeping' },
    { id: '24', label: 'Discharge' },
    { id: '25', label: 'Dizziness' },
    { id: '26', label: 'Dry and/or Scaly skin' },
    { id: '27', label: 'Ear Pain' },
    { id: '28', label: 'Eye pain' },
    { id: '29', label: 'Fatigue' },
    { id: '30', label: 'Fever ' },
    { id: '31', label: 'Fluid Drainage from the ear' },
    { id: '32', label: 'Frequent and/or intense urge to urinate' },
    { id: '33', label: 'Hair Loss' },
    { id: '34', label: 'Headache ' },
    { id: '35', label: 'Hearing loss' },
    { id: '36', label: 'Heartburn' },
    { id: '37', label: 'Hives' },
    { id: '38', label: 'Hypersomnia' },
    { id: '39', label: 'Infammation' },
    { id: '40', label: 'Insomnia' },
    { id: '41', label: 'Irregular Period' },
    { id: '42', label: 'Irritability' },
    { id: '43', label: 'Itchy eyes' },
    { id: '44', label: 'Itchy nose' },
    { id: '45', label: 'Itchy skin' },
    { id: '46', label: 'Itchy troat' },
    { id: '47', label: 'Loss of appetite' },
    { id: '48', label: 'Lumps' },
    { id: '49', label: 'Mucus' },
    { id: '50', label: 'Muscle and Joint pain' },
    { id: '51', label: 'Nasal congestion ' },
    { id: '52', label: 'Nausea' },
    { id: '53', label: 'Night sweats' },
    { id: '54', label: 'Numbness' },
    { id: '55', label: 'Pain ' },
    { id: '56', label: 'Pain or burning during urination' },
    { id: '57', label: 'Pain with intercourse' },
    { id: '58', label: 'Rash' },
    { id: '59', label: 'Red eyes' },
    { id: '60', label: 'Redness' },
    { id: '61', label: 'Runny nose' },
    { id: '62', label: 'Shortness of breath' },
    { id: '63', label: 'Skin Rash and eruptions' },
    { id: '64', label: 'Sneezing' },
    { id: '65', label: 'Sore  ' },
    { id: '66', label: 'Sore throat ' },
    { id: '67', label: 'Sores' },
    { id: '68', label: 'Spasms' },
    { id: '69', label: 'Spots' },
    { id: '70', label: 'Stiff neck' },
    { id: '71', label: 'Stiffness' },
    { id: '72', label: 'Stuffy nose' },
    { id: '73', label: 'Swelling' },
    { id: '74', label: 'Swollen lymph nodes on the neck' },
    { id: '75', label: 'Swollen, red tonsils' },
    { id: '76', label: 'Tenderness' },
    { id: '77', label: 'Tinnitus' },
    { id: '78', label: 'Tooth pain' },
    { id: '79', label: 'Unpleasant urine odor' },
    { id: '80', label: 'Urine that is cloudy or red-tinged' },
    { id: '81', label: 'Vaginal itching' },
    { id: '82', label: 'Vomiting' },
    { id: '83', label: 'Weakness' },
    { id: '84', label: 'Weight gain' },
    { id: '85', label: 'Weight loss' },
    { id: '86', label: 'Wheezing' },
];

const weekAvailability = [
    { id: 0, label: 'MON' },
    { id: 1, label: 'TUE' },
    { id: 2, label: 'WED' },
    { id: 3, label: 'THU' },
    { id: 4, label: 'FRI' },
    { id: 5, label: 'SAT' },
    { id: 6, label: 'SUN' },
]

const countryCodes = [
    {
      "Name": "Afghanistan",
      "ISO": "af",
      "Code": "+93"
    },
    {
      "Name": "Albania",
      "ISO": "al",
      "Code": "+355"
    },
    {
      "Name": "Algeria",
      "ISO": "dz",
      "Code": "+213"
    },
    {
      "Name": "American Samoa",
      "ISO": "as",
      "Code": "+1684"
    },
    {
      "Name": "Andorra",
      "ISO": "ad",
      "Code": "+376"
    },
    {
      "Name": "Angola",
      "ISO": "ao",
      "Code": "+244"
    },
    {
      "Name": "Anguilla",
      "ISO": "ai",
      "Code": "+1264"
    },
    {
      "Name": "Antigua and Barbuda",
      "ISO": "ag",
      "Code": "+1268"
    },
    {
      "Name": "Argentina",
      "ISO": "ar",
      "Code": "+54"
    },
    {
      "Name": "Armenia",
      "ISO": "am",
      "Code": "+374"
    },
    {
      "Name": "Aruba",
      "ISO": "aw",
      "Code": "+297"
    },
    {
      "Name": "Australia",
      "ISO": "au",
      "Code": "+61"
    },
    {
      "Name": "Austria",
      "ISO": "at",
      "Code": "+43"
    },
    {
      "Name": "Azerbaijan",
      "ISO": "az",
      "Code": "+994"
    },
    {
      "Name": "Bahamas",
      "ISO": "bs",
      "Code": "+1242"
    },
    {
      "Name": "Bahrain",
      "ISO": "bh",
      "Code": "+973"
    },
    {
      "Name": "Bangladesh",
      "ISO": "bd",
      "Code": "+880"
    },
    {
      "Name": "Barbados",
      "ISO": "bb",
      "Code": "+1246"
    },
    {
      "Name": "Belarus",
      "ISO": "by",
      "Code": "+375"
    },
    {
      "Name": "Belgium",
      "ISO": "be",
      "Code": "+32"
    },
    {
      "Name": "Belize",
      "ISO": "bz",
      "Code": "+501"
    },
    {
      "Name": "Benin",
      "ISO": "bj",
      "Code": "+229"
    },
    {
      "Name": "Bermuda",
      "ISO": "bm",
      "Code": "+1441"
    },
    {
      "Name": "Bhutan",
      "ISO": "bt",
      "Code": "+975"
    },
    {
      "Name": "Bolivia",
      "ISO": "bo",
      "Code": "+591"
    },
    {
      "Name": "Bosnia and Herzegovina",
      "ISO": "ba",
      "Code": "+387"
    },
    {
      "Name": "Botswana",
      "ISO": "bw",
      "Code": "+267"
    },
    {
      "Name": "Brazil",
      "ISO": "br",
      "Code": "+55"
    },
    {
      "Name": "British Indian Ocean Territory",
      "ISO": "io",
      "Code": "+246"
    },
    {
      "Name": "British Virgin Islands",
      "ISO": "vg",
      "Code": "+1284"
    },
    {
      "Name": "Brunei",
      "ISO": "bn",
      "Code": "+673"
    },
    {
      "Name": "Bulgaria",
      "ISO": "bg",
      "Code": "+359"
    },
    {
      "Name": "Burkina Faso",
      "ISO": "bf",
      "Code": "+226"
    },
    {
      "Name": "Burundi",
      "ISO": "bi",
      "Code": "+257"
    },
    {
      "Name": "Cambodia",
      "ISO": "kh",
      "Code": "+855"
    },
    {
      "Name": "Cameroon",
      "ISO": "cm",
      "Code": "+237"
    },
    {
      "Name": "Canada",
      "ISO": "ca",
      "Code": "+1"
    },
    {
      "Name": "Cape Verde",
      "ISO": "cv",
      "Code": "+238"
    },
    {
      "Name": "Caribbean Netherlands",
      "ISO": "bq",
      "Code": "+599"
    },
    {
      "Name": "Cayman Islands",
      "ISO": "ky",
      "Code": "+1345"
    },
    {
      "Name": "Central African Republic",
      "ISO": "cf",
      "Code": "+236"
    },
    {
      "Name": "Chad",
      "ISO": "td",
      "Code": "+235"
    },
    {
      "Name": "Chile",
      "ISO": "cl",
      "Code": "+56"
    },
    {
      "Name": "China",
      "ISO": "cn",
      "Code": "+86"
    },
    {
      "Name": "Colombia",
      "ISO": "co",
      "Code": "+57"
    },
    {
      "Name": "Comoros",
      "ISO": "km",
      "Code": "+269"
    },
    {
      "Name": "Congo (DRC)",
      "ISO": "cd",
      "Code": "+243"
    },
    {
      "Name": "Congo (Republic)",
      "ISO": "cg",
      "Code": "+242"
    },
    {
      "Name": "Cook Islands",
      "ISO": "ck",
      "Code": "+682"
    },
    {
      "Name": "Costa Rica",
      "ISO": "cr",
      "Code": "+506"
    },
    {
      "Name": "CÃ´te dâ€™Ivoire",
      "ISO": "ci",
      "Code": "+225"
    },
    {
      "Name": "Croatia",
      "ISO": "hr",
      "Code": "+385"
    },
    {
      "Name": "Cuba",
      "ISO": "cu",
      "Code": "+53"
    },
    {
      "Name": "CuraÃ§ao",
      "ISO": "cw",
      "Code": "+599"
    },
    {
      "Name": "Cyprus",
      "ISO": "cy",
      "Code": "+357"
    },
    {
      "Name": "Czech Republic",
      "ISO": "cz",
      "Code": "+420"
    },
    {
      "Name": "Denmark",
      "ISO": "dk",
      "Code": "+45"
    },
    {
      "Name": "Djibouti",
      "ISO": "dj",
      "Code": "+253"
    },
    {
      "Name": "Dominica",
      "ISO": "dm",
      "Code": "+1767"
    },
    {
      "Name": "Dominican Republic",
      "ISO": "do",
      "Code": "+1"
    },
    {
      "Name": "Ecuador",
      "ISO": "ec",
      "Code": "+593"
    },
    {
      "Name": "Egypt",
      "ISO": "eg",
      "Code": "+20"
    },
    {
      "Name": "El Salvador",
      "ISO": "sv",
      "Code": "+503"
    },
    {
      "Name": "Equatorial Guinea",
      "ISO": "gq",
      "Code": "+240"
    },
    {
      "Name": "Eritrea",
      "ISO": "er",
      "Code": "+291"
    },
    {
      "Name": "Estonia",
      "ISO": "ee",
      "Code": "+372"
    },
    {
      "Name": "Ethiopia",
      "ISO": "et",
      "Code": "+251"
    },
    {
      "Name": "Falkland Islands",
      "ISO": "fk",
      "Code": "+500"
    },
    {
      "Name": "Faroe Islands",
      "ISO": "fo",
      "Code": "+298"
    },
    {
      "Name": "Fiji",
      "ISO": "fj",
      "Code": "+679"
    },
    {
      "Name": "Finland",
      "ISO": "fi",
      "Code": "+358"
    },
    {
      "Name": "France",
      "ISO": "fr",
      "Code": "+33"
    },
    {
      "Name": "French Guiana",
      "ISO": "gf",
      "Code": "+594"
    },
    {
      "Name": "French Polynesia",
      "ISO": "pf",
      "Code": "+689"
    },
    {
      "Name": "Gabon",
      "ISO": "ga",
      "Code": "+241"
    },
    {
      "Name": "Gambia",
      "ISO": "gm",
      "Code": "+220"
    },
    {
      "Name": "Georgia",
      "ISO": "ge",
      "Code": "+995"
    },
    {
      "Name": "Germany",
      "ISO": "de",
      "Code": "+49"
    },
    {
      "Name": "Ghana",
      "ISO": "gh",
      "Code": "+233"
    },
    {
      "Name": "Gibraltar",
      "ISO": "gi",
      "Code": "+350"
    },
    {
      "Name": "Greece",
      "ISO": "gr",
      "Code": "+30"
    },
    {
      "Name": "Greenland",
      "ISO": "gl",
      "Code": "+299"
    },
    {
      "Name": "Grenada",
      "ISO": "gd",
      "Code": "+1473"
    },
    {
      "Name": "Guadeloupe",
      "ISO": "gp",
      "Code": "+590"
    },
    {
      "Name": "Guam",
      "ISO": "gu",
      "Code": "+1671"
    },
    {
      "Name": "Guatemala",
      "ISO": "gt",
      "Code": "+502"
    },
    {
      "Name": "Guinea",
      "ISO": "gn",
      "Code": "+224"
    },
    {
      "Name": "Guinea-Bissau",
      "ISO": "gw",
      "Code": "+245"
    },
    {
      "Name": "Guyana",
      "ISO": "gy",
      "Code": "+592"
    },
    {
      "Name": "Haiti",
      "ISO": "ht",
      "Code": "+509"
    },
    {
      "Name": "Honduras",
      "ISO": "hn",
      "Code": "+504"
    },
    {
      "Name": "Hong Kong",
      "ISO": "hk",
      "Code": "+852"
    },
    {
      "Name": "Hungary",
      "ISO": "hu",
      "Code": "+36"
    },
    {
      "Name": "Iceland",
      "ISO": "is",
      "Code": "+354"
    },
    {
      "Name": "India",
      "ISO": "in",
      "Code": "+91"
    },
    {
      "Name": "Indonesia",
      "ISO": "id",
      "Code": "+62"
    },
    {
      "Name": "Iran",
      "ISO": "ir",
      "Code": "+98"
    },
    {
      "Name": "Iraq",
      "ISO": "iq",
      "Code": "+964"
    },
    {
      "Name": "Ireland",
      "ISO": "ie",
      "Code": "+353"
    },
    {
      "Name": "Israel",
      "ISO": "il",
      "Code": "+972"
    },
    {
      "Name": "Italy",
      "ISO": "it",
      "Code": "+39"
    },
    {
      "Name": "Jamaica",
      "ISO": "jm",
      "Code": "+1876"
    },
    {
      "Name": "Japan",
      "ISO": "jp",
      "Code": "+81"
    },
    {
      "Name": "Jordan",
      "ISO": "jo",
      "Code": "+962"
    },
    {
      "Name": "Kazakhstan",
      "ISO": "kz",
      "Code": "+7"
    },
    {
      "Name": "Kenya",
      "ISO": "ke",
      "Code": "+254"
    },
    {
      "Name": "Kiribati",
      "ISO": "ki",
      "Code": "+686"
    },
    {
      "Name": "Kuwait",
      "ISO": "kw",
      "Code": "+965"
    },
    {
      "Name": "Kyrgyzstan",
      "ISO": "kg",
      "Code": "+996"
    },
    {
      "Name": "Laos",
      "ISO": "la",
      "Code": "+856"
    },
    {
      "Name": "Latvia",
      "ISO": "lv",
      "Code": "+371"
    },
    {
      "Name": "Lebanon",
      "ISO": "lb",
      "Code": "+961"
    },
    {
      "Name": "Lesotho",
      "ISO": "ls",
      "Code": "+266"
    },
    {
      "Name": "Liberia",
      "ISO": "lr",
      "Code": "+231"
    },
    {
      "Name": "Libya",
      "ISO": "ly",
      "Code": "+218"
    },
    {
      "Name": "Liechtenstein",
      "ISO": "li",
      "Code": "+423"
    },
    {
      "Name": "Lithuania",
      "ISO": "lt",
      "Code": "+370"
    },
    {
      "Name": "Luxembourg",
      "ISO": "lu",
      "Code": "+352"
    },
    {
      "Name": "Macau",
      "ISO": "mo",
      "Code": "+853"
    },
    {
      "Name": "Macedonia",
      "ISO": "mk",
      "Code": "+389"
    },
    {
      "Name": "Madagascar",
      "ISO": "mg",
      "Code": "+261"
    },
    {
      "Name": "Malawi",
      "ISO": "mw",
      "Code": "+265"
    },
    {
      "Name": "Malaysia",
      "ISO": "my",
      "Code": "+60"
    },
    {
      "Name": "Maldives",
      "ISO": "mv",
      "Code": "+960"
    },
    {
      "Name": "Mali",
      "ISO": "ml",
      "Code": "+223"
    },
    {
      "Name": "Malta",
      "ISO": "mt",
      "Code": "+356"
    },
    {
      "Name": "Marshall Islands",
      "ISO": "mh",
      "Code": "+692"
    },
    {
      "Name": "Martinique",
      "ISO": "mq",
      "Code": "+596"
    },
    {
      "Name": "Mauritania",
      "ISO": "mr",
      "Code": "+222"
    },
    {
      "Name": "Mauritius",
      "ISO": "mu",
      "Code": "+230"
    },
    {
      "Name": "Mexico",
      "ISO": "mx",
      "Code": "+52"
    },
    {
      "Name": "Micronesia",
      "ISO": "fm",
      "Code": "+691"
    },
    {
      "Name": "Moldova",
      "ISO": "md",
      "Code": "+373"
    },
    {
      "Name": "Monaco",
      "ISO": "mc",
      "Code": "+377"
    },
    {
      "Name": "Mongolia",
      "ISO": "mn",
      "Code": "+976"
    },
    {
      "Name": "Montenegro",
      "ISO": "me",
      "Code": "+382"
    },
    {
      "Name": "Montserrat",
      "ISO": "ms",
      "Code": "+1664"
    },
    {
      "Name": "Morocco",
      "ISO": "ma",
      "Code": "+212"
    },
    {
      "Name": "Mozambique",
      "ISO": "mz",
      "Code": "+258"
    },
    {
      "Name": "Myanmar",
      "ISO": "mm",
      "Code": "+95"
    },
    {
      "Name": "Namibia",
      "ISO": "na",
      "Code": "+264"
    },
    {
      "Name": "Nauru",
      "ISO": "nr",
      "Code": "+674"
    },
    {
      "Name": "Nepal",
      "ISO": "np",
      "Code": "+977"
    },
    {
      "Name": "Netherlands",
      "ISO": "nl",
      "Code": "+31"
    },
    {
      "Name": "New Caledonia",
      "ISO": "nc",
      "Code": "+687"
    },
    {
      "Name": "New Zealand",
      "ISO": "nz",
      "Code": "+64"
    },
    {
      "Name": "Nicaragua",
      "ISO": "ni",
      "Code": "+505"
    },
    {
      "Name": "Niger",
      "ISO": "ne",
      "Code": "+227"
    },
    {
      "Name": "Nigeria",
      "ISO": "ng",
      "Code": "+234"
    },
    {
      "Name": "Niue",
      "ISO": "nu",
      "Code": "+683"
    },
    {
      "Name": "Norfolk Island",
      "ISO": "nf",
      "Code": "+672"
    },
    {
      "Name": "North Korea",
      "ISO": "kp",
      "Code": "+850"
    },
    {
      "Name": "Northern Mariana Islands",
      "ISO": "mp",
      "Code": "+1670"
    },
    {
      "Name": "Norway",
      "ISO": "no",
      "Code": "+47"
    },
    {
      "Name": "Oman",
      "ISO": "om",
      "Code": "+968"
    },
    {
      "Name": "Pakistan",
      "ISO": "pk",
      "Code": "+92"
    },
    {
      "Name": "Palau",
      "ISO": "pw",
      "Code": "+680"
    },
    {
      "Name": "Palestine",
      "ISO": "ps",
      "Code": "+970"
    },
    {
      "Name": "Panama",
      "ISO": "pa",
      "Code": "+507"
    },
    {
      "Name": "Papua New Guinea",
      "ISO": "pg",
      "Code": "+675"
    },
    {
      "Name": "Paraguay",
      "ISO": "py",
      "Code": "+595"
    },
    {
      "Name": "Peru",
      "ISO": "pe",
      "Code": "+51"
    },
    {
      "Name": "Philippines",
      "ISO": "ph",
      "Code": "+63"
    },
    {
      "Name": "Poland",
      "ISO": "pl",
      "Code": "+48"
    },
    {
      "Name": "Portugal",
      "ISO": "pt",
      "Code": "+351"
    },
    {
      "Name": "Puerto Rico",
      "ISO": "pr",
      "Code": "+1"
    },
    {
      "Name": "Qatar",
      "ISO": "qa",
      "Code": "+974"
    },
    {
      "Name": "RÃ©union",
      "ISO": "re",
      "Code": "+262"
    },
    {
      "Name": "Romania",
      "ISO": "ro",
      "Code": "+40"
    },
    {
      "Name": "Russia",
      "ISO": "ru",
      "Code": "+7"
    },
    {
      "Name": "Rwanda",
      "ISO": "rw",
      "Code": "+250"
    },
    {
      "Name": "Saint BarthÃ©lemy",
      "ISO": "bl",
      "Code": "+590"
    },
    {
      "Name": "Saint Helena",
      "ISO": "sh",
      "Code": "+290"
    },
    {
      "Name": "Saint Kitts and Nevis",
      "ISO": "kn",
      "Code": "+1869"
    },
    {
      "Name": "Saint Lucia",
      "ISO": "lc",
      "Code": "+1758"
    },
    {
      "Name": "Saint Martin",
      "ISO": "mf",
      "Code": "+590"
    },
    {
      "Name": "Saint Pierre and Miquelon",
      "ISO": "pm",
      "Code": "+508"
    },
    {
      "Name": "Saint Vincent and the Grenadines",
      "ISO": "vc",
      "Code": "+1784"
    },
    {
      "Name": "Samoa",
      "ISO": "ws",
      "Code": "+685"
    },
    {
      "Name": "San Marino",
      "ISO": "sm",
      "Code": "+378"
    },
    {
      "Name": "SÃ£o TomÃ© and PrÃ­ncipe",
      "ISO": "st",
      "Code": "+239"
    },
    {
      "Name": "Saudi Arabia",
      "ISO": "sa",
      "Code": "+966"
    },
    {
      "Name": "Senegal",
      "ISO": "sn",
      "Code": "+221"
    },
    {
      "Name": "Serbia",
      "ISO": "rs",
      "Code": "+381"
    },
    {
      "Name": "Seychelles",
      "ISO": "sc",
      "Code": "+248"
    },
    {
      "Name": "Sierra Leone",
      "ISO": "sl",
      "Code": "+232"
    },
    {
      "Name": "Singapore",
      "ISO": "sg",
      "Code": "+65"
    },
    {
      "Name": "Sint Maarten",
      "ISO": "sx",
      "Code": "+1721"
    },
    {
      "Name": "Slovakia",
      "ISO": "sk",
      "Code": "+421"
    },
    {
      "Name": "Slovenia",
      "ISO": "si",
      "Code": "+386"
    },
    {
      "Name": "Solomon Islands",
      "ISO": "sb",
      "Code": "+677"
    },
    {
      "Name": "Somalia",
      "ISO": "so",
      "Code": "+252"
    },
    {
      "Name": "South Africa",
      "ISO": "za",
      "Code": "+27"
    },
    {
      "Name": "South Korea",
      "ISO": "kr",
      "Code": "+82"
    },
    {
      "Name": "South Sudan",
      "ISO": "ss",
      "Code": "+211"
    },
    {
      "Name": "Spain",
      "ISO": "es",
      "Code": "+34"
    },
    {
      "Name": "Sri Lanka",
      "ISO": "lk",
      "Code": "+94"
    },
    {
      "Name": "Sudan",
      "ISO": "sd",
      "Code": "+249"
    },
    {
      "Name": "Suriname",
      "ISO": "sr",
      "Code": "+597"
    },
    {
      "Name": "Swaziland",
      "ISO": "sz",
      "Code": "+268"
    },
    {
      "Name": "Sweden",
      "ISO": "se",
      "Code": "+46"
    },
    {
      "Name": "Switzerland",
      "ISO": "ch",
      "Code": "+41"
    },
    {
      "Name": "Syria",
      "ISO": "sy",
      "Code": "+963"
    },
    {
      "Name": "Taiwan",
      "ISO": "tw",
      "Code": "+886"
    },
    {
      "Name": "Tajikistan",
      "ISO": "tj",
      "Code": "+992"
    },
    {
      "Name": "Tanzania",
      "ISO": "tz",
      "Code": "+255"
    },
    {
      "Name": "Thailand",
      "ISO": "th",
      "Code": "+66"
    },
    {
      "Name": "Timor-Leste",
      "ISO": "tl",
      "Code": "+670"
    },
    {
      "Name": "Togo",
      "ISO": "tg",
      "Code": "+228"
    },
    {
      "Name": "Tokelau",
      "ISO": "tk",
      "Code": "+690"
    },
    {
      "Name": "Tonga",
      "ISO": "to",
      "Code": "+676"
    },
    {
      "Name": "Trinidad and Tobago",
      "ISO": "tt",
      "Code": "+1868"
    },
    {
      "Name": "Tunisia",
      "ISO": "tn",
      "Code": "+216"
    },
    {
      "Name": "Turkey",
      "ISO": "tr",
      "Code": "+90"
    },
    {
      "Name": "Turkmenistan",
      "ISO": "tm",
      "Code": "+993"
    },
    {
      "Name": "Turks and Caicos Islands",
      "ISO": "tc",
      "Code": "+1649"
    },
    {
      "Name": "Tuvalu",
      "ISO": "tv",
      "Code": "+688"
    },
    {
      "Name": "U.S. Virgin Islands",
      "ISO": "vi",
      "Code": "+1340"
    },
    {
      "Name": "Uganda",
      "ISO": "ug",
      "Code": "+256"
    },
    {
      "Name": "Ukraine",
      "ISO": "ua",
      "Code": "+380"
    },
    {
      "Name": "United Arab Emirates",
      "ISO": "ae",
      "Code": "+971"
    },
    {
      "Name": "United Kingdom",
      "ISO": "gb",
      "Code": "+44"
    },
    {
      "Name": "United States",
      "ISO": "us",
      "Code": "+1"
    },
    {
      "Name": "Uruguay",
      "ISO": "uy",
      "Code": "+598"
    },
    {
      "Name": "Uzbekistan",
      "ISO": "uz",
      "Code": "+998"
    },
    {
      "Name": "Vanuatu",
      "ISO": "vu",
      "Code": "+678"
    },
    {
      "Name": "Vatican City",
      "ISO": "va",
      "Code": "+39"
    },
    {
      "Name": "Venezuela",
      "ISO": "ve",
      "Code": "+58"
    },
    {
      "Name": "Vietnam",
      "ISO": "vn",
      "Code": "+84"
    },
    {
      "Name": "Wallis and Futuna",
      "ISO": "wf",
      "Code": "+681"
    },
    {
      "Name": "Yemen",
      "ISO": "ye",
      "Code": "+967"
    },
    {
      "Name": "Zambia",
      "ISO": "zm",
      "Code": "+260"
    },
    {
      "Name": "Zimbabwe",
      "ISO": "zw",
      "Code": "+263"
    }
  ];


const domain = {
    appointment: appointment,
    medicalProvider: medicalProvider,
    US: US,
    specialities: specialities,
    symptoms: symptoms,
    genders: genders,
    weekAvailability: weekAvailability,
    countryCodes: countryCodes,
    timezones: timezones,
    profiles: profiles,
    locales: locales
}

domain.specialityMap = domain.specialities.reduce((obj, item) => {
    obj[item.id] = item;
    return obj
}, {});

domain.symptomMap = domain.symptoms.reduce((obj, item) => {
    obj[item.id] = item;
    return obj
}, {});


domain.genderMap = domain.genders.reduce((obj, item) => {
    obj[item.id] = item;
    return obj
}, {});


domain.genderMap = domain.genders.reduce((obj, item) => {
  obj[item.id] = item;
  return obj
}, {});


export default domain;
