import { I18n } from 'aws-amplify';

class Localization {

    locale = 'en';
    
    constructor() {
        
        const vocabulary = require('./vocabulary.json');

        const cache = localStorage.getItem('LOCALE') || this.locale;

        console.log({ locale: cache });

        I18n.putVocabularies(vocabulary);

        I18n.setLanguage(cache);
    }

    async setLocale(locale) {

        console.log({'locale update': locale});

        localStorage.setItem('LOCALE', locale);

        this.locale = locale;

        I18n.setLanguage(locale);
    }

}

const localizationService = new Localization();

export default localizationService;