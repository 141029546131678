import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';
import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import TPAList from './_tpa-list';

import SearchField from '../../components/form/search-field';

import { TpaRegularIcon } from '../../components/ui/icons';

const TPAListWithData = CORE_API.list_TPAsByProvider(TPAList);

class TPAsPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            search: ''
        };
    }

    componentDidMount() {

    }

    onChangeSearch(value) {

        this.setState({ search: value });
    }

    getFilter(parameters) {

        const filter = {};

        if (parameters.search) {
            filter.q = { contains: parameters.search.toLowerCase() }
        }

        return filter;
    }

    render() {

        const provider = this.props.provider;

        const filter = this.getFilter(this.state);

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <TpaRegularIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('TPAs')}
                </div>

                <div className="flex-grow flex items-center justify-end">
                    <NavLink to={`/${provider.publicURL}/tpas/new`} className="btn-primary mr-8">
                        {I18n.get('Create TPA')}
                    </NavLink>
                </div>

                <SearchField onChangeText={this.onChangeSearch.bind(this)} value={this.state.search} />


            </div>



            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {provider.id && <TPAListWithData providerId={provider.id} {...this.props} filter={filter} />}

            </div>

        </ProviderLayout>);
    }

}

export default ProviderContainer(TPAsPage);

