import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import PractitionerGroupItem from '../shared/_practitioner-group-item';

class PractitionerList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
        }
    }

    componentDidMount() {

    }

    getSpeciality(id) {
        return domain.specialityMap[id] || { label: '-' };
    }

    selectPractitioner(item, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            if (prevState.selected.id == item.id) {

                return { selected: {} };
            }
            else {
                return {
                    selected: {
                        ...item,
                        tpas: JSON.parse(item.tpas || '[]'),
                        groups: JSON.parse(item.groups || '[]')
                    }
                };
            }

        });
    }

    render() {

        const provider = this.props.provider;

        return (<section>

            <div className="hidden sm:block font-semibold">
                <div className="px-4 py-4">

                    <div className="flex justify-between">

                        <div className="flex-1">
                            {I18n.get('Group')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Created')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Updated')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                {provider.groups && provider.groups.items.map((item, index) => (<div key={index}>

                    <NavLink to={`/${provider.publicURL}/groups/${item.id}/details`}>
                        <PractitionerGroupItem key={index} item={item} />
                    </NavLink>

                </div>))}
            </div>

        </section>);
    }
}

export default PractitionerList;