import React from 'react'
import { I18n } from 'aws-amplify';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

import moment from 'moment';

import './_calendar.scss'

export default class DemoApp extends React.Component {

    calendarComponentRef = React.createRef()
    state = {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.defaultView != prevProps.defaultView) {

            let calendarApi = this.calendarComponentRef.current.getApi()

            calendarApi.changeView(this.props.defaultView);
        }

    }

    render() {

        const events = [];

        this.props.calendar && Object.keys(this.props.calendar).map((ts) => {

            const day = this.props.calendar[ts];

            day.map((item) => {

                events.push({ id: item.id, start: item.scheduledDate, end: item.scheduledDate, title: `${item.name}` });
            });
        });

        console.log({ events: events });

        return (
            <FullCalendar
                defaultView={this.props.defaultView}
                height='parent'
                header={{
                    left: 'prev',
                    center: 'title',
                    right: 'next',
                }}
                footer={{
                    center: 'today'
                }}
                timeZone={this.props.timezoneId}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                ref={this.calendarComponentRef}
                events={events}
                dateClick={this.handleDateClick}
                eventClick={this.props.selectEvent}
            />
        )
    }

    gotoPast = () => {
        let calendarApi = this.calendarComponentRef.current.getApi()
        calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
    }

    handleDateClick = (arg) => {

        if (this.props.selectDate) {
            this.props.selectDate(arg.dateStr);
        }
    }

}