import React from 'react';

const DetailsItem = ({ label, value , className }) => {

    return (
        <div className={'flex mt-2 ' + className}>
            <div className="flex-none w-2/5 tracking-wide text-gray-label">
                {label}
            </div>
            <div className="flex-grow text-sm rounded" style={{ color: '#959597' }}>
                {value || '-'}
            </div>
        </div>
    )
}

export default DetailsItem;