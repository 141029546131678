import React from 'react';

import TimeAgo from 'timeago-react';

import localizationService from '../localization';

import * as timeago from 'timeago.js';

var pt = function (number, index, totalSec) {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        ['agora mesmo', 'agora'],
        ['há %s segundos', 'em %s segundos'],
        ['há um minuto', 'em um minuto'],
        ['há %s minutos', 'em %s minutos'],
        ['há uma hora', 'em uma hora'],
        ['há %s horas', 'em %s horas'],
        ['há um dia', 'em um dia'],
        ['há %s dias', 'em %s dias'],
        ['há uma semana', 'em uma semana'],
        ['há %s semanas', 'em %s semanas'],
        ['há um mês', 'em um mês'],
        ['há %s meses', 'em %s meses'],
        ['há um ano', 'em um ano'],
        ['há %s anos', 'em %s anos'],
    ][index]
};
timeago.register('pt', pt);

class TimeAgoFmt extends React.Component {
    render() {
        return <TimeAgo
            datetime={this.props.v}
            locale={localizationService.locale}
        />;
    }
}

const TimeAgoFmt2 = ({ v, className = '' }) => {

    //const fmt = timeago.format(v, localizationService.locale);

    return (
        <TimeAgo
            datetime={v}
            locale={localizationService.locale}
        />
        // <span className={className}>
        //     {fmt}
        // </span>
    )
}

export default TimeAgoFmt;