import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import ProviderContainer from '../../containers/provider-container';

import ProviderLayout from '../shared/layout';

import ToastContainer from '../shared/toast-container';

import ReportList from '../shared/_report-list';

import { ReportIcon } from '../../components/ui/icons';

const ReportListWithData = CORE_API.list_appointments(ReportList);

class ReportPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <ReportIcon className="mr-3 opacity-50" />
                    {I18n.get('Report')}
                </div>

                <div className="flex-grow flex items-center justify-end">
                </div>

            </div>


            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {this.props.providerId && <ReportListWithData  {...this.props} />}

            </div>

        </ProviderLayout>);
    }

}

export default ProviderContainer(CORE_API.list_Practitioners(CORE_API.list_TPAsByProvider((ReportPage))));

