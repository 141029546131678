import { Auth } from 'aws-amplify';

import Task from '../../task';

import { AUTH_UPDATE } from './actions';

export const api_call = (request) => {
    return (dispatch, getState) => {

        return Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, request.current, request.password);
            })
    }

}

export default api_call;