import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.createTpa}`,
    {
        options: {
        },
        props: (props) => ({
            create_tpa: (input) => {

                if (typeof (input.groups) !== 'string') {
                    input.groups = JSON.stringify(input.groups);
                }

                return props.mutate({
                    variables: { input: input }
                })
                    .then(({ data }) => {

                        const tpa = data.createTPA;

                        if (typeof (tpa.groups) === 'string') {
                            tpa.groups = JSON.parse(tpa.groups);
                        }

                        return {
                            tpa: tpa
                        }
                    })
            }
        })
    })

