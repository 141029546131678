import moment from 'moment';

import domain from './domain';

import { AppointmentStatus } from './model';
import localizationService from './localization';

export function age(value) {

    if (!value) return null;

    const dob = moment(value).toDate();

    var today = new Date();
    var age = today.getFullYear() - dob.getFullYear();
    var m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age = age - 1;
    }

    return age;
}

export function formatDateTime(value) {

    if (!value) return null;

    return moment(value).format('DD/MM/YYYY HH:mm');
}

export function formatDob(value) {

    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
}

export function formatDate(value, timezoneId) {

    if (!value) return null;

    const local = moment.tz(value, timezoneId);

    let fmt = '';

    switch(localizationService.locale){
        case 'pt':
            fmt = 'DD/MM/YYYY';
            break;
        default:
            fmt = 'MMM Do YYYY';
            break;
    }
   
    const date = local.format(fmt);

    return date;
}

export function formatTime(value, timezoneId) {

    //console.log({value: value, timezoneId: timezoneId});

    if (!value) return null;

    const local = moment.tz(value, timezoneId);

    const time = local.format('HH:mm');

    return time;
}

export function getSymptom(id) {
    return domain.symptomMap[id] || {};
}

export function getGender(id) {
    return domain.genderMap[id] || {};
}

export function getSpeciality(id) {
    return domain.specialityMap[id] || { label: '-' };
}

export function createCalendar(appointments) {

    const calendar = {};

    appointments && appointments.map((item) => {

        if (item.status != AppointmentStatus.Cancelled) {

            const scheduledDate = moment(item.scheduledDate);

            const key = scheduledDate.format('YYYY-MM-DD');

            calendar[key] = calendar[key] || [];

            calendar[key].push(item);
        }
    });

    return calendar;
}


export function normalizeAddress(result) {
    var street_number = result.address_components.find((item) => item.types[0] == 'street_number') || { short_name: '' };
    var route = result.address_components.find((item) => item.types[0] == 'route');
    var city = result.address_components.find((item) => item.types[0] == 'locality') || result.address_components.find((item) => item.types[0] == 'administrative_area_level_2');
    var state = result.address_components.find((item) => item.types[0] == 'administrative_area_level_1');
    var country = result.address_components.find((item) => item.types[0] == 'country');
    var zipcode = result.address_components.find((item) => item.types[0] == 'postal_code') || { short_name: '' };

    return {
        'address1': `${street_number['short_name']} ${route['long_name']}`,
        'city': city && city['long_name'],
        'state': state['short_name'],
        'zipcode': zipcode['short_name'],
        'countryId': country['short_name'],
        'lat': result['geometry']['location'].lat(),
        'lng': result['geometry']['location'].lng(),
    }
}

export function getCountryCodes() {
    return domain.countryCodes.map((item) => {
        return { id: item.Code, label: `${item.Name}`, data: item }
    })
}