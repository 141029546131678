import React from 'react';
import { I18n } from 'aws-amplify';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';

import { CORE_API } from '../../actions/core/actions';

import PractitionerContainer from '../../containers/practitioner-container';

import PractitionerLayout from '../shared/layout';

import Modal from '../../components/modal';

import AppointmentDetails from '../shared/appointment-details';

import Calendar from '../shared/_calendar';

import { CalendarRegularIcon } from '../../components/ui/icons';

const AppointmentDetailsWithData = CORE_API.get_appointment(AppointmentDetails);

const CalendarWithData = CORE_API.listAppointmentsByPractitioner(Calendar);

class PractitionerDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
            appointmentId: '',
            view: 'dayGridMonth'
        }
    }

    componentDidMount() {
    }

    selectEvent(item, e) {

        if (e) e.preventDefault();

        if (item && item.event) {
            this.setState({ appointmentId: item.event.id });
        }
        else {
            this.setState({ appointmentId: '' });
        }
    }

    onChangeView(value, e) {
        this.setState({ view: value });
    }

    render() {

        const practitioner = this.props.practitioner || {};

        const dayGridMonthClassName = this.state.view == 'dayGridMonth' ? 'active' : '';
        const listWeekClassName = this.state.view == 'listWeek' ? 'active' : '';

        return (
            <PractitionerLayout {...this.props}>

                <div className="page-header">

                    <div className="title">
                        <CalendarRegularIcon className="icon md mr-3 opacity-50" />
                        {I18n.get('Calendar')}
                    </div>

                    <div className="bar">

                        <div className="inline-flex">
                            <button onClick={this.onChangeView.bind(this, 'dayGridMonth')} className={'btn-group rounded-l hover:opacity-50 ' + dayGridMonthClassName}>
                                {I18n.get('Month')}
                            </button>
                            <button onClick={this.onChangeView.bind(this, 'listWeek')} className={'btn-group rounded-r hover:opacity-50 ' + listWeekClassName}>
                                {I18n.get('Week')}
                            </button>
                        </div>

                    </div>

                </div>

                <div className="page-body" style={{ minHeight: '640px' }}>

                    <CalendarWithData practitionerId={practitioner.id} timezoneId={practitioner.timezoneId} selectEvent={this.selectEvent.bind(this)} defaultView={this.state.view} />

                </div>

                <Modal isOpen={this.state.appointmentId}>

                    <AppointmentDetailsWithData {...this.props} appointmentId={this.state.appointmentId} select={this.selectEvent.bind(this)} />

                </Modal>

            </PractitionerLayout>
        );
    }

}

export default PractitionerContainer(PractitionerDetails);