import { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';

const api_call = ({ email, code, password }) => {
    return async (dispatch, getState) => {

        return Auth.forgotPasswordSubmit(email, code, password)
            .then((data) => {

                const payload = {
                    task: {
                        result: true
                    },
                    errors: {}
                }

                dispatch({
                    type: 'AUTH_RESET_PASSWORD',
                    payload: payload
                });

                return payload;
            })
            .catch(err => {

                console.log({err22: err});

                const payload = {
                    task: {
                        result: false,
                        error: {
                            message: I18n.get(err.code || err.message)
                        }
                    },
                    errors: {}
                }

                dispatch({
                    type: 'AUTH_RESET_PASSWORD',
                    payload: payload
                });

                return payload;

            });
    }
}

export default api_call;