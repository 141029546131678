import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import ProviderContainer from '../../containers/provider-container';

import ProviderLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';


import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import SelectField from '../../components/form/select-field';
import FileField from '../../components/form/file-field';

import { SettingsIcon } from '../../components/ui/icons';

class SettingsPage extends React.Component {

    constructor(props) {
        super();

        const provider = props.provider || {};

        const settings = provider.settings || {};

        console.log({ settings: settings });

        const minuteValues = [
            { id: 0, label: I18n.get('00 min') },
            { id: 1, label: I18n.get('01 min') },
            { id: 2, label: I18n.get('02 min') },
            { id: 3, label: I18n.get('03 min') },
            { id: 4, label: I18n.get('04 min') },
            { id: 5, label: I18n.get('05 min') },
            { id: 6, label: I18n.get('06 min') },
            { id: 7, label: I18n.get('07 min') },
            { id: 8, label: I18n.get('08 min') },
            { id: 9, label: I18n.get('09 min') },
            { id: 10, label: I18n.get('10 min') },
            { id: 11, label: I18n.get('11 min') },
            { id: 12, label: I18n.get('12 min') },
            { id: 13, label: I18n.get('13 min') },
            { id: 14, label: I18n.get('14 min') },
            { id: 15, label: I18n.get('15 min') }
        ]

        this.state = {
            fields: {
                broadcast_appointment_vip_delay_in_minutes: {
                    name: 'broadcast_appointment_vip_delay_in_minutes',
                    label: I18n.get('Delay - VIP'),
                    onChange: this.onChangeForm.bind(this, 'broadcast_appointment_vip_delay_in_minutes'),
                    values: minuteValues
                },
                broadcast_appointment_non_vip_delay_in_minutes: {
                    name: 'broadcast_appointment_non_vip_delay_in_minutes',
                    label: I18n.get('Delay - Non-VIP'),
                    onChange: this.onChangeForm.bind(this, 'broadcast_appointment_non_vip_delay_in_minutes'),
                    values: minuteValues
                },
                broadcast_appointment_all_delay_in_minutes: {
                    name: 'broadcast_appointment_all_delay_in_minutes',
                    label: I18n.get('Delay - All'),
                    onChange: this.onChangeForm.bind(this, 'broadcast_appointment_all_delay_in_minutes'),
                    values: minuteValues
                }
            },
            form: {
            },
            errors: {
            },
            task: {
            }
        };

        Object.keys(this.state.fields).forEach((item) => {
            console.log({ item: item, v: settings[item] });
            this.state.form[item] = settings[item];
        });
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.provider) != JSON.stringify(this.props.provider)) {

            const provider = this.props.provider || {};
            const settings = provider.settings || {};

            var nextState = Object.assign({}, prevState);

            Object.keys(this.state.fields).forEach((item) => {
                console.log({ item: item, u: settings[item] });
                nextState.form[item] = settings[item];
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    onSubmitForm(e) {
        e.preventDefault();

        const data = {
            id: this.props.provider.id,
            settings: {
                ...this.props.provider.settings,
                ...this.state.form
            }
        }

        var task = new Task();

        this.setState({ task: task });

        this.props.update_provider(data)
            .then(({ provider }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${this.props.provider.publicURL}/dashboard`);

                CORE_API.create_toast({ message: I18n.get(`Provider '@provider' has been updated.`).replace('@provider', provider.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        const provider = this.props.provider;

        return (<ProviderLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <SettingsIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Provider Settings')}
                </div>

                <div className="flex-grow flex items-center justify-end">
                    <BackButton {...this.props} />
                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('Broadcast Settings')}</p>

                            <SelectField {...this.state.fields.broadcast_appointment_vip_delay_in_minutes}
                                value={this.state.form.broadcast_appointment_vip_delay_in_minutes}
                                error={this.state.errors.broadcast_appointment_vip_delay_in_minutes} />

                            <SelectField {...this.state.fields.broadcast_appointment_non_vip_delay_in_minutes}
                                value={this.state.form.broadcast_appointment_non_vip_delay_in_minutes}
                                error={this.state.errors.broadcast_appointment_non_vip_delay_in_minutes} />

                            <SelectField {...this.state.fields.broadcast_appointment_all_delay_in_minutes}
                                value={this.state.form.broadcast_appointment_all_delay_in_minutes}
                                error={this.state.errors.broadcast_appointment_all_delay_in_minutes} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />


                        </div>
                    </div>

                </form>

            </div>

        </ProviderLayout >);
    }

}

export default ProviderContainer(SettingsPage);

