import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.createPractitioner}`,
    {
        options: {
        },
        props: (props) => ({
            create_practitioner: (input) => {

                if (typeof (input.specialities) !== 'string') {
                    input.specialities = JSON.stringify(input.specialities);
                }

                if (typeof (input.groups) !== 'string') {
                    input.groups = JSON.stringify(input.groups);
                }

                if (typeof (input.includeTPAs) !== 'string') {
                    input.includeTPAs = JSON.stringify(input.includeTPAs);
                }

                if (typeof (input.excludeTPAs) !== 'string') {
                    input.excludeTPAs = JSON.stringify(input.excludeTPAs);
                }                

                return props.mutate({
                    variables: { input: input }
                })
                    .then(({ data }) => {

                        const practitioner = data.createPractitioner;

                        if (typeof (practitioner.specialities) === 'string') {
                            practitioner.specialities = JSON.parse(practitioner.specialities);
                        }

                        if (typeof (practitioner.groups) === 'string') {
                            practitioner.groups = JSON.parse(practitioner.groups);
                        }

                        if (typeof (practitioner.includeTPAs) === 'string') {
                            practitioner.includeTPAs = JSON.parse(practitioner.includeTPAs);
                        }
                        
                        if (typeof (practitioner.excludeTPAs) === 'string') {
                            practitioner.excludeTPAs = JSON.parse(practitioner.excludeTPAs);
                        }

                        return {
                            practitioner: practitioner,
                        }
                    })
            }
        })
    })

