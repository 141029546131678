const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:81babdf5-b1f0-4a89-a5ae-14c25f405aff",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_sjA0htyAA",
    "aws_user_pools_web_client_id": "6vj3e82hjk23k6cdfgbgceji2t",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://eemyimrm4bhlddcg5c6yijbgqm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "smart-provider-assets-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_apigateway_url": "https://vhdech9f1a.execute-api.us-east-1.amazonaws.com/api",
    "google_maps_api_key": "AIzaSyAiy0ocF0S1U_tNBurB7S83zdrfD6Kz-lE",
    "google_timezone_api_key": "AIzaSyDeqWGy5U3YfjGUb9XSq4twqrtNMz4bnkQ",
};


export default awsmobile;
