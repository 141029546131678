
import { CORE_CREATE_TOAST, CORE_DELETE_TOAST } from './actions';

const api_call = ({ message, type }) => {
    return (dispatch, getState) => {

        const toast_id = new Date().getTime();

        const payload = {
            id: toast_id,
            message: message,
            type: type
        };

        dispatch({
            type: CORE_CREATE_TOAST,
            payload: payload
        });

        setTimeout(() => {

            dispatch({
                type: CORE_DELETE_TOAST,
                payload: {
                    id: toast_id,
                }
            });

        }, 5000)
    }
}

export default api_call;