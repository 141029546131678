import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';
import Task from '../../task';

import MasterContainer from '../../containers/master-container';
import MasterLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import DetailsItem from '../../components/ui/details-item';

import DateTimeSpan from '../../components/datetime-span';

import Modal from '../../components/modal';

import { ActionButton } from '../../components/ui/buttons';
import { CloseButtonIcon, AlertIcon } from '../../components/ui/icons';

import TaskMessage from '../../components/task-message';


class UserDetailsPage extends React.Component {

    constructor(props) {
        super();

        this.state = {
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({ form: { ...prevState.form, [prop]: value } }));
    }

    selectView(name, e) {

        if (e) e.preventDefault();

        this.setState({ view: name, task: {} });
    }

    onDisableUserSubmitForm(e) {

        e.preventDefault();

        const user = this.props.user || {};

        const data = {
            id: user.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.disable_user(data)
            .then(({ user }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Master User '@name' has been disabled.`).replace('@name', user.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    onEnableUserSubmitForm(e) {

        e.preventDefault();

        const user = this.props.user || {};


        const data = {
            id: user.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.enable_user(data)
            .then(({ user }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Master User '@name' has been enabled.`).replace('@name', user.name) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    onDeleteUserSubmitForm(e) {

        e.preventDefault();

        const user = this.props.user || {};


        const data = {
            id: user.id
        };

        var task = new Task();

        this.setState({ task: task });

        this.props.delete_user(data)
            .then(({ user }) => {

                this.setState({ view: null, task: task.complete_as_success() });

                CORE_API.create_toast({ message: I18n.get(`Master User '@name' has been deleted.`).replace('@name', user.name) });

                this.props.history.push(`/users`);
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }

    render() {

        const user = this.props.user || {};

        return (<MasterLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Master User Details')}
                </div>

                <div className="bar">

                    <NavLink to={`/users/${user.id}/edit`} className="btn-primary mr-4">
                        {I18n.get('Edit')}
                    </NavLink>

                    {user.enabled && <button onClick={this.selectView.bind(this, 'disable-form')} className="btn-secondary mr-4">{I18n.get('Disable User')}</button>}

                    {!user.enabled && <button onClick={this.selectView.bind(this, 'enable-form')} className="btn-secondary mr-4">{I18n.get('Enable User')}</button>}

                    <button onClick={this.selectView.bind(this, 'delete-form')} disabled={user.enabled} className="btn-tertiary mr-4">{I18n.get('Delete User')}</button>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="card">

                    <div className="body">

                        <p className="subtitle mb-12">{I18n.get('User Details')}</p>

                        <DetailsItem label={I18n.get('Name')} value={user.name} />
                        <DetailsItem label={I18n.get('Email')} value={user.email} />
                        <DetailsItem label={I18n.get('Status')} value={user.enabled ? I18n.get('ENABLED') : I18n.get('DISABLED') } />
                        <DetailsItem label={I18n.get('Language')} value={user.locale} />
                        <DetailsItem label={I18n.get('Created')} value={<DateTimeSpan value={user.createdAt} />} />
                        <DetailsItem label={I18n.get('Updated')} value={<DateTimeSpan value={user.updatedAt} />} />

                    </div>

                </div>

            </div>

            <Modal isOpen={this.state.view == 'disable-form'}>

                <div>
                    <div className="bg-white flex justify-end items-center border-b">
                        <div className="flex-1 p-4">
                            <span className="font-semibold mr-2">{I18n.get('Disable User Confirmation')}</span>
                        </div>
                        <div className="flex-1 text-right pr-4">
                            <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                <CloseButtonIcon className="icon w-3 h-3" />
                            </a>
                        </div>
                    </div>
                    <div className="p-12">

                        <form onSubmit={this.onDisableUserSubmitForm.bind(this)}>

                            <p className="mb-6">
                                <strong>{user.name} ({user.email})</strong>
                            </p>

                            <p className="mb-12 text-gray-600">
                                {I18n.get('User will no longer be able to log in to the system.')}
                            </p>

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Disable User')} />


                        </form>

                    </div>
                </div>

            </Modal>

            <Modal isOpen={this.state.view == 'enable-form'}>

                <div>
                    <div className="bg-white flex justify-end items-center border-b">
                        <div className="flex-1 p-4">
                            <span className="font-semibold mr-2">{I18n.get('Enable User Confirmation')}</span>
                        </div>
                        <div className="flex-1 text-right pr-4">
                            <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                <CloseButtonIcon className="icon w-3 h-3" />
                            </a>
                        </div>
                    </div>
                    <div className="p-12">

                        <form onSubmit={this.onEnableUserSubmitForm.bind(this)}>

                            <p className="mb-6">
                                <strong>{user.name} ({user.email})</strong>
                            </p>

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Enable User')} />


                        </form>

                    </div>
                </div>

            </Modal>

            <Modal isOpen={this.state.view == 'delete-form'}>

                <div>
                    <div className="bg-white flex justify-end items-center border-b">
                        <div className="flex-1 p-4">
                            <span className="font-semibold mr-2">{I18n.get('Delete User Confirmation')}</span>
                        </div>
                        <div className="flex-1 text-right pr-4">
                            <a href="#" onClick={this.selectView.bind(this, '')} className="card-header-icon" aria-label={I18n.get('more options')}>
                                <CloseButtonIcon className="icon w-3 h-3" />
                            </a>
                        </div>
                    </div>
                    <div className="p-2 m-4 mb-0 text-gray-600 text-red-600 text-sm border border-red-500">
                        <AlertIcon /> This operation cannot be undone.
                    </div>
                    <div className="p-12">

                        <form onSubmit={this.onDeleteUserSubmitForm.bind(this)}>

                            <p className="mb-8">
                                <strong>{user.name} ({user.email})</strong>
                            </p>

                            <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Delete User')} />


                        </form>

                    </div>
                </div>

            </Modal>
        </MasterLayout>);
    }

}

export default MasterContainer(CORE_API.get_user(UserDetailsPage));

