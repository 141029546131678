import { I18n } from 'aws-amplify';

export default class Task {

    constructor() {
        this.id = new Date().getTime();
        this.start_date = new Date();
        this.busy = true;
    }

    complete_as_success() {

        var task = new Task();
        task.id = this.id;
        task.busy = false;
        task.completed = true;
        task.result = true;
        task.end_date = new Date();
        return task;
    }

    complete_as_failure(err) {

        var task = new Task();
        task.id = this.id;
        task.busy = false;
        task.completed = true;
        task.result = false;
        task.end_date = new Date();

        var obj = {
            message: 'An error has occurred.',
            errors: {},
        };

        try {
            obj = JSON.parse(err.graphQLErrors[0].message);
        }
        catch (e) {
            obj.message = I18n.get(err.code || err.message || err);
        }

        task.error = { message: obj.message };

        return { task: task, errors: obj.errors };
    }
}

Task.areEqual = (lhs, rhs) => {

    return (JSON.stringify(lhs) == JSON.stringify(rhs));
}



