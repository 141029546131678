import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from "history/createBrowserHistory";

import { Auth } from 'aws-amplify';

import { store } from './store';

import { AUTH_API } from './actions/auth/actions';
import { CORE_API } from './actions/core/actions';

import localizationService from './localization';

import LoginPage from './areas/account/login-page';
import ForgotPasswordPage from './areas/account/forgot-password-page';
import ResetPasswordPage from './areas/account/reset-password-page';
import ChangePasswordPage from './areas/account/change-password-page';

import IndexPage from './areas/pages/index-page';
import DashboardPage from './areas/pages/dashboard-page';
import NotFoundPage from './areas/pages/not-found-page';
import UnauthorizedPage from './areas/pages/unauthorized-page';
import MasterUserListPage from './areas/master/users-page';
import MasterUserCreatePage from './areas/master/create-user-page';
import MasterUserDetailsPage from './areas/master/user-details-page';
import MasterUserEditPage from './areas/master/edit-user-page';


import MasterProviderListPage from './areas/master/providers-page';
import MasterMedicalProviderCreatePage from './areas/master/create-provider-page';
import MasterAuditLogsPage from './areas/master/audit-logs-page';


import MedicalProviderLoginPage from './areas/medical-provider/login-page';
import MedicalProviderDetailsPage from './areas/medical-provider/details-page';
import MedicalProviderDashboardPage from './areas/medical-provider/dashboard-page';
import MedicalProviderEditPage from './areas/medical-provider/edit-page';
import MedicalProviderUserListPage from './areas/medical-provider/users-page';
import MedicalProviderUserCreatePage from './areas/medical-provider/create-user-page';
import MedicalProviderUserDetailsPage from './areas/medical-provider/user-details-page';
import MedicalProviderUserEditPage from './areas/medical-provider/edit-user-page';

import MedicalProviderCoveragePage from './areas/medical-provider/coverage-page';

import MedicalProviderTPAListPage from './areas/medical-provider/tpas-page';
import MedicalProviderTPACreatePage from './areas/medical-provider/create-tpa-page';

import MedicalProviderPractitionerListPage from './areas/medical-provider/practitioners-page';
import MedicalProviderPractitionerCreatePage from './areas/medical-provider/create-practitioner-page';

import MedicalProviderPractitionerGroupListPage from './areas/medical-provider/practitioner-groups-page';
import MedicalProviderPractitionerGroupCreatePage from './areas/medical-provider/create-practitioner-group-page';
import MedicalProviderPractitionerGroupDetailsPage from './areas/medical-provider/practitioner-group-details-page';
import MedicalProviderPractitionerGroupEditPage from './areas/medical-provider/edit-practitioner-group-page';

import ProviderSettingsPage from './areas/medical-provider/settings-page';

import ProviderAuditLogsPage from './areas/medical-provider/audit-logs-page';
import ProviderReportPage from './areas/medical-provider/report-page';

import PractitionerDashboardPage from './areas/practitioner/dashboard-page';
import PractitionerDetailsPage from './areas/practitioner/details-page';
import PractitionerLocationPage from './areas/practitioner/location-page';
import PractitionerCalendarPage from './areas/practitioner/calendar-page';
import PractitionerEditPage from './areas/practitioner/edit-page';
import PractitionerEditLocationPage from './areas/practitioner/edit-location-page';
import PractitionerSettingsPage from './areas/practitioner/edit-user-page';
import PractitionerReportPage from './areas/practitioner/report-page';

import TPADashboardPage from './areas/tpa/dashboard-page';
import TPADetailsPage from './areas/tpa/details-page';
import TPAEditPage from './areas/tpa/edit-page';
import TPAAppointmentCreatePage from './areas/tpa/create-appointment-page';
import TPAAppointmentCreateMultiStepPage from './areas/tpa/create-appointment-multi-step-page';
import TPAAppointmentEditPage from './areas/tpa/edit-appointment-page';
import TPADashboardUserListPage from './areas/tpa/users-page';
import TPAUserCreatePage from './areas/tpa/create-user-page';
import TPAUserDetailsPage from './areas/tpa/user-details-page';
import TPAUserEditPage from './areas/tpa/edit-user-page';
import TPAReportPage from './areas/tpa/report-page';

import './App.css';

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    login_success: (user, task) => {
      dispatch(AUTH_API.login_success(user, task));
    },
    login_failure: (task) => {
      dispatch(AUTH_API.login_failure({ task: task, errors: {} }));
    },
    dispatch: action => {
      dispatch(action);
    },
  };
};

const history = createBrowserHistory()

class App extends Component {

  constructor(props) {
    super();

    this.state = {
      notificationSubscription: false
    }

    Auth.currentAuthenticatedUser()
      .then(user => {

        // console.log({user: user});

        const payload = AUTH_API.login_success(user);

        store.dispatch({ type: 'AUTH_SUCCESS', payload: payload });  
        
        if (payload.locale) {
          localizationService.setLocale(payload.locale);
        }

      })
      .catch(err => {

        const payload = AUTH_API.login_failure(err);

        store.dispatch({ type: 'AUTH_FAILURE', payload: payload });
      });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }


  render() {

    console.log({ App: this.props });

    return (<div id="app">
      <Router history={history}>
        <Switch>
          <Route path="/" exact render={(routerProps) => (<IndexPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/login" render={(routerProps) => (<LoginPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/forgot-password" render={(routerProps) => (<ForgotPasswordPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/reset-password" render={(routerProps) => (<ResetPasswordPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/change-password" render={(routerProps) => (<ChangePasswordPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/login" render={(routerProps) => (<MedicalProviderLoginPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/users" exact render={(routerProps) => (<MasterUserListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/users/new" render={(routerProps) => (<MasterUserCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/users/:userId" exact render={(routerProps) => (<MasterUserDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/users/:userId/edit" exact render={(routerProps) => (<MasterUserEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />


          <Route path="/:providerId/login" render={(routerProps) => (<MedicalProviderLoginPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/providers/new" render={(routerProps) => (<MasterMedicalProviderCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/providers" exact render={(routerProps) => (<MasterProviderListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />


          <Route path="/audit-logs" exact render={(routerProps) => (<MasterAuditLogsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />


          <Route path="/:providerId/details" render={(routerProps) => (<MedicalProviderDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/dashboard" render={(routerProps) => (<MedicalProviderDashboardPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/coverage-area" render={(routerProps) => (<MedicalProviderCoveragePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />


          <Route path="/:providerId/edit" render={(routerProps) => (<MedicalProviderEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/users" exact render={(routerProps) => (<MedicalProviderUserListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/users/:userId/details" exact render={(routerProps) => (<MedicalProviderUserDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/users/:userId/edit" exact render={(routerProps) => (<MedicalProviderUserEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/users/new" render={(routerProps) => (<MedicalProviderUserCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/groups" exact render={(routerProps) => (<MedicalProviderPractitionerGroupListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/groups/new" render={(routerProps) => (<MedicalProviderPractitionerGroupCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/groups/:practitionerGroupId/details" render={(routerProps) => (<MedicalProviderPractitionerGroupDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/groups/:practitionerGroupId/edit" render={(routerProps) => (<MedicalProviderPractitionerGroupEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/practitioners" exact render={(routerProps) => (<MedicalProviderPractitionerListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/practitioners/new" render={(routerProps) => (<MedicalProviderPractitionerCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/settings" exact render={(routerProps) => (<ProviderSettingsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/audit-logs" exact render={(routerProps) => (<ProviderAuditLogsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/report" exact render={(routerProps) => (<ProviderReportPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route path="/:providerId/p/:practitionerId/dashboard" render={(routerProps) => (<PractitionerDashboardPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/details" exact render={(routerProps) => (<PractitionerDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/location" exact render={(routerProps) => (<PractitionerLocationPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/calendar" exact render={(routerProps) => (<PractitionerCalendarPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/edit" render={(routerProps) => (<PractitionerEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/location/edit" render={(routerProps) => (<PractitionerEditLocationPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/p/:practitionerId/settings" render={(routerProps) => (<PractitionerSettingsPage {...this.props} {...routerProps} {...routerProps.match.params} userId={this.props.auth.userId} />)} />
          <Route path="/:providerId/p/:practitionerId/report" render={(routerProps) => (<PractitionerReportPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          
          <Route path="/:providerId/tpas/new" render={(routerProps) => (<MedicalProviderTPACreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/tpas" render={(routerProps) => (<MedicalProviderTPAListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/edit" render={(routerProps) => (<TPAEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/dashboard" render={(routerProps) => (<TPADashboardPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/details" render={(routerProps) => (<TPADetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/appointments/new" render={(routerProps) => (<TPAAppointmentCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/appointments/create" render={(routerProps) => (<TPAAppointmentCreateMultiStepPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/appointments/:appointmentId/edit" render={(routerProps) => (<TPAAppointmentEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/users" exact render={(routerProps) => (<TPADashboardUserListPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/users/new" render={(routerProps) => (<TPAUserCreatePage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/users/:userId" exact render={(routerProps) => (<TPAUserDetailsPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/users/:userId/edit" exact render={(routerProps) => (<TPAUserEditPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
          <Route path="/:providerId/t/:tpaId/report" exact render={(routerProps) => (<TPAReportPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />


          <Route path="/unauthorized" exact render={(routerProps) => (<UnauthorizedPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />

          <Route render={(routerProps) => (<NotFoundPage {...this.props} {...routerProps} {...routerProps.match.params} />)} />
        </Switch>
      </Router >
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

