import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';


import { AUTH_API } from '../../actions/auth/actions';
import Task from '../../task';

import LoginLayout from '../shared/login-layout';

import { ActionButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import PasswordField from '../../components/form/password-field';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ResetPasswordPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                email: {
                    fieldName: 'email',
                    onChange: this.onChangeForm.bind(this, 'email'),
                    placeholder: I18n.get("Email")
                },
                code: {
                    fieldName: 'code',
                    onChange: this.onChangeForm.bind(this, 'code'),
                    placeholder: I18n.get("Code")
                },
                password: {
                    fieldName: 'password',
                    onChange: this.onChangeForm.bind(this, 'password'),
                    placeholder: I18n.get("Password")
                },
                password2: {
                    fieldName: 'password2',
                    onChange: this.onChangeForm.bind(this, 'password2'),
                    placeholder: I18n.get("Re-type new password")
                }
            },
            form: {
                email: props.auth.email,
                code: '',
                password: '',
                password2: '',
            },
            errors: {
            },
            task: {}
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {
        e.preventDefault();

        const reset_task = new Task();

        this.setState({ task: reset_task });

        const data = {
            ...this.state.form,
        };

        AUTH_API.reset_password(data)
            .then(({ task, errors }) => {

                this.setState({ task: task, errors: errors });
            })
            .catch(err => {

                console.log({xx: err});

            });
    }

    render() {

        return (<LoginLayout {...this.props}>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden p-8 mx-8 w-full max-w-md">

            <p className="px-8 py-4 text-xl text-center">{I18n.get('Forgot password')}</p>

                <p className="my-8 text-gray-500">{I18n.get('Please choose a new password.')}</p>

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} autoFocus="" />

                    <TextField  {...this.state.fields.code} value={this.state.form.code} error={this.state.errors.email} />

                    <PasswordField  {...this.state.fields.password} value={this.state.form.password} error={this.state.errors.password} />

                    <PasswordField  {...this.state.fields.password2} value={this.state.form.password2} error={this.state.errors.password2} />

                    <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                    <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />
                </form>

                <p className="mt-8 text-xs text-gray-600 text-right">
                    <NavLink to="/login">{I18n.get('Login')}</NavLink>
                    &nbsp;·&nbsp;
                    <a href="/help">{I18n.get('Help')}</a>
                </p>

            </div>
        </LoginLayout>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

