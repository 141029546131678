import * as queries from '../../graphql/queries';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${queries.listAuditLogsByProvider}`,
    {
        options: ({ providerId, filter = {} }) => {

            return {

            variables: {
                providerId: providerId,
                filter: filter,
                limit: 50
            },
            fetchPolicy: 'cache-and-network',
        }},
        props: ({ data: { listAuditLogsByProvider = {}, loading, fetchMore } }) => {

            return {
                auditLogs: listAuditLogsByProvider,
                loading: loading,
                refresh: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAuditLogsByProvider: {
                                ...fetchMoreResult.listAuditLogsByProvider
                            }
                        });
                    }
                }),
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAuditLogsByProvider: {
                                ...fetchMoreResult.listAuditLogsByProvider,
                                items: [...prev.listAuditLogsByProvider.items, ...fetchMoreResult.listAuditLogsByProvider.items],
                            }
                        });
                    }
                })
            }
        }
    }
)