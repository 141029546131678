import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import PractitionerContainer from '../../containers/practitioner-container';
import PractitionerLayout from '../shared/layout';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import Address1Field from '../../components/form/address1-field';

import NumberField from '../../components/form/number-field';
import SelectField from '../../components/form/select-field';
import RadioField from '../../components/form/radio-field';
import TimeRangeField from '../../components/form/time-range-field';

import domain from '../../domain';
import { normalizeAddress } from '../../utils';

import 'whatwg-fetch';

import Map from '../../components/map';

import { AddIcon, CloseButtonIcon } from '../../components/ui/icons';

import config from '../../aws-exports';

class EditPractitionerPage extends React.Component {

    constructor(props) {

        super(props);

        const { practitioner = {} } = props;

        this.searchRef = React.createRef()
        this.sliderEl = React.createRef();

        this.state = {
            search: '',
            fields: {
                id: '',
                address1: {
                    name: 'address1',
                    label: I18n.get('Street Address'),
                    onChange: this.onChangeAddress1.bind(this),
                },
                city: {
                    name: 'city',
                    label: I18n.get('City'),
                    onChange: this.onChangeForm.bind(this, 'city'),
                    readOnly: true
                },
                state: {
                    name: 'state',
                    label: I18n.get('State'),
                    onChange: this.onChangeForm.bind(this, 'state'),
                    readOnly: true
                },
                countryId: {
                    name: 'countryId',
                    label: I18n.get('Country'),
                    onChange: this.onChangeForm.bind(this, 'countryId'),
                    readOnly: true
                },
                zipcode: {
                    name: 'zipcode',
                    label: I18n.get('Zip Code'),
                    onChange: this.onChangeForm.bind(this, 'zipcode'),
                },
                radius: {
                    name: 'radius',
                    label: I18n.get('Radius'),
                    onChange: this.onChangeForm.bind(this, 'radius'),
                    min: 1,
                    max: 50,
                    defaultValue: 1
                },
                radiusUnit: {
                    name: 'radiusUnit',
                    label: I18n.get('Radius Unit'),
                    onChange: this.onChangeForm.bind(this, 'radiusUnit'),
                    values: [{ id: 'mi', label: I18n.get('MILE') }, { id: 'km', label: I18n.get('KILOMETER') }],
                    defaultValue: 'mi'
                },
                timezoneName: {
                    name: 'timezoneName',
                    label: I18n.get('Timezone'),
                    readOnly: true
                },
                availability: {
                    defaultValue: {
                        0: [{ start: '08:00', end: '17:00' }],
                        1: [{ start: '08:00', end: '17:00' }],
                        2: [{ start: '08:00', end: '17:00' }],
                        3: [{ start: '08:00', end: '17:00' }],
                        4: [{ start: '08:00', end: '17:00' }],
                        5: [],
                        6: []
                    }
                }

            },
            form: {
                lat: practitioner.lat,
                lng: practitioner.lng,
                timezoneName: practitioner.timezoneName,
                radius: 10,
                radiusUnit: 'mi',
                availability: {}
            },
            errors: {
            },
            task: {
            },
            autocomplete: false
        };
    }

    componentDidMount() {
        this.setupGoogleAutoComplete();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var nextState = Object.assign({}, prevState);
        var updateState = false;

        if (this.state.form.id != this.props.practitioner.id) {

            Object.keys(this.state.fields).forEach((item) => {

                nextState.form[item] = this.props.practitioner[item] || this.state.fields[item].defaultValue;
            });

            this.setState(nextState);
        }

        this.setupGoogleAutoComplete();
    }

    componentWillUnmount() {
    }

    setupGoogleAutoComplete() {

        if (this.state.autocomplete) return;
        if (!window.google) return;

        const options = {
            types: ['address']
        };

        const geoAutocomplete = new window.google.maps.places.Autocomplete(this.searchRef.current, options);

        geoAutocomplete.addListener('place_changed', () => {

            const place = geoAutocomplete.getPlace();

            console.log({ place: place });

            var { address1, city, state, zipcode, countryId, lat, lng } = normalizeAddress(place);

            var ts = Math.round((new Date()).getTime() / 1000);

            fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${ts}&key=${config.google_timezone_api_key}`)
                .then((response) => {

                    return response.json();

                }).then(({ timeZoneId, timeZoneName }) => {

                    this.setState(prevState => {

                        var nextState = Object.assign({}, prevState);

                        nextState.form = { ...prevState.form, address1, city, state, zipcode, countryId, lat, lng, timezoneName: timeZoneName, timezoneId: timeZoneId }
                        nextState.errors = {};
                        nextState.formatted_address = place.formatted_address;

                        return nextState;
                    });

                });

        });

        this.setState({ autocomplete: true });
    }

    onChangeSearch(e) {

        const value = e.target.value;

        this.setState({ search: value });
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeAddress1(e) {

        const value = e.target.value;

        // Reset lat/lng on address1 change so the user has to pick an address from the list

        this.setState(prevState => ({
            form: { ...prevState.form, 'address1': value, 'lat': null, 'lng': null },
            errors: { ...prevState.errors, 'address1': undefined }
        })
        );
    }

    onChangeFormValue(prop, value) {

        console.log({ prop: prop, value: value });

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onChangeFormArrayToggle(prop, value) {

        this.setState(prevState => {

            let arr = prevState.form[prop] || [];

            if (arr.indexOf(value) > -1) {
                arr = prevState.form[prop].filter((item) => item !== value);
            }
            else {
                arr = [...arr, value]
            }

            return ({
                form: {
                    ...prevState.form,
                    [prop]: arr
                }
            })
        });
    }

    onChangeHours(day, index, value) {

        console.log({ day: day, index: index, value: value });

        this.setState(prevState => {

            var nextState = Object.assign({}, prevState);

            nextState.form.availability[day][index] = value;

            return nextState;
        });

    }

    onAddHours(day) {

        console.log({ add: day });

        this.setState(prevState => {

            var nextState = Object.assign({}, prevState);

            nextState.form.availability[day].push({ start: '08:00', end: '17:00' });

            return nextState;
        });

    }

    onDelHours(day, index) {

        this.setState(prevState => {

            var nextState = Object.assign({}, prevState);

            nextState.form.availability[day] = nextState.form.availability[day].filter((item, i) => i != index);

            return nextState;
        });

    }

    onSubmitForm(e) {
        e.preventDefault();

        const provider = this.props.provider || {};

        const data = {
            ...this.state.form,
        }

        delete data['provider'];
        delete data['__typename'];

        var task = new Task();

        this.setState({ task: task });

        this.props.update_practitioner(data)
            .then(({ practitioner }) => {

                this.setState({ task: task.complete_as_success() });

                this.props.history.push(`/${provider.publicURL}/p/${practitioner.id}/location`);

                CORE_API.create_toast({ message: I18n.get(`Practitioner '@practitioner' has been updated.`.replace('@practitioner', practitioner.name)) });
            })
            .catch((err) => {

                const result = task.complete_as_failure(err);

                this.setState(result);
            });
    }


    render() {

        const practitioner = this.props.practitioner || {};

        const location = (this.state.form.lat && this.state.form.lat) && new window.google.maps.LatLng(parseFloat(this.state.form.lat), parseFloat(this.state.form.lng));

        return (<PractitionerLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Edit Location & Availability')}
                </div>

                <div className="bar">
                    <BackButton {...this.props} />
                </div>

            </div>

            <div className="page-body flex">

                <div className="flex-1">

                    <form onSubmit={this.onSubmitForm.bind(this)}>

                        <div className="card">

                            <div className="body">

                                <p className="subtitle mt-8 mb-8">{I18n.get('Location')}</p>

                                {/* <div className="field mb-12">

                                    <div className="control">

                                        <input type="search" name="address-search"  className="input-search px-5 py-3 focus:outline-none focus:shadow-outline" placeholder={I18n.get('Type an address')} />

                                    </div>
                                </div> */}

                                <Address1Field {...this.state.fields.address1} createRef={this.searchRef} value={this.state.form.address1} error={this.state.errors.address1} />

                                <TextField {...this.state.fields.city} value={this.state.form.city} error={this.state.errors.city} />

                                <TextField {...this.state.fields.state} value={this.state.form.state} error={this.state.errors.state} />

                                <TextField {...this.state.fields.countryId} value={this.state.form.countryId} error={this.state.errors.countryId} style={{ width: '50%' }} />

                                <TextField {...this.state.fields.zipcode} value={this.state.form.zipcode} error={this.state.errors.zipcode} style={{ width: '50%' }} />

                                <NumberField {...this.state.fields.radius} value={this.state.form.radius} error={this.state.errors.radius} style={{ width: '50%' }} />

                                <RadioField
                                    {...this.state.fields.radiusUnit}
                                    value={this.state.form.radiusUnit}
                                    error={this.state.errors.radiusUnit} />

                                <TextField {...this.state.fields.timezoneName} value={this.state.form.timezoneName} error={this.state.errors.timezoneName} style={{ width: '50%' }} />

                                <p className="subtitle mt-8 mb-8">{I18n.get('Availability')}</p>

                                {domain.weekAvailability.map((domainDay) => {

                                    const day = (this.state.form.availability && this.state.form.availability[domainDay.id]) || [];

                                    const value = day.length == 0 ? I18n.get('Unavailable') : day.map((hour, index) => {
                                        const cmp = (<div className="flex flex-row">
                                            <TimeRangeField value={day[index]} onChange={this.onChangeHours.bind(this, domainDay.id, index)} />
                                            <a onClick={this.onDelHours.bind(this, domainDay.id, index)} className="ml-2">
                                                <CloseButtonIcon className="fill-current h-3 w-3 mt-3 text-gray-400" />
                                            </a>
                                        </div>);
                                        return (cmp);
                                    });


                                    return (<div className="flex mt-2">
                                        <div className="flex-none w-1/3 tracking-wide text-gray-label">
                                            {I18n.get(domainDay.label)}

                                            <a onClick={this.onAddHours.bind(this, domainDay.id)} className="float-right mr-4 text-gray-400">
                                                <AddIcon />
                                            </a>

                                        </div>
                                        <div className="flex-grow">
                                            {value}
                                        </div>
                                    </div>);
                                })}

                                {this.state.errors.availability && <p className="mt-4 task-failure"><span>{this.state.errors.availability}</span></p>}

                                <br /><br />


                                <TaskMessage {...this.state.task}>{I18n.get('Saved successfully.')}</TaskMessage>

                                <ActionButton task={this.state.task} label={I18n.get('Save Changes')} />

                            </div>

                        </div>

                    </form>

                </div>

                <div className="flex-1 ml-4">

                    <div className="card">
                        <div className="body">

                            <p className="subtitle mb-8">{I18n.get('MAP')}</p>

                            {this.state.formatted_address && <p>{this.state.formatted_address}</p>}

                            <br />

                            <Map defaultCenter={location} marker={this.state.location} radius={this.state.form.radius} style={{ height: '300px' }}></Map>

                            <br />

                            <p className="form-group">{I18n.get('Radius')}</p>

                            <p className="mt-4">
                                {I18n.get('Current radius is')} {this.state.form.radius} {this.state.form.radiusUnit}.
                            </p>

                        </div>
                    </div>

                </div>

            </div>

        </PractitionerLayout >);
    }

}

export default PractitionerContainer(EditPractitionerPage);



