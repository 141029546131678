import React from 'react';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import ReportItem from '../shared/_report-item';

import Task from '../../task';

import Modal from '../../components/modal';

import { Button } from '../../components/ui/buttons';

import AppointmentDetails from '../shared/appointment-details';

import ReportFilter from './_report-filter';

const AppointmentDetailsWithData = CORE_API.get_appointment(AppointmentDetails);


class ReportList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
            fields: {
            },
            form: {
            },
            errors: {},
            load_task: {}
        }
    }

    componentDidMount() {

    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
            , () => {

                this.props.onFilterChange(this.state.form)
            });
    }

    select(item, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            if (prevState.selected.id == item.id) {

                return { selected: {} };
            }
            else {
                return {
                    selected: {
                        ...item,
                    }
                };
            }

        });
    }

    onFilterChange(filter) {

        console.log({ filter: filter });

        var load_task = new Task();

        this.setState({ load_task: load_task });

        const data = {
            providerId: this.props.provider.id,
            filter: filter
        }

        this.props.filter(data)
            .then(({ listAppointments }) => {

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    loadMore(token) {

        console.log({ loadMore: token });

        const data = {
            nextToken: token,
            filter: this.props.filter
        }

        var load_task = new Task();

        this.setState({ load_task: load_task });

        this.props.next(data)
            .then(({ listAppointments }) => {

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    render() {

        const provider = this.props.provider;
        const appointments = this.props.appointments || {};

        const items = appointments.items || [];
        const nextToken = appointments.nextToken;


        return (<section className={this.props.loading ? 'loading-mask' : ''}>

            <ReportFilter {...this.props} onFilterChange={this.onFilterChange.bind(this)} />

            <div className="hidden sm:block font-semibold">
                <div className="px-4 py-4">

                    <div className="flex justify-between">

                        {this.props.auth.is_master_or_provider && <div className="flex-1 p-2">
                            {I18n.get('TPA')}
                        </div>}
                        <div className="flex-1 p-2">
                            {I18n.get('Id')}
                        </div>
                        <div className="flex-1 p-2">
                            {I18n.get('Status')}
                        </div>

                        <div className="flex-1 p-2">
                            {I18n.get('Patient')}
                        </div>

                        <div className="flex-1 p-2">
                            {I18n.get('Location')}
                        </div>

                        <div className="flex-1 p-2">
                            {I18n.get('Practitioner')}
                        </div>

                        <div className="flex-1 p-2">
                            {I18n.get('Scheduled Date')}
                        </div>

                        <div className="flex-1 p-2">
                            {I18n.get('Created')}
                        </div>

                        <div className="flex-none w-10 p-2">
                            {I18n.get('EMR')}
                        </div>

                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                {items && items.map((item, index) => (
                    <div key={index} className="cursor-pointer" >
                        <ReportItem auth={this.props.auth} item={item} select={this.select.bind(this)} />
                    </div>)
                )}
            </div>

            <br />

            <div className="text-center">
                <Button task={this.state.load_task} onClick={(e) => this.loadMore(nextToken)} disabled={!nextToken} label={I18n.get('Load more')}></Button>
            </div>

            <br />

            <Modal isOpen={this.state.selected.id}>

                <AppointmentDetailsWithData {...this.props} appointmentId={this.state.selected.id} select={this.select.bind(this)} />

            </Modal>

        </section>);
    }
}

export default ReportList;