import React from 'react';

const Badge = ({ label }) => {

    return (
        <div className="inline-block bg-teal-500 text-white text-xs px-2 py-0 rounded-full">
            {label}
        </div>
    )
}

export default Badge;