import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import LoginLayout from '../shared/login-layout';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class UnauthorizedPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        console.log(this.props);

        if (!this.props.auth.logged_user) {

            if (this.props.match && this.props.match.params && this.props.match.params.providerId)
            {
                return <Redirect to={`/${this.props.match.params.providerId}/login`} /> 
            }
            else {
                return <Redirect to={`/login`} />
            }
        }

        return  (<LoginLayout {...this.props}>

            <div className="column is-4 is-offset-4">
                <h3 className="title has-text-grey">{I18n.get('Unauthorized')}</h3>
            </div>


        </LoginLayout>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedPage);