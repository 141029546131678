import React from 'react';
import { I18n } from 'aws-amplify';

import Task from '../../task';

import { Button } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import AppointmentCard from '../shared/appointment-card';

import Map from '../../components/map';
import { CORE_API } from '../../actions/core/actions';

import { CloseButtonIcon } from '../../components/ui/icons';

class AppointmentRequest extends React.Component {

    constructor(props) {
        super();
        this.state = {
            accept_task: {},
            decline_task: {}
        }
    }

    componentDidMount() {

        // CORE_API.update_notification({
        //     username: this.props.auth.username,
        //     id: this.props.core.notification.id,
        //     status: 1
        // });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
    }

    select(item, e) {

        if (e) e.preventDefault();

        CORE_API.select_notification(item);
    }

    onAccept(e) {

        e.preventDefault();

        const data = {
            id: this.props.appointmentId,
            practitionerId: this.props.appointmentRequestId,
            accepted: true
        }

        var accept_task = new Task();

        this.setState({ accept_task: accept_task });

        this.props.update_appointment_request(data)
            .then(({ appointment }) => {

                //CORE_API.create_toast({ message: `Appointment '@appointment' has been accepted.`.replace('@appointment', appointment.name) });

                this.setState({ accept_task: accept_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = accept_task.complete_as_failure(err);

                this.setState({ accept_task: task, errors: errors });
            });
    }

    onDecline(e) {

        e.preventDefault();

        const data = {
            id: this.props.appointmentId,
            practitionerId: this.props.appointmentRequestId,
            accepted: false
        }

        var decline_task = new Task();

        this.setState({ decline_task: decline_task });

        this.props.update_appointment_request(data)
            .then(({ appointment }) => {

                //CORE_API.create_toast({ message: `Appointment '@appointment' has been declined.`.replace('@appointment', appointment.name) });

                this.setState({ decline_task: decline_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = decline_task.complete_as_failure(err);

                this.setState({ decline_task: task, errors: errors });
            });
    }

    render() {

        const { appointmentRequest = {} } = this.props;
        const { appointment = {} } = appointmentRequest;

        const practitioner = this.props.practitioner || {};

        const location = appointment.lat && appointment.lng && new window.google.maps.LatLng(parseFloat(appointment.lat), parseFloat(appointment.lng));

        const origin = practitioner.lat && practitioner.lng && new window.google.maps.LatLng(parseFloat(practitioner.lat), parseFloat(practitioner.lng));

        return (<div>
            <div className="bg-white flex justify-end items-center border-b">
                <div className="flex-1 p-4">
                    <span className="font-semibold mr-2">{I18n.get('Appointment Request')}</span>
                </div>
                <div className="flex-1 text-right pr-4">
                    <a href="#" onClick={(e) => this.select({}, e)} className="card-header-icon" aria-label={I18n.get('more options')}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </a>
                </div>
            </div>

            <div className="flex flex-col sm:flex-row">
                <div className="flex-1 p-8">

                    <AppointmentCard item={appointment} />

                    {appointmentRequest.accepted != null && <div className="mt-8">

                        {appointmentRequest.accepted === true && <div className="font-bold text-secondary">{I18n.get('ACCEPTED')}</div>}

                        {appointmentRequest.accepted === false && <div className="font-bold text-secondary">{I18n.get('DECLINED')}</div>}

                    </div>}

                </div>

                <div className="flex-1 p-8">

                    <p className="my-4 font-semibold text-lg leading-wider">Map &amp; Driving Directions</p>

                    {location && <Map defaultCenter={location} origin={origin} style={{ height: '300px' }} ></Map>}

                </div>
            </div>

            <div className="p-8 mb-2">
                <TaskMessage {...this.state.accept_task}>
                    You have accepted this appointment request. However, you will receive a confirmation if the appointment is assigned to you.
                </TaskMessage>
                <TaskMessage {...this.state.decline_task}>
                    You have declined this appointment request.
                </TaskMessage>

                {appointmentRequest.accepted == null && <div className="mt-8">
                    <Button onClick={this.onAccept.bind(this)} task={this.state.accept_task} className="btn-primary" label={I18n.get('Accept')}></Button>
                    <Button onClick={this.onDecline.bind(this)} task={this.state.decline_task} className="btn-tertiary" label={I18n.get('Decline')}></Button>
                </div>}

            </div>
        </div>);
    }
}


export default (AppointmentRequest);