import React from 'react';
import { I18n } from 'aws-amplify';

import DateTimeSpan from '../../components/datetime-span';

import Badge from '../../components/ui/badge';

const NotificationItem = ({ item = {} }) => {

    return (<div className="px-3 py-4 border-b">
        <div className="text-sm">
            {item.title}
        </div>
        <div className="mt-1 text-sm">
            <span className="text-gray-500"><DateTimeSpan value={item.createdAt} /></span> &nbsp; {item.status === 0 && <Badge label={I18n.get('NEW')} />}
        </div>
    </div>);
};

export default NotificationItem;