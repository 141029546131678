import React from 'react';
import { I18n } from 'aws-amplify';

import './select-search-field.css';

import Select from 'react-select';

export default class SelectSearchField extends React.Component {
    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited,
        };
    }

    static defaultProps = {
        successIcon: true,
        className: 'field mb-4',
        placeholder: I18n.get('Select')
    }

    onChange(option) {

        if (!!option && option.value) {
            this.props.onChange(option.value);
        }
        else if (!!option && option.constructor === Array) {
            const values = option.map((item) => item.value);

            this.props.onChange(values);
        }
        else {
            this.props.onChange('');
        }
    }

    render() {

        const options = this.props.values && this.props.values.map((item) => ({ value: item.id, label: item.label, data: item.data }));

        var selected = null;

        if (!!this.props.value && this.props.value.constructor === Array) {

            selected = options && options.filter((item) => this.props.value.indexOf(item.value) > -1);
        }
        else {

            selected = options && options.filter((item) => item.value == this.props.value);
        }

        //console.log({ s: selected });

        const isOptional = this.props.isOptional;
        const label = this.props.label;

        const error = !this.state.focused && this.state.visited && this.props.error;

        const filterOption = ({ label = '', value }, q) => {

            const result = (label.toLowerCase().includes(q.toLowerCase()) || value == q);

            //console.log({ label: label, value: value, q: q, result: result});

            return result;
        };

        return (<div className={this.props.className}>
            {label && <label className="label">{label}</label>}
            <div className="control">
                <Select
                    id={this.props.name}
                    defaultMenuIsOpen={false}
                    isMulti={this.props.isMulti || false}
                    isSearchable={true}
                    value={selected}
                    options={options}
                    filterOption={filterOption}
                    onChange={this.onChange.bind(this)}
                    classNamePrefix='sp'
                    noOptionsMessage={() => I18n.get('Empty')}
                    placeholder={this.props.placeholder}
                    isDisabled={this.props.disabled}
                    components={this.props.components}
                    isOptionDisabled={this.props.isOptionDisabled}
                    placeholder={this.props.placeholder}
                />
                {error && <p className="help is-danger">
                    {error}
                </p>}
            </div>
        </div>);

    }
}


