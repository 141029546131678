
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as queries from '../../graphql/queries';

export default graphql(
    gql`${queries.getEmr}`,
    {
        options: ({ appointmentId }) => ({
            variables: {
                id: appointmentId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getEMR = {}, loading, refetch } }) => {

            console.log({getEMR: getEMR });

            const emr = getEMR || {};

            return {
                emr: emr,
                loading: loading,
                refetch: refetch
            }
        }
    }
)

