/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAdminUser = /* GraphQL */ `
  subscription OnCreateAdminUser {
    onCreateAdminUser {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAdminUser = /* GraphQL */ `
  subscription OnUpdateAdminUser {
    onUpdateAdminUser {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAdminUser = /* GraphQL */ `
  subscription OnDeleteAdminUser {
    onDeleteAdminUser {
      id
      username
      email
      profiles
      name
      enabled
      status
      notification
      locale
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onProviderAppointment = /* GraphQL */ `
  subscription OnProviderAppointment($appointmentProviderId: String!) {
    onProviderAppointment(appointmentProviderId: $appointmentProviderId) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const onTpaAppointment = /* GraphQL */ `
  subscription OnTpaAppointment($appointmentTpaId: String!) {
    onTpaAppointment(appointmentTpaId: $appointmentTpaId) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const onPractitionerAppointment = /* GraphQL */ `
  subscription OnPractitionerAppointment($practitionerId: String!) {
    onPractitionerAppointment(practitionerId: $practitionerId) {
      id
      name
      alternateId
      dob
      phoneNumberCode
      phoneNumber
      gender
      specialities
      symptoms
      otherSymptom
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      country
      comments
      status
      createdAt
      updatedAt
      requestedAt
      scheduledDate
      scheduledDateUtc
      timezoneId
      timezoneName
      lat
      lng
      tpa {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioner {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
      availabilities
      startedAt
      completedAt
      appointmentTpaId
      appointmentProviderId
      practitionerId
    }
  }
`;
export const onCreateAvailability = /* GraphQL */ `
  subscription OnCreateAvailability {
    onCreateAvailability {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAvailability = /* GraphQL */ `
  subscription OnUpdateAvailability {
    onUpdateAvailability {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAvailability = /* GraphQL */ `
  subscription OnDeleteAvailability {
    onDeleteAvailability {
      ts
      slots
      total
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCountry = /* GraphQL */ `
  subscription OnCreateCountry {
    onCreateCountry {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCountry = /* GraphQL */ `
  subscription OnUpdateCountry {
    onUpdateCountry {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCountry = /* GraphQL */ `
  subscription OnDeleteCountry {
    onDeleteCountry {
      id
      name
      culture
      states
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMedicalProvider = /* GraphQL */ `
  subscription OnCreateMedicalProvider {
    onCreateMedicalProvider {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const onUpdateMedicalProvider = /* GraphQL */ `
  subscription OnUpdateMedicalProvider {
    onUpdateMedicalProvider {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const onDeleteMedicalProvider = /* GraphQL */ `
  subscription OnDeleteMedicalProvider {
    onDeleteMedicalProvider {
      id
      name
      website
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      taxId
      preferredDueDate
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      logo
      createdAt
      updatedAt
      country {
        id
        name
        culture
        states
        status
        createdAt
        updatedAt
      }
      publicURL
      settings
      coverage {
        lat
        lng
        radius
      }
      groups {
        nextToken
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($username: String!) {
    onCreateNotification(username: $username) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($username: String!) {
    onUpdateNotification(username: $username) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($username: String!) {
    onDeleteNotification(username: $username) {
      id
      username
      title
      type
      data
      deeplink
      appointment {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        appointmentTpaId
        appointmentProviderId
        practitionerId
      }
      status
      locale
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePractitioner = /* GraphQL */ `
  subscription OnCreatePractitioner {
    onCreatePractitioner {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const onUpdatePractitioner = /* GraphQL */ `
  subscription OnUpdatePractitioner {
    onUpdatePractitioner {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const onDeletePractitioner = /* GraphQL */ `
  subscription OnDeletePractitioner {
    onDeletePractitioner {
      id
      email
      name
      dob
      phoneNumberCode
      phoneNumber
      gender
      speciality
      license
      location
      status
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      groups {
        id
        name
        score
        createdAt
        updatedAt
      }
      includeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      excludeTPAs {
        id
        name
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        createdAt
        updatedAt
        groups
      }
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      radius
      radiusUnit
      availability
      lat
      lng
      timezoneId
      timezoneName
      calendar
      user {
        id
        username
        email
        profiles
        name
        enabled
        status
        notification
        locale
        identityId
        createdAt
        updatedAt
      }
      isVIP
      specialities
      position
    }
  }
`;
export const onCreatePractitionerGroup = /* GraphQL */ `
  subscription OnCreatePractitionerGroup {
    onCreatePractitionerGroup {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const onUpdatePractitionerGroup = /* GraphQL */ `
  subscription OnUpdatePractitionerGroup {
    onUpdatePractitionerGroup {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const onDeletePractitionerGroup = /* GraphQL */ `
  subscription OnDeletePractitionerGroup {
    onDeletePractitionerGroup {
      id
      name
      score
      createdAt
      updatedAt
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
      practitioners {
        id
        email
        name
        dob
        phoneNumberCode
        phoneNumber
        gender
        speciality
        license
        location
        status
        createdAt
        updatedAt
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        radius
        radiusUnit
        availability
        lat
        lng
        timezoneId
        timezoneName
        calendar
        isVIP
        specialities
        position
      }
    }
  }
`;
export const onCreateTpa = /* GraphQL */ `
  subscription OnCreateTpa {
    onCreateTPA {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
export const onUpdateTpa = /* GraphQL */ `
  subscription OnUpdateTpa {
    onUpdateTPA {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
export const onDeleteTpa = /* GraphQL */ `
  subscription OnDeleteTpa {
    onDeleteTPA {
      id
      name
      contactName
      contactEmail
      contactPhoneNumberCode
      contactPhoneNumber
      address1
      address2
      address3
      address4
      zipcode
      city
      state
      countryId
      status
      createdAt
      updatedAt
      groups
      provider {
        id
        name
        website
        contactName
        contactEmail
        contactPhoneNumberCode
        contactPhoneNumber
        taxId
        preferredDueDate
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        countryId
        status
        logo
        createdAt
        updatedAt
        publicURL
        settings
      }
    }
  }
`;
