import React from 'react';

import gql from "graphql-tag";


import { CORE_API } from '../actions/core/actions';

import Modal from '../components/modal';

import AppointmentDetails from '../areas/shared/appointment-details';

import AppointmenRequest from '../areas/practitioner/appointment-request';

export default function NotificationContainer(Component) {

  const AppointmentDetailsWithData = CORE_API.get_appointment(AppointmentDetails);

  const AppointmenRequestWithData = CORE_API.get_appointmentRequest(AppointmenRequest);


  class NotificationContext extends React.Component {

    constructor(props) {

      super(props);

      this.state = {
        subscribed: null
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

      //console.log({NotificationContext: this.props.auth.username });

      if (this.state.subscribed != this.props.auth.username) {

        console.log(`subscribed: ${this.props.auth.username}`);

        this.props.subscribeToNotifications(this.props.auth.username);

        this.setState({ subscribed: this.props.auth.username })
      }
    }


    render() {

      const notification = this.props.core.notification || {};

      return (<div>

        {<Component {...this.props} />}

        <Modal isOpen={notification.id && notification.type == 'APPOINTMENT_REQUEST'}>
          <AppointmenRequestWithData {...this.props} {...notification.data} notification={notification} select={(e) => CORE_API.select_notification({})} />
        </Modal>
        <Modal isOpen={notification.id && notification.type == 'ASSIGNMENT_TIMEOUT'}>
          <AppointmentDetailsWithData {...this.props} {...notification.data} select={(e) => CORE_API.select_notification({})} />
        </Modal>

        <Modal isOpen={notification.id && notification.type == 'APPOINTMENT_ASSIGNMENT'}>
          <AppointmentDetailsWithData {...this.props} {...notification.data} select={(e) => CORE_API.select_notification({})} />
        </Modal>

        <Modal isOpen={notification.id && notification.type == 'APPOINTMENT_UNASSIGNMENT'}>
          <AppointmentDetailsWithData {...this.props} {...notification.data} select={(e) => CORE_API.select_notification({})} />
        </Modal>

      </div>);
    }
  }

  return CORE_API.list_notifications(NotificationContext);
}
