import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import TPALayout from '../shared/layout';
import TPAContainer from '../../containers/tpa-container';
import ToastContainer from '../shared/toast-container';

import AppointmentList from '../shared/_appointment-list';

import SearchField from '../../components/form/search-field';

import { DashboardHomeIcon } from '../../components/ui/icons';

import moment from 'moment';

const AppointmentListWithData = CORE_API.list_appointmentsByTPA(AppointmentList);

class DashboardPage extends React.Component {

    constructor(props) {
        super();

        const sevendays = moment(new Date()).add(1, 'days').format("YYYY-MM-DD");

        this.state = {
            status: [1, 2, 3],
            scheduledDate: sevendays,
            search: ''
        }
    }

    componentDidMount() {
    }

    onChangeSearch(value) {

        this.setState({ search: value });
    }

    getFilter(parameters) {

        const filter = {};

        if (parameters.status && parameters.status.length) {
            filter.status = { in: parameters.status };
        }

        if (parameters.search) {
            filter.name = { contains: parameters.search }
        }

        if (parameters.scheduledDate) {
            filter.scheduledDate = { lt: parameters.scheduledDate };
        }

        return filter;
    }

    onFilterChange(parameters) {

        //console.log({ parameters: parameters});

        this.setState(prevState => {

            const nextState = Object.assign(prevState, parameters);

            //console.log({ nextState: nextState });

            return nextState;
        });
    }

    render() {

        const provider = this.props.provider || {};
        const tpa = this.props.tpa || {};

        const filter = this.getFilter(this.state);

        return (<TPALayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <DashboardHomeIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Appointments')}
                </div>

                {this.props.is_ctx_tpa && <div className="bar">
                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/appointments/new`} className="btn-primary sm:mr-8">
                        {I18n.get('Create Appointment')}
                    </NavLink>
                </div>}

                <SearchField onChangeText={this.onChangeSearch.bind(this)} value={this.state.search} />

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {tpa.id && <AppointmentListWithData {...this.props} tpaId={tpa.id} {...this.state} filter={filter} onFilterChange={this.onFilterChange.bind(this)} />}
            </div>

        </TPALayout>);
    }
}

export default TPAContainer(DashboardPage);

