import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { AUTH_API } from '../../actions/auth/actions';
import Task from '../../task';

import LoginLayout from '../shared/login-layout';

import { ActionButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import PasswordField from '../../components/form/password-field';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class LoginPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                email: {
                    name: "email",
                    onChange: this.onChangeForm.bind(this, 'email'),
                    placeholder: I18n.get('Your Email')
                },
                password: {
                    name: "password",
                    onChange: this.onChangeForm.bind(this, 'password'),
                    placeholder: I18n.get('Your Password')
                }
            },
            form: {
            },
            task: {
            },
            errors: {
            },
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        var login_task = new Task();

        this.setState({ task: login_task });
    
        const data = {
          ...this.state.form,
        };
    
        AUTH_API.login(data)
          .then(({ task, errors }) => {
    
            this.setState({ task: task, errors: errors });
          });
    }

    render() {

        if (this.props.auth.logged_user) {

            if (this.props.auth.is_master) {
                return <Redirect to={`/providers`} />
            }

            if (this.props.auth.is_provider) {
                return <Redirect to={`/${this.props.auth.providerId}/dashboard`} />
            }

            if (this.props.auth.is_tpa) {
                return <Redirect to={`/${this.props.auth.providerId}/t/${this.props.auth.tpaId}/dashboard`} />
            }

            if (this.props.auth.is_practitioner) {
                return <Redirect to={`/${this.props.auth.providerId}/p/${this.props.auth.practitionerId}/dashboard`} />
            }
        }

        return (<LoginLayout {...this.props}>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden p-8 mx-8 w-full max-w-md">

                <p className="px-8 py-4 text-xl text-center">{I18n.get('Please log in to proceed')}</p>

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                    <PasswordField {...this.state.fields.password} value={this.state.form.password} error={this.state.errors.password} />

                    <br />

                    <TaskMessage {...this.state.task}>Logged in.</TaskMessage>

                    <ActionButton className="btn-primary full" label={I18n.get('Login')} task={this.state.task} />

                </form>
                <p className="mt-8 text-xs text-gray-600 text-right">
                    <NavLink to="/forgot-password">{I18n.get('Forgot password')}</NavLink>
                    &nbsp;·&nbsp;
                    <a href="/">{I18n.get('Help')}</a>
                </p>
            </div>
        </LoginLayout>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

