import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.completeAppointment}`,
    {
        options: {
        },
        props: (props) => ({
            complete_appointment: (input) => {

                return props.mutate({
                    variables: { input: input }
                })
                    .then(({ data }) => {

                        const appointment = data.completeAppointment;

                        if (typeof (appointment['symptoms']) === 'string') {
                            appointment['symptoms'] = JSON.parse(appointment['symptoms']);
                        }
        
                        if (typeof (appointment['records']) === 'string') {
                            appointment['records'] = JSON.parse(appointment['records']);
                        }

                        if (typeof (appointment['records']) === 'string') {
                            appointment['records'] = JSON.parse(appointment['records']);
                        }                        

                        return {
                            appointment: appointment,
                        }
                    })
            }
        })
    })
