
export const listAppointmentsByTpa = /* GraphQL */ `
  query ListAppointmentsByTpa(
    $tpaId: String!
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentsByTPA(
      tpaId: $tpaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alternateId
        dob
        phoneNumberCode
        phoneNumber
        gender
        specialities
        symptoms
        otherSymptom
        address1
        address2
        address3
        address4
        zipcode
        city
        state
        country
        comments
        status
        createdAt
        updatedAt
        requestedAt
        scheduledDate
        scheduledDateUtc
        timezoneId
        timezoneName
        lat
        lng
        availabilities
        startedAt
        completedAt
        tpa {
          id
          name
        }
        practitioner {
          id
          name
        }
      }
      nextToken
    }
  }
`