import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import { AppointmentStatus } from '../../model';

import { listAppointments } from '../../graphql/queries/listAppointments';

import * as subscriptions from '../../graphql/subscriptions';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${listAppointments}`,
    {
        options: ({ providerId, filter = {} }) => {

            return {
                variables: {
                    providerId: providerId,
                    filter: filter,
                    limit: 50
                },
                fetchPolicy: 'cache-and-network',
            }
        },
        props: ({ data: { listAppointments = {}, loading, fetchMore, subscribeToMore } }) => {

            const appointments = listAppointments;

            appointments.items && appointments.items.map((item) => {
                if (typeof (item.specialities) === 'string') {
                    item.specialities = JSON.parse(item.specialities)
                }
                if (typeof (item.symptoms) === 'string') {
                    item.symptoms = JSON.parse(item.symptoms)
                }
            });

            return {
                appointments: appointments,
                loading: loading,
                filter: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAppointments: {
                                ...fetchMoreResult.listAppointments
                            }
                        });
                    }
                }),
                next: (data) => fetchMore({
                    variables: data,
                    updateQuery: (prev, { fetchMoreResult }) => {

                        if (!fetchMoreResult) {
                            return prev;
                        }

                        return Object.assign({}, prev, {
                            listAppointments: {
                                ...fetchMoreResult.listAppointments,
                                items: [...prev.listAppointments.items, ...fetchMoreResult.listAppointments.items],
                            }
                        });
                    }
                }),
                subscribeToProviderAppointment: (providerId) => {

                    const info = {
                        document: gql`${subscriptions.onProviderAppointment}`,
                        updateQuery: (prev, { subscriptionData }) => {

                            //console.log({ prev: prev, subscriptionData: subscriptionData });

                            const item = subscriptionData.data.onProviderAppointment || {};

                            if (item.status == AppointmentStatus.Requested){

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been created.`).replace('@appointment', item.name) })
                            }
                            else {

                                CORE_API.create_toast({ type: 'info', message: I18n.get(`Appointment for '@appointment' has been updated.`).replace('@appointment', item.name) })
                            }

                            return prev;
                        },
                        variables: {
                            appointmentProviderId: providerId
                        }
                    }

                    subscribeToMore(info);
                }
            }
        }
    }
)