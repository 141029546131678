import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { DashboardHomeIcon, DetailsListIcon, PractitionersIcon, GroupIcon, ProviderUserIcon, CoverageIcon, TpaRegularIcon, SettingsIcon, ReportIcon, AuditLogIcon } from '../../components/ui/icons';

const ASide = ({ provider, auth, menuIsOpen = false }) => {

    if (!auth.is_provider && !auth.is_master) return null;

    if (!provider || !provider.id) return null;

    const labelCssClass = menuIsOpen ? 'label inline-block' : 'label hidden lg:inline-block';

    return (
        <aside className="mt-12 sm:mt-16 text-gray-300">

            <ul className="list-reset flex flex-col">

                <li className="flex-1" style={{ backgroundColor: '#3D4961' }}>
                    <div className="px-5 py-6">
                        <span className={labelCssClass + ' text-gray-300 text-2xl uppercase tracking-wider'}>{provider.name}</span>
                    </div>
                </li>

                <li className="mt-8">
                    <NavLink to={`/${provider.publicURL}/dashboard`} className="menu-item hover:text-smart-blue">
                        <DashboardHomeIcon />
                        <span className={labelCssClass}>{I18n.get('Dashboard')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/details`} className="menu-item hover:text-smart-blue">
                        <DetailsListIcon />
                        <span className={labelCssClass}>{I18n.get('Provider Details')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/practitioners`} className="menu-item hover:text-smart-blue">
                        <PractitionersIcon />
                        <span className={labelCssClass}>{I18n.get('Practitioners')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/groups`} className="menu-item hover:text-smart-blue">
                        <GroupIcon />
                        <span className={labelCssClass}>{I18n.get('Groups')}</span>
                    </NavLink>
                </li>                

                <li>
                    <NavLink to={`/${provider.publicURL}/users`} className="menu-item hover:text-smart-blue">
                        <ProviderUserIcon />
                        <span className={labelCssClass}>{I18n.get('Provider Users')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/coverage-area`} className="menu-item hover:text-smart-blue">
                        <CoverageIcon />
                        <span className={labelCssClass}>{I18n.get('Coverage Area')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/settings`} className="menu-item hover:text-smart-blue">
                        <SettingsIcon />
                        <span className={labelCssClass}>{I18n.get('Settings')}</span>
                    </NavLink>
                </li>                

                <li>
                    <NavLink to={`/${provider.publicURL}/tpas`} className="menu-item hover:text-smart-blue">
                        <TpaRegularIcon />
                        <span className={labelCssClass}>{I18n.get('TPAs')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${provider.publicURL}/report`} className="menu-item hover:text-smart-blue">
                        <ReportIcon />
                        <span className={labelCssClass}>{I18n.get('Report')}</span>
                    </NavLink>
                </li>                

                <li>
                    <NavLink to={`/${provider.publicURL}/audit-logs`} className="menu-item hover:text-smart-blue">
                        <AuditLogIcon />
                        <span className={labelCssClass}>{I18n.get('Audit Logs')}</span>
                    </NavLink>
                </li>

            </ul>

        </aside>
    );

}

export default ASide;