export const getMedicalProviderCoverage = `query getMedicalProviderCoverage($id: ID!) {
    getMedicalProvider(id: $id) {
      id
      coverage {
        lat
        lng
        radius
      }
    }
  }
  `;