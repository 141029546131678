import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import MasterContainer from '../../containers/master-container';
import MasterLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import ProviderItem from './_provider-item';

import domain from '../../domain';

class ProvidersPage extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {

        const items = this.props.providers.items;

        return (<MasterLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Providers')}
                </div>

                <div className="bar">

                <NavLink to={`/providers/new`} className="btn-primary">
                    {I18n.get('Create Provider')}
                </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div>
                    <div className="hidden sm:block font-semibold">
                        <div className="px-4 py-4">

                            <div className="flex justify-between">

                                <div className="flex-1">
                                    {I18n.get('Provider')}
                                </div>

                                {/* <div className="flex-1">
                                    {I18n.get('Contact')}
                                </div> */}


                                <div className="flex-1">
                                    {I18n.get('Status')}
                                </div>


                                <div className="flex-1">
                                    {I18n.get('Created')}
                                </div>

                                <div className="flex-1">
                                    {I18n.get('Updated')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        {items && items.map((item, index) =>
                            (<NavLink key={index} to={`/${item.publicURL}/dashboard`}>
                                <ProviderItem item={item} />
                            </NavLink>)
                        )}
                    </div>
                </div>

            </div>

        </MasterLayout >);
    }

}

export default MasterContainer(ProvidersPage);

