import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'aws-amplify';


import { AUTH_API } from '../../actions/auth/actions';
import { CORE_API } from '../../actions/core/actions';

import Task from '../../task';

import MasterLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import PasswordField from '../../components/form/password-field';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
};

class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                current: {
                    fieldName: 'current',
                    label: I18n.get('Current password'),
                    onChange: this.onChangeForm.bind(this, 'current'),
                },
                password: {
                    fieldName: 'password',
                    label: I18n.get('New password'),
                    onChange: this.onChangeForm.bind(this, 'password'),
                },
                password2: {
                    fieldName: 'password2',
                    label: I18n.get('Re-type new password'),
                    onChange: this.onChangeForm.bind(this, 'password2'),
                }
            },
            form: {
                current: '',
                password: '',
                password2: '',
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {
        e.preventDefault();

        const change_task = new Task();

        this.setState({ task: change_task });

        const data = {
            ...this.state.form
        };

        AUTH_API.change_password(data)
            .then((result) => {

                console.log({ success: result });

                this.setState({ task: change_task.complete_as_success(), errors: {} });

                CORE_API.create_toast({ message: I18n.get(`Password for '@username' has been changed.`).replace('@username', this.props.auth.username) });

            })
            .catch((err) => {

                console.log({ err3: err });

                const { task, errors } = change_task.complete_as_failure(err);

                this.setState({ task: task, errors: errors });
            });
    }

    render() {

        return (<MasterLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Change password')}
                </div>

                <div className="bar">

                    <BackButton {...this.props} />

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <div className="card">

                    <div className="body">

                        <p className="subtitle mb-12">{I18n.get('Choose a new password')}</p>

                        <form onSubmit={this.onSubmitForm.bind(this)}>

                            <PasswordField  {...this.state.fields.current} value={this.state.form.current} error={this.state.errors.current} />

                            <PasswordField  {...this.state.fields.password} value={this.state.form.password} error={this.state.errors.password} />

                            <PasswordField  {...this.state.fields.password2} value={this.state.form.password2} error={this.state.errors.password2} />

                            <br /><br />

                            <TaskMessage {...this.state.task} />

                            <ActionButton task={this.state.task} label={I18n.get('Save Changes')} disabled={!(this.state.form.current && this.state.form.password && this.state.form.password2)} />


                        </form>

                    </div>

                </div>

            </div>

        </MasterLayout>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);

