import React from 'react';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';
import TimeSpan from '../../components/time-span';

import { CircleIcon, MaleIcon, FemaleIcon,  CalendarRegularIcon, TimeClearIcon, PractitionersIcon, LocationIcon } from '../../components/ui/icons';

import TimeAgo from '../../components/time-ago';

const AppointmentItem = ({ item = {} }) => {

    //const symptoms = JSON.parse(item.symptoms);

    const status = domain.appointment.statusMap[item.status] || {};

    var getSymptom = (id) => {
        return domain.symptomMap[id] || {};
    }

    var getGender = (id) => {
        return domain.genderMap[id] || {};
    }

    var style = {
    };

    if (item.status === 0) {
        style['color'] = '#cccccc';
    }

    if (item.status === 9) {
        style['color'] = '#FF8E8E';
    }

    if (item.status === 1) {
        style['color'] = '#03BBD7';
    }

    if (item.status === 2) {
        style['color'] = '#803694';
    }

    return (<div className="bg-white border-b border-grey-100 hover:bg-gray-100">
        <div className="px-4 py-4">

            <div className="flex justify-between">

                <div className="flex-none pr-4 py-4" style={style}>
                    <span title={I18n.get(status.label)} style={style}><CircleIcon /></span>
                </div>

                <div className="flex-1">
                    <p className="sm:text-xl">{item.name}</p>
                    <p className="text-sm text-gray-600">
                        {item.gender === 'M' && <MaleIcon />}
                        {item.gender === 'F' && <FemaleIcon />}
                        &nbsp;&nbsp;
                        {item.dob && <DateSpan value={item.dob} />}
                    </p>
                    <div className="sm:hidden text-sm text-gray-600">
                        <LocationIcon /> &nbsp;
                        {item.city}, {item.state}
                    </div>
                </div>

                <div className="flex-1">
                    <p className="sm:text-lg">
                        <CalendarRegularIcon /> &nbsp;
                        <DateSpan value={item.scheduledDate} timezoneId={item.timezoneId} />
                    </p>
                    <p className="text-sm text-gray-600">
                        <TimeClearIcon /> &nbsp;
                        <TimeSpan value={item.scheduledDate} timezoneId={item.timezoneId} timezoneName={item.timezoneName} />
                    </p>
                    <div className="sm:hidden text-sm text-gray-600">
                        <PractitionersIcon /> &nbsp;
                        {item.practitioner && item.practitioner.name}
                        {!item.practitioner && <span className="italic">{I18n.get('Unassigned')}</span>}
                    </div>
                </div>

                <div className="flex-1 hidden sm:block">
                    <p className="text-lg">
                        <LocationIcon /> &nbsp;
                        {item.city}, {item.state}
                    </p>
                    <p className="text-sm text-gray-600">
                        {item.address2} {item.address1}
                    </p>
                </div>


                <div className="flex-1 hidden sm:block">
                    <p className="text-lg">
                        <PractitionersIcon /> &nbsp;
                        {item.practitioner && item.practitioner.name}
                        {!item.practitioner && <span className="italic">{I18n.get('Unassigned')}</span>}
                    </p>
                    <p className="text-sm text-gray-600"><TimeAgo v={item.updatedAt} /></p>
                </div>

            </div>
        </div>
    </div >);
};

export default AppointmentItem;