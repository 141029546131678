import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import MasterContainer from '../../containers/master-container';
import MasterLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import AdminUserList from '../shared/_admin-user-list';

import { ProviderUserIcon } from '../../components/ui/icons';

const AdminUserListWithData = CORE_API.list_users(AdminUserList);

class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            selected: {}
        }
    }

    componentDidMount() {
    }

    select(item, e) {

        if (e) e.preventDefault();

        this.props.history.push(`/users/${item.id}`);
    }

    render() {

        const items = this.props.users;

        return (<MasterLayout {...this.props}>
            <div className="page-header">

                <div className="title">
                    <ProviderUserIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('Master Users')}
                </div>

                <div className="bar">

                    <NavLink to={`/users/new`} className="btn-primary">
                        {I18n.get('Create User')}
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <AdminUserListWithData {...this.props} profile="Master" select={this.select.bind(this)} />

            </div>

        </MasterLayout>);
    }

}

export default MasterContainer(UsersPage);

