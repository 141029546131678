import { store } from '../../store';

import login from './login';
import login_success from './login-success';
import login_failure from './login-failure';
import reset_password from './reset-password';
import set_password from './set-password';
import forgot_password from './forgot-password';
import change_password from './change-password';
import logout from './logout';
import reset_task from './reset_task';
import get_provider from './get_provider';

export const LOGIN = 'AUTH_LOGIN';
export const FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const PASSWORD_SET = 'AUTH_PASSWORD_SET';
export const PASSWORD_RESET = 'AUTH_PASSWORD_RESET';
export const LOGOUT = 'AUTH_LOGOUT';
export const EXPIRED = 'AUTH_EXPIRED';
export const AUTH_UPDATE = 'AUTH_UPDATE';

export const AUTH_API = {
    login: (data) => store.dispatch(login(data)),
    logout: () => store.dispatch(logout()),
    login_success: (data) => login_success(data),
    login_failure: (data) => login_failure(data),
    reset_password: (data) => store.dispatch(reset_password(data)),
    set_password: set_password,
    forgot_password: (data) => store.dispatch(forgot_password(data)),
    change_password: (data) => store.dispatch(change_password(data)),
    reset_task:  (data) => store.dispatch(reset_task(data)),
    get_provider: (data) => store.dispatch(get_provider(data)),
}

