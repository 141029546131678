import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.updateAdminUser}`,
    {
        options: {
            // update: (proxy, { data: { updateTPA } }) => {
            //     const query = getTPA;
            //     const data = proxy.readQuery({ query });

            //     proxy.writeQuery({ query, data });
            // }
        },
        props: (props) => ({
            update_user: (data) => {

                return props.mutate({
                    variables: { input: data }
                })
                .then(({ data: { updateAdminUser = {} } }) =>{

                    const user = updateAdminUser;
                    
                    return {
                        user: user
                    }
                });
            }
        })
    })
