import React from 'react';


const Modal = ({ isOpen, className = '', children }) => {

    if (isOpen){
        className += ' is-active';
    }

    if (!isOpen) return null;

    return (
        <div className={'modal' + className}>
            <div className="modal-background"></div>
            <div className="modal-content">
                {children}
            </div>
        </div>);
}

export default Modal;