import thunk from 'redux-thunk';
import { applyMiddleware, compose, combineReducers, createStore } from 'redux';

import authReducer from './reducers/auth-reducer';
import coreReducer from './reducers/core-reducer';

const reducers = combineReducers({
    auth: authReducer,
    core: coreReducer,
});

const allStoreEnhancers = compose(
    applyMiddleware(thunk),
    //window.devToolsExtension && window.devToolsExtension()
);


export const store = createStore(
    reducers,
    {
        auth: {
            init: true,
            provider: {},
            task: {},
        },
        core: {
            countries: {},
            users: [],
            providers: {},
            provider: {},
            practitioner: {},
            practitioner_group: {},
            tpa: {},
            appointments: {},
            appointment: {},
            availabilities: {},
            timeslots: {},
            coverage: {},
            notifications: [],
            notification: {},
            toasts: [],
            task: {},
            prepare_task: {},
            request_task: {},
            errors: {}
        }
    },
    allStoreEnhancers
);

