import React from 'react';
import { I18n } from 'aws-amplify';
import domain from '../../domain';

import DateTimeSpan from '../../components/datetime-span';
import DateSpan from '../../components/date-span';
import TimeSpan from '../../components/time-span';

import { AppointmentStatus } from '../../model';
import Modal from '../../components/modal';

import TimeAgo from '../../components/time-ago';

import EMRDetails from './emr-details';

import { MedicalRecordIcon } from '../../components/ui/icons';
import { CORE_API } from '../../actions/core/actions';

const EMRDetailsWithData = CORE_API.get_emr(EMRDetails)

class ReportItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onEMR(id, mode, e) {

        e.preventDefault();

        this.setState({ id: id });
    }

    reset(e) {

        this.setState({ id: null, mode: null });
    }

    render() {

        const { item } = this.props;

        var getGender = (id) => {
            return domain.genderMap[id] || {};
        }

        const status = domain.appointment.statusMap[item.status] || {};

        const gender = getGender(item.gender);

        return (<div className="bg-white border-b border-grey-100 hover:bg-gray-100">
            <div className="p-2">

                <div className="flex justify-between flex-col sm:flex-row">
                    {this.props.auth.is_master_or_provider &&
                    <div className="flex-1 p-2 text-sm" onClick={(e) => this.props.select(item, e)}>
                        {item.tpa && item.tpa.name}
                    </div>}
                    <div className="flex-1 p-2 text-sm" onClick={(e) => this.props.select(item, e)}>
                        {item.alternateId}
                    </div>
                    <div className="flex-1 p-2 text-sm" onClick={(e) => this.props.select(item, e)}>
                        {I18n.get(status.label)}
                    </div>
                    <div className="flex-1 p-2" onClick={(e) => this.props.select(item, e)}>
                        <p className="text-sm">
                            {item.name}
                        </p>
                        <p className="text-sm text-gray-600">
                            {I18n.get(gender.label)} &middot; <DateSpan value={item.dob} />
                        </p>
                    </div>
                    <div className="flex-1 p-2" onClick={(e) => this.props.select(item, e)}>
                        <p className="text-sm">
                            {item.address2} {item.address1}
                        </p>
                        <p className="text-sm text-gray-600">
                            {item.city}, {item.state}
                        </p>
                    </div>
                    <div className="flex-1 p-2" onClick={(e) => this.props.select(item, e)}>
                        <p className="text-sm">{item.practitioner && item.practitioner.name}</p>
                    </div>
                    <div className="flex-1 p-2" onClick={(e) => this.props.select(item, e)}>
                        <p className="text-sm">
                            <DateSpan value={item.scheduledDate} timezoneId={item.timezoneId} />
                        </p>
                        <p className="text-sm">
                            <TimeSpan value={item.scheduledDate} timezoneId={item.timezoneId} timezoneName={item.timezoneName} />
                        </p>
                    </div>
                    <div className="flex-1 p-2" onClick={(e) => this.props.select(item, e)}>
                        <p className="text-sm"><DateTimeSpan value={item.createdAt} /> </p>
                        <p className="text-sm"><TimeAgo v={item.createdAt} className="text-gray-600" /> </p>
                    </div>
                    <div className="flex-none w-10 p-2">
                        &nbsp;
                        {item.status >= AppointmentStatus.Started && <button onClick={this.onEMR.bind(this, item.id, 'view')} title='View EMR'> <MedicalRecordIcon /> </button>}
                    </div>

                </div>
            </div>

            <Modal isOpen={this.state.id}>

                {this.state.id && <EMRDetailsWithData item={item} appointmentId={this.state.id} select={this.reset.bind(this)} />}

            </Modal>


        </div >);
    }
}

export default ReportItem;