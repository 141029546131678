import React from 'react';

import { SearchIcon } from '../ui/icons';

class SearchField extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            value: props.value || ''
        }

        this.timeout_id = null;

        this.onChangeValue = this.changeValue.bind(this);
    }

    changeValue(e){

        const value = e.target.value;

        this.setState({ value: value });

        clearTimeout(this.timeout_id);

        this.timeout_id = setTimeout( () => {
            this.props.onChangeText(this.state.value);
        }, 500);
    }


    render() {
        return (<div className="flex mt-4 sm:mt-0" style={{ minWidth: '20rem', maxWidth: '24rem' }}>
            <div className="flex-none flex items-center">
                <SearchIcon />
            </div>
            <div className="flex-grow ml-4">
                <input onChange={this.onChangeValue} value={this.state.value} type="search" name="serch" placeholder={this.props.placeholder} className="bg-white shadow h-10 w-full px-5 rounded-full text-sm focus:outline-none" />
            </div>
        </div>);
    }
}

export default SearchField;