import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { AUTH_API } from '../../actions/auth/actions';

import ToastContainer from '../shared/toast-container';

import NotificationBox from '../shared/notification-box';

import ASideProvider from './_aside-provider';
import ASideTPA from './_aside-tpa';
import ASidePractitioner from './_aside-practitioner';
import ASideMaster from './_aside-master';

import Dropdown from '../../components/ui/drop-down';

import { MenuIcon, UserFillIcon, UserClearIcon, PasswordLockIcon, LogOutIcon, ChevronRightIcon, SettingsIcon } from '../../components/ui/icons';


class Layout extends React.Component {

    constructor(props) {
        super();
        this.state = {
            menu: false
        }
    }

    componentDidMount() {
    }

    onMenuClick(e) {
        this.setState(prevState => {
            return {
                menu: !prevState.menu
            }
        })
    }

    render() {

        const auth = this.props.auth;

        const providerId = this.props.match.params.providerId;

        if (!this.props.auth.init && !this.props.auth.logged_user && providerId) return (<Redirect to={`/${providerId}/login`} />);

        if (!this.props.auth.init && !this.props.auth.logged_user) return (<Redirect to={`/login`} />);

        //if (!this.props.auth.init && !this.props.auth.is_master && !this.props.auth.is_provider) return (<Redirect to={`/unauthorized`} />);

        return (

            <div className="">
                <nav className="flex justify-between bg-white shadow-lg fixed w-full z-50">

                    <div className="lg:hidden flex-1 flex items-center">
                        <span onClick={this.onMenuClick.bind(this)} className="px-4 cursor-pointer"><MenuIcon /></span>
                    </div>

                    <div className="lg:flex-none flex-1 lg:border-r flex justify-center w-64">
                        <NavLink to={`/`}>
                            <img className="h-12 sm:h-16" src="/img/smartprovider-logo.svg" />
                        </NavLink>
                    </div>

                    <div className="flex-1 flex items-center justify-end">

                        {auth.logged_user && <NotificationBox {...this.props} />}

                        {auth.logged_user && <Dropdown
                            key="account"
                            style={{ width: '65px' }}
                            className="h-full sm:border-l dropdown-lg"

                            label={<UserFillIcon className="icon md" />}
                            icon={(<ChevronRightIcon />)}
                        >
                            <div className="px-8 py-6">
                                <div className="text-sm mb-4">
                                    {auth.name}
                                </div>
                                {auth.is_master && <NavLink to={`/users/${auth.userId}`} className="px-2 py-2 block text-sm ">
                                    <UserClearIcon />
                                    <span className="ml-4">{I18n.get('Profile')}</span>
                                </NavLink>}
                                {auth.is_provider && <NavLink to={`/${auth.providerId}/users/${auth.userId}/details`} className="px-2 py-2 block text-sm ">
                                    <UserClearIcon />
                                    <span className="ml-4">{I18n.get('Profile')}</span>
                                </NavLink>}
                                {auth.is_tpa && <NavLink to={`/${auth.providerId}/t/${auth.tpaId}/users/${auth.userId}`} className="px-2 py-2 block text-sm ">
                                    <UserClearIcon />
                                    <span className="ml-4">{I18n.get('Profile')}</span>
                                </NavLink>}
                                {auth.is_practitioner && <NavLink to={`/${auth.providerId}/p/${auth.practitionerId}/details`} className="px-2 py-2 block text-sm ">
                                    <UserClearIcon />
                                    <span className="ml-4">{I18n.get('Profile')}</span>
                                </NavLink>}
                                {auth.is_practitioner && <NavLink to={`/${auth.providerId}/p/${auth.practitionerId}/settings`} className="px-2 py-2 block text-sm ">
                                    <SettingsIcon />
                                    <span className="ml-4">{I18n.get('Settings')}</span>
                                </NavLink>}                                
                                <NavLink to="/change-password" className="px-2 py-2 block text-sm">
                                    <PasswordLockIcon />
                                    <span className="ml-4">{I18n.get('Password')}</span>
                                </NavLink>
                                <a href="#" onClick={(e) => AUTH_API.logout()} className="px-2 py-2 block text-sm">
                                    <LogOutIcon />
                                    <span className="ml-4">{I18n.get('Logout')}</span>
                                </a>
                            </div>
                        </Dropdown>}

                    </div>
                </nav>

                <div className="min-h-screen flex">
                    <div className={(this.state.menu ? '' : 'hidden') + ' sm:block lg:w-64 flex-none'} style={{ backgroundColor: '#3F4D67' }}>

                        <ASideMaster {...this.props} menuIsOpen={this.state.menu} />

                        <ASideProvider {...this.props} menuIsOpen={this.state.menu} />

                        <ASideTPA {...this.props} menuIsOpen={this.state.menu} />

                        <ASidePractitioner {...this.props} menuIsOpen={this.state.menu} />

                    </div>
                    <div className="flex-1 overflow-auto pb-8">

                        {this.props.children}

                    </div>
                </div>

            </div>
        );
    }
}

export default Layout;