import { getTpa } from '../../graphql/queries/getTPA';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export default graphql(
    gql`${getTpa}`,
    {
        options: ({ match: { params: { tpaId } } }) => ({
            variables: {
                id: tpaId
            },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getTPA = {}, loading } }) => {

            const tpa = getTPA;

            if (typeof (tpa.groups) === 'string') {
                tpa.groups = JSON.parse(tpa.groups)
            }

            return {
                tpa: tpa,
                provider: tpa.provider,
                loading: loading
            }
        }
    }
)
