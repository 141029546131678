import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { CORE_API } from '../../actions/core/actions';

import TPAContainer from '../../containers/tpa-container';
import TPALayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import AdminUserList from '../shared/_admin-user-list';

import { ProviderUserIcon } from '../../components/ui/icons';

const AdminUserListWithData = CORE_API.list_users(AdminUserList);

class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            selected: {}
        }
    }

    componentDidMount() {
    }

    select(item, e) {

        if (e) e.preventDefault();

        const provider = this.props.provider || {};
        const tpa = this.props.tpa || {};


        this.props.history.push(`/${provider.publicURL}/t/${tpa.id}/users/${item.id}`);
    }

    render() {


        const tpa = this.props.tpa || {};
        const provider = tpa.provider || {};

        return (<TPALayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    <ProviderUserIcon className="icon md mr-3 opacity-50" />
                    {I18n.get('TPA Users')}
                </div>

                <div className="bar">

                    <NavLink to={`/${provider.publicURL}/t/${tpa.id}/users/new`} className="btn-primary">
                        {I18n.get('Create TPA User')}
                    </NavLink>


                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                {tpa.id && <AdminUserListWithData {...this.props} profile="TPA" tpaId={tpa.id} select={this.select.bind(this)} />}

            </div>

        </TPALayout>);
    }

}

export default TPAContainer(UsersPage);

