import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import Task from '../../task';

import { CORE_API } from '../../actions/core/actions';

import MasterContainer from '../../containers/master-container';
import MasterLayout from '../shared/layout';
import ToastContainer from '../shared/toast-container';

import { ActionButton, BackButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';

import { CloseButtonIcon } from '../../components/ui/icons';

class UsersPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fields: {
                id: '',
                name: {
                    label: I18n.get('Full Name'),
                    onChange: this.onChangeForm.bind(this, 'name'),
                    maxLength: 100
                },
                email: {
                    label: I18n.get('Email'),
                    onChange: this.onChangeForm.bind(this, 'email'),
                    maxLength: 100,
                },
                profile: {
                    label: I18n.get('Profile'),
                    onChange: this.onChangeForm.bind(this, 'profile'),
                }
            },
            form: {
                profile: 'Master'
            },
            errors: {
            },
            task: {
            }
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
    }

    getProfiles() {
        return [
            { id: "Master", label: "Master" },
        ];
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        var create_task = new Task();

        this.setState({ task: create_task });

        const data = {
            ...this.state.form
        }

        this.props.create_user(data)
            .then(({ user }) => {

                this.setState({ task: create_task.complete_as_success() });

                this.props.history.push(`/users`);

                CORE_API.create_toast({ message: I18n.get(`Master User  '@name' has been created. An email has been sent to the user with a temporary password`).replace('@name', user.name) });
            })
            .catch((err) => {

                const { task, errors } = create_task.complete_as_failure(err);

                this.setState({ task: task, errors: errors });
            });
    }

    render() {

        const profiles = this.getProfiles();

        return (<MasterLayout {...this.props}>

            <div className="page-header">

                <div className="title">
                    {I18n.get('Create Master User')}
                </div>

                <div className="bar">

                    <NavLink to={`/providers`}>
                        <CloseButtonIcon className="icon h-3 w-3" />
                    </NavLink>

                </div>

            </div>

            <ToastContainer toasts={this.props.core.toasts} />

            <div className="page-body">

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <div className="card">

                        <div className="body">

                            <p className="subtitle mb-12">{I18n.get('User Details')}</p>

                            <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                            <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                            <br /><br />

                            <TaskMessage {...this.state.task}>User successfully created. An email has been sent to the user with a temporary password.</TaskMessage>

                            <ActionButton task={this.state.task} label={I18n.get('Create')} />

                        </div>

                    </div>

                </form>
            </div>

        </MasterLayout>);
    }

}

export default MasterContainer(UsersPage);

