import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import domain from '../../domain';

import TPAItem from '../shared/_tpa-item';

import Task from '../../task';

import { Button } from '../../components/ui/buttons';


class TPAList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            selected: {},
        }
    }

    componentDidMount() {

    }

    getSpeciality(id) {
        return domain.specialityMap[id] || { label: '-' };
    }

    selectPractitioner(item, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            if (prevState.selected.id == item.id) {

                return { selected: {} };
            }
            else {
                return {
                    selected: item
                };
            }

        });
    }

    loadMore(token) {

        console.log({ loadMore: token });

        const data = {
            nextToken: token,
            filter: this.props.filter
        }

        var load_task = new Task();

        this.setState({ load_task: load_task });

        this.props.next(data)
            .then(({ listAppointments }) => {

                this.setState({ load_task: load_task.complete_as_success() });
            })
            .catch((err) => {

                const { task, errors } = load_task.complete_as_failure(err);

                this.setState({ load_task: task, errors: errors });
            });
    }

    render() {

        const provider = this.props.provider;
        const tpas = this.props.tpas || {};

        const items = tpas.items || [];
        const nextToken = tpas.nextToken;

        return (<section className="tpa-list">
            <div className="hidden sm:block font-semibold">
                <div className="px-4 py-4">

                    <div className="flex justify-between">

                        <div className="flex-1">
                            {I18n.get('TPA')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Contact')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Created')}
                        </div>

                        <div className="flex-1">
                            {I18n.get('Updated')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                {items && items.map((item, index) => (<div key={index}>

                    <NavLink to={`/${provider.publicURL}/t/${item.id}/dashboard`}>
                        <TPAItem item={item} />
                    </NavLink>

                </div>))}
            </div>

            <br />

            <div className="text-center">
                <Button task={this.state.load_task} onClick={(e) => this.loadMore(nextToken)} disabled={!nextToken} label={I18n.get('Load more')}></Button>
            </div>

        </section>);
    }
}

export default TPAList;