import config from '../../aws-exports';

import { AUTH_UPDATE } from './actions';

const api_call = (data) => {
    return (dispatch, getState) => {

        fetch(`${config.aws_apigateway_url}/providers/${data.id}`)
            .then(function (response) {

                return response.json()

            }).then(function (json) {

                dispatch({
                    type: AUTH_UPDATE,
                    payload: {
                        provider: json
                    }
                });

            }).catch(function (ex) {

                dispatch({
                    type: AUTH_UPDATE,
                    payload: {
                        provider: {}
                    }
                });
            })

    }
}

export default api_call;