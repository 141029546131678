import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { AUTH_API } from '../../actions/auth/actions';
import Task from '../../task';

import LoginLayout from '../shared/login-layout';

import { ActionButton } from '../../components/ui/buttons';
import TaskMessage from '../../components/task-message';

import TextField from '../../components/form/text-field';
import PasswordField from '../../components/form/password-field';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: {
                    name: "email",
                    onChange: this.onChangeForm.bind(this, 'email'),
                    placeholder: I18n.get('Email')
                },
            },
            form: {},
            task: {},
            errors: {},
        }
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState(prevState => ({
            form: { ...prevState.form, [prop]: value },
            errors: { ...prevState.errors, [prop]: undefined }
        })
        );
    }

    onSubmitForm(e) {

        e.preventDefault();

        var forgot_task = new Task();

        this.setState({ task: forgot_task });

        const data = {
            ...this.state.form,
        };

        AUTH_API.forgot_password(data)
            .then(({ task, errors }) => {

                console.log({ task: task, errors: errors });

                this.setState({ task: task, errors: errors });
            });
    }

    render() {

        if (this.props.auth.password_reset_required) {
            return (<Redirect to="/reset-password" />);
        }

        return (<LoginLayout {...this.props}>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden p-8 mx-8 w-full max-w-md">

                <p className="px-8 py-4 text-xl text-center">{I18n.get('Forgot password')}</p>

                <p className="my-8 text-gray-500">{I18n.get('Enter your email to receive a code to reset your password.')}</p>

                <form onSubmit={this.onSubmitForm.bind(this)}>

                    <TextField {...this.state.fields.email} value={this.state.form.email} error={this.state.errors.email} />

                    <TaskMessage {...this.state.task}>{I18n.get('Please check your email.')}</TaskMessage>

                    <ActionButton className="btn-primary full" label={I18n.get('Send Code')} task={this.state.task} />
                </form>

                <p className="mt-8 text-xs text-gray-600 text-right">
                    <NavLink to="/login">{I18n.get('Login')}</NavLink>
                    &nbsp;·&nbsp;
                    <NavLink to="/reset-password">{I18n.get('Reset Password')}</NavLink>
                    &nbsp;·&nbsp;
                    <a href="/help">{I18n.get('Help')}</a>                    
                </p>
            </div>
        </LoginLayout>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

