import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import * as mutations from '../../graphql/mutations';

export default graphql(
    gql`${mutations.updateAppointmentRequest}`,
    {
        options: {
        },
        props: (props) => ({
            update_appointment_request: (input) => {

                return props.mutate({
                    variables: { input: input }
                }).then(({ data }) => {

                    const appointment = data.updateAppointmentRequest;

                    if (typeof (appointment['symptoms']) === 'string') {
                        appointment['symptoms'] = JSON.parse(appointment['symptoms']);
                    }

                    if (typeof (appointment['requests']) === 'string') {
                        appointment['requests'] = JSON.parse(appointment['requests']);
                    }

                    return {
                        appointment: appointment
                    }
                })
            }
        })
    })
